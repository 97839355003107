import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Vstack,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
  HStack,
  VStack,
  Avatar,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { AiFillLock } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import {
  useGetUserInfoQuery,
  useGetUsersListInOrgQuery,
  useSearchCaseByIDQuery,
  useSearchUsersByNameQuery,
} from "../../state/API/HospitalApi";
import Header from "../../DashboardLayout/Header";
import ChatHeader from "./ChatHeader";
import ChatNavbar from "./ChatNavbar";
import ChatLists from "./ChatLists";
import Conversation from "./Conversation";
import ConversationHeader from "./ConversationHeader";
import UserToUserConversation from "./UserToUserConversation";
import ScrollBar from "../../Components/other/Scrollbar";

const Chat = () => {
  const [searchName, setSearchName] = useState("");
  const [searchCase, setSearchCase] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [activeGroup, setActiveGroup] = useState(
    location?.state?.selectedGroup || null
  );
  const [groupData, setGroupData] = useState({
    _id: location.state?.selectedGroupData?._id || null,
    caseName: location.state?.selectedGroupData?.caseName || null,
  });
  const { user } = useAuth0();
  const { data: userInfo, isLoading } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user?.sub }
  );

  useEffect(() => {
    navigate(location.pathname, {});
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, []);

  const [isScreenOpen, setIsScreenOpen] = useState(false);
  const toggleScreen = () => {
    setIsScreenOpen(!isScreenOpen);
  };

  const [isHeight604] = useMediaQuery("(min-height: 604px)");
  const [isHeight610] = useMediaQuery("(min-height: 610px)");
  const [isHeight720] = useMediaQuery("(min-height: 720px)");
  const [isHeight760] = useMediaQuery("(min-height: 760px)");
  const [isHeight962] = useMediaQuery("(min-height: 962px)");
  const [isHeight1080] = useMediaQuery("(min-height: 1080px)");

  const getComponentSize = () => {
    let size = "425px";
    switch (true) {
      case isHeight1080:
        size = "888px";
        break;
      case isHeight962:
        size = "794px";
        break;
      case isHeight760:
        size = "630px";
        break;
      case isHeight720:
        size = "553px";
        break;
      case isHeight610:
        size = "502px";
        break;
      case isHeight604:
        size = "435px";
        break;
      default:
        size = "438px";
        break;
    }
    return size;
  };
  const getComponentForListSize = () => {
    let size = "92.4vh";
    switch (true) {
      case isHeight1080:
        size = "93.8vh";
        break;
      case isHeight962:
        size = "95.3vh";
        break;
      case isHeight760:
        size = "94.2vh";
        break;
      case isHeight720:
        size = "94vh";
        break;
      case isHeight610:
        size = "93vh";
        break;
      case isHeight604:
        size = "92.4vh";
        break;
      default:
        size = "93vh";
        break;
    }
    return size;
  };

  const getComponentParticipantSize = () => {
    let size = "56vh";
    switch (true) {
      case isHeight1080:
        size = "73vh";
        break;
      case isHeight962:
        size = "72vh";
        break;
      case isHeight760:
        size = "66vh";
        break;
      case isHeight720:
        size = "64vh";
        break;
      case isHeight610:
        size = "61vh";
        break;
      case isHeight604:
        size = "56vh";
        break;
      default:
        size = "57vh";
        break;
    }
    return size;
  };
  const getComponentParticipantInternalSize = () => {
    let size = "55vh";
    switch (true) {
      case isHeight1080:
        size = "72vh";
        break;
      case isHeight962:
        size = "71vh";
        break;
      case isHeight760:
        size = "65vh";
        break;
      case isHeight720:
        size = "63vh";
        break;
      case isHeight610:
        size = "60vh";
        break;
      case isHeight604:
        size = "55vh";
        break;
      default:
        size = "56vh";
        break;
    }
    return size;
  };

  const componentSize = getComponentSize();
  const componentListSize = getComponentForListSize();
  const componentParticipantSize = getComponentParticipantSize();
  const componentParticipantInternalSize =
    getComponentParticipantInternalSize();

  const [participantData, setParticipantData] = useState([]);
  const [filterParticipantData, setFilterParticipantData] = useState([]);
  useEffect(() => {
    const uniqueFromNames = [
      ...new Set(participantData.map((item) => item.fromName)),
    ];
    setFilterParticipantData(uniqueFromNames);
  }, [participantData]);

  return (
    <Flex h="100vh" w="100vw" flexDirection="column" overflow="hidden">
      <Header userInfo={userInfo} />
      <Flex w="100%" h="100vh" justifyContent="flex-start" flex="1" minH={0}>
        <Flex
          w="25%"
          h={componentListSize}
          pr="5px"
          flexDirection="column"
          overflow="hidden"
          border="1px solid black"
          ml="2px"
          borderTop="0.4rem solid #f5f5f5"
          borderLeft="0.6rem solid #f5f5f5"
          borderRight="0.6rem solid #f5f5f5"
          borderBottom="0.5rem solid #f5f5f5"
        >
          <ChatHeader
            index={index}
            setSearchName={setSearchName}
            setSearchCase={setSearchCase}
            setActiveGroup={setActiveGroup}
            setGroupData={setGroupData}
            setIndex={setIndex}
          />
          <ChatNavbar index={index} setIndex={setIndex} />
          <ChatLists
            searchName={searchName}
            searchCase={searchCase}
            setActiveGroup={setActiveGroup}
            setGroupData={setGroupData}
            activeGroup={activeGroup}
            tab={index}
          />
        </Flex>
        <Flex
          flex="3"
          bg="#f6f6f6"
          flexDir="column"
          pos="relative"
          w="75%"
          p="0.4rem 0.5rem"
          // pt="0"
          // pb="0.5rem"
          // borderTop="2px solid #F5F5F5"
        >
          {groupData?._id ? (
            <Box bg="#fff" w="100%">
              <ConversationHeader
                groupData={groupData}
                toggleScreen={toggleScreen}
              />
              <Tabs p={0}>
                <TabList
                  style={{
                    borderBottom: "10px solid #F5F5F5",
                    marginBottom: "-13px",
                  }}
                >
                  <Tab>Chats </Tab>
                  {/* <Tab>Files</Tab> */}
                  <Tab>Participants</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      marginBottom: "-11px",
                    }}
                  >
                    <Flex flexDir="column" w="100%" h={componentSize}>
                      {index === 0 ? (
                        <UserToUserConversation
                          userInfo={userInfo}
                          groupChatId={groupData._id}
                          groupData={groupData}
                          setParticipantData={setParticipantData}
                          userProfile={user}
                        />
                      ) : (
                        <Box h="100%" w="100%" bg="white">
                          <Conversation
                            userInfo={userInfo}
                            groupChatId={groupData._id}
                            groupData={groupData}
                            isScreenOpen={isScreenOpen}
                            setIsScreenOpen={setIsScreenOpen}
                            setParticipantData={setParticipantData}
                            userProfile={user}
                          />
                        </Box>
                      )}
                    </Flex>
                  </TabPanel>
                  {/* <TabPanel
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      marginBottom: "-11px",
                    }}
                  >
                    <Box h={componentSize} w="100%" bg="white">
                      Files
                    </Box>
                  </TabPanel> */}
                  <TabPanel
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      marginBottom: "-11px",
                    }}
                  >
                    <Box h={componentSize} w="100%" bg="white">
                      <Box display="flex" justifyContent="center" pt="20px">
                        <Box>
                          <Box
                            border="1px solid #F5F5F5"
                            p="5px"
                            pl="15px"
                            display="flex"
                            justifyContent="flex-start"
                            minW="400px"
                            bg="#E8EDF6"
                            fontWeight="700"
                          >
                            <Text>Participants</Text>
                          </Box>
                          <Box
                            border="1px solid #F5F5F5"
                            borderTop="none"
                            minW="400px"
                            minH={componentParticipantSize}
                            maxH={componentParticipantSize}
                            pl="6px"
                            pr="6px"
                            bg="#FCFCFC"
                          >
                            <ScrollBar>
                              <Flex
                                flexDir="column"
                                maxH={componentParticipantInternalSize}
                              >
                                {filterParticipantData.map((userName) => {
                                  return (
                                    <Flex
                                      h="7.5vh"
                                      minH="58px"
                                      border="none"
                                      w="100%"
                                      bg="#FCFCFC"
                                      _hover={{
                                        bgColor: "#F6F6F6",
                                        cursor: "pointer",
                                        transition: "0.2s",
                                      }}
                                      // onClick={() => {
                                      //   setGroupData(User);
                                      //   setActiveGroup(User?._id);
                                      //   onClose();
                                      // }}
                                      letterSpacing="0.7px"
                                      fontSize="caseInfo"
                                      borderBottom="1px solid #DEDEDE"
                                      key={Math.random()}
                                    >
                                      <HStack
                                        marginStart="0.8vw"
                                        border="none"
                                        w="100%"
                                      >
                                        <Avatar
                                          h="40px"
                                          w="40px"
                                          size="sm"
                                          name={userName}
                                          getInitials={(name) =>
                                            name.slice(0, 2)
                                          }
                                        />
                                        <VStack
                                          alignItems="flex-start"
                                          spacing={3}
                                          w="100%"
                                        >
                                          <Flex
                                            w="100%"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            pt="0"
                                            pr="1"
                                            mt="-0rem !important"
                                          >
                                            <Text
                                              pl="25px"
                                              w="90%"
                                              fontSize="caseHeader"
                                              isTruncated
                                            >
                                              {userName}
                                            </Text>
                                          </Flex>
                                        </VStack>
                                      </HStack>
                                    </Flex>
                                  );
                                })}
                              </Flex>
                            </ScrollBar>
                          </Box>
                        </Box>
                      </Box>
                      <Box pt="2vh" pl="20px">
                        Note : The case is shared with the following users. To
                        add a new participant in the discussion, Share the case.
                      </Box>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          ) : (
            <Flex
              w="100%"
              h="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
              pos="relative"
              bg="#fff"
            >
              <HiOutlineUserGroup fontSize="22px" />
              <Text fontSize="24px" fontWeight="500">
                Group Chat
              </Text>
              <Text fontSize="14px" color="#52585D">
                Send and receive messages
              </Text>
              <Text
                textAlign="center"
                pos="absolute"
                bottom="20px"
                display="flex"
                alignItems="center"
                gap="0.5rem"
                color="#52585D"
                fontSize="14px"
              >
                <AiFillLock /> Encrypted
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Chat;
