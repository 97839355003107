import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Button,
  Input,
  CloseButton,
  Flex,
  useMediaQuery,
  HStack,
  VStack,
  Text,
  Textarea,
  Select,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import { HiOutlinePencilAlt } from "react-icons/hi";
import moment from "moment";
import { isActionValid } from "../../hooks/utility";

const CaseEditModal = ({
  isOpen,
  onClose,
  activeTab,
  handleTabChange,
  isEditing,
  handleEditClick,
  handleCancelClick,
  handleSaveClick,
  caseInfo,
  inputPatientData,
  inputCaseData,
  setInputCaseData,
  setInputPatientData,
  handlePatientInput,
  handleCaseInput,
  permissions,
}) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const headerStyle = {
    fontSize: ifWidthLessthan1920 ? "14px" : "16px",
    opacity: "0.6",
    fontWeight: "600",
  };
  //   console.log("caseData", caseInfo);
  // console.log("PatientData", inputPatientData);
  // console.log("CaseData", inputCaseData);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />

      <ModalContent>
        <Tabs defaultIndex={activeTab}>
          <TabList>
            <Tab onClick={() => handleTabChange(0)}>
              <Text variant="flushed" fontWeight="600" fontSize="16px">
                Patient Overview
              </Text>
            </Tab>
            <Tab onClick={() => handleTabChange(1)}>
              <Text variant="flushed" fontWeight="600" fontSize="16px">
                Case Overview
              </Text>
            </Tab>
            <Flex marginLeft="180px">
              <Button
                bgColor="inherit"
                onClick={handleEditClick}
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                padding="0px"
                disabled={isActionValid("caseEdit", permissions)}
              >
                <HiOutlinePencilAlt color="#1B75BC" size="19px" />
              </Button>
              <Button
                bgColor="inherit"
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                padding="0px"
                onClick={onClose}
              >
                <CloseButton
                  size="md"
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                />
              </Button>
            </Flex>
          </TabList>

          <ModalBody>
            <TabPanels>
              <TabPanel>
                {!isEditing ? (
                  <>
                    <HStack w="100%">
                      <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Name</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {`${caseInfo?.patient?.patientName?.firstName} ${caseInfo?.patient?.patientName?.lastName}`}
                        </Text>
                      </VStack>
                      <VStack alignItems="left" spacing={0} w="40%">
                        <Text style={headerStyle}>UHID</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {caseInfo?.patient?.uhid}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack w="100%" marginTop="30px">
                      <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Age</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {`${moment().diff(
                            `${caseInfo?.patient?.dateOfBirth}`,
                            "years"
                          )} Y`}
                        </Text>
                      </VStack>
                      <VStack alignItems="left" spacing={0} w="40%">
                        <Text style={headerStyle}>Gender</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {caseInfo?.patient?.gender}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack marginTop="30px">
                      <VStack alignItems="left" spacing={0}>
                        <Text style={headerStyle}>Contact</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {caseInfo?.patient?.contactNumber
                            ? caseInfo?.patient?.contactNumber
                            : "NA"}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack marginTop="30px">
                      <VStack alignItems="left" spacing={0}>
                        <Text style={headerStyle}>Address</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {caseInfo?.patient?.patientAddress
                            ? caseInfo?.patient?.patientAddress
                            : "NA"}
                        </Text>
                      </VStack>
                    </HStack>
                  </>
                ) : (
                  <>
                    <HStack w="100%">
                      <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Name</Text>
                        <Input
                          w="70%"
                          variant="flushed"
                          fontWeight="600"
                          h="30px"
                          fontSize="16px"
                          defaultValue={inputPatientData.patientName}
                          name="patientName"
                          onChange={handlePatientInput}
                          style={{ textTransform: "capitalize" }}
                        />
                      </VStack>
                      <VStack alignItems="left" spacing={0} w="40%">
                        <Text style={headerStyle}>UHID</Text>
                        <Input
                          w="70%"
                          variant="flushed"
                          fontWeight="600"
                          h="30px"
                          fontSize="16px"
                          defaultValue={inputPatientData.UHID}
                          name="UHID"
                          onChange={handlePatientInput}
                          style={{ textTransform: "capitalize" }}
                        />
                      </VStack>
                    </HStack>
                    <HStack w="100%" marginTop="30px">
                      <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Date Of Birth</Text>

                        <Input
                          w="70%"
                          h="30px"
                          fontWeight="600"
                          fontSize="16px"
                          variant="flushed"
                          type="date"
                          id="age"
                          name="dob"
                          onChange={(e) => handlePatientInput(e)}
                          max="9999-12-31"
                          value={inputPatientData.dob}
                          borderRadius={5}
                          onWheel={(e) => e.target.blur()}
                          maxLength="2"
                          required
                          style={{ textTransform: "capitalize" }}
                        />
                      </VStack>
                      <VStack alignItems="left" spacing={0} w="40%">
                        <Text style={headerStyle}>Gender</Text>

                        <Select
                          id="gender"
                          name="gender"
                          h="30px"
                          w="70%"
                          fontWeight="600"
                          fontSize="16px"
                          variant="flushed"
                          placeholder="Select Gender"
                          required
                          defaultValue={inputPatientData.gender}
                          onChange={handlePatientInput}
                        >
                          <option hidden disabled value="">
                            Select option
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="non binary">Non Binary</option>
                        </Select>
                      </VStack>
                    </HStack>
                    <HStack marginTop="30px">
                      <VStack alignItems="left" spacing={0}>
                        <Text style={headerStyle}>Contact</Text>
                        <Text fontSize="16px" fontWeight="600">
                          <PhoneInput
                            name="contactNo"
                            country="us"
                            inputProps={{
                              name: "patientContactNumber",
                              required: true,
                              autoFocus: true,
                              id: "contact_number",
                            }}
                            enableSearch="true"
                            placeholder="Enter phone number"
                            value={inputPatientData.contactNo}
                            onChange={(country, formattedValue, value, e) => {
                              setInputPatientData({
                                ...inputPatientData,
                                contactNo: e,
                              });
                            }}
                            style={{
                              width: "100%",
                              height: "38px",
                            }}
                            buttonStyle={{ height: "38px" }}
                            containerStyle={{
                              border: "10px solid black",
                            }}
                            inputStyle={{
                              width: "88%",
                              height: "38px",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          />
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack marginTop="30px" w="100%">
                      <VStack alignItems="left" spacing={0}>
                        <Text style={headerStyle}>Address</Text>
                        <Textarea
                          fontWeight="600"
                          fontSize="16px"
                          variant="flushed"
                          h="50px"
                          maxH="50px"
                          minW="500px"
                          w="100%"
                          name="address"
                          onChange={handlePatientInput}
                          defaultValue={inputPatientData.address}
                          style={{ textTransform: "capitalize" }}
                        />
                      </VStack>
                    </HStack>
                  </>
                )}
              </TabPanel>
              <TabPanel>
                {!isEditing ? (
                  <>
                    <HStack w="100%">
                      <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Case ID</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {caseInfo?.caseId}
                        </Text>
                      </VStack>
                      <VStack alignItems="left" spacing={0} w="40%">
                        <Text style={headerStyle}>Organ</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {caseInfo?.organs
                            ? caseInfo.organs[0].organName
                            : "NA"}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack w="100%" marginTop="30px">
                      <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Department</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {caseInfo?.departmentFrom}
                        </Text>
                      </VStack>
                      <VStack alignItems="left" spacing={0} w="40%">
                        <Text style={headerStyle}>Hospital</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {caseInfo?.treatingHospitalDetails
                            ? caseInfo?.treatingHospitalDetails.hospitalName
                            : "NA"}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack w="100%" marginTop="30px">
                      <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Clinician</Text>
                        <Text fontSize="16px" fontWeight="600">
                          Dr. {caseInfo?.treatingDoctor}
                        </Text>
                      </VStack>
                      <VStack alignItems="left" spacing={0} w="40%">
                        <Text style={headerStyle}>Specimen Size</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {caseInfo?.organs
                            ? caseInfo.organs[1].organSize
                            : "NA"}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack w="100%" marginTop="30px">
                      <VStack alignItems="left" spacing={0} w="80%">
                        <Text style={headerStyle}>Created</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {moment(caseInfo?.caseCreationDate).format(
                            " DD/MM/YYYY HH:mm A"
                          )}
                        </Text>
                      </VStack>
                    </HStack>
                  </>
                ) : (
                  <>
                    <HStack w="100%">
                      <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Case ID</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {caseInfo?.caseId}
                        </Text>
                      </VStack>
                      <VStack alignItems="left" spacing={0} w="40%">
                        <Text style={headerStyle}>Organ</Text>
                        <Select
                          id="organ"
                          name="organ"
                          h="30px"
                          w="70%"
                          fontWeight="600"
                          fontSize="16px"
                          variant="flushed"
                          value={inputCaseData.organ}
                          onChange={(e) => handleCaseInput(e)}
                        >
                          <option hidden disabled value="">
                            Select option
                          </option>
                          <option value="brain">Brain</option>
                          <option value="spinal-cord">Spinal cord</option>
                          <option value="nerves">Nerves</option>
                          <option value="heart">Heart</option>
                          <option value="blood-vessels">Blood vessels</option>
                          <option value="lungs">Lungs</option>
                          <option value="trachea">Trachea</option>
                          <option value="bronchi">Bronchi</option>
                          <option value="diaphragm">Diaphragm</option>
                          <option value="liver">Liver</option>
                          <option value="gallbladder">Gallbladder</option>
                          <option value="pancreas">Pancreas</option>
                          <option value="spleen">Spleen</option>
                          <option value="stomach">Stomach</option>
                          <option value="small-intestine">
                            Small intestine
                          </option>
                          <option value="large-intestine">
                            Large intestine
                          </option>
                          <option value="rectum">Rectum</option>
                          <option value="anus">Anus</option>
                          <option value="kidneys">Kidneys</option>
                          <option value="ureters">Ureters</option>
                          <option value="bladder">Bladder</option>
                          <option value="urethra">Urethra</option>
                          <option value="adrenal-glands">Adrenal glands</option>
                          <option value="thyroid-gland">Thyroid gland</option>
                          <option value="parathyroid-glands">
                            Parathyroid glands
                          </option>
                          <option value="pituitary-gland">
                            Pituitary gland
                          </option>
                          <option value="pineal-gland">Pineal gland</option>
                          <option value="thymus-gland">Thymus gland</option>
                          <option value="ovaries">Ovaries</option>
                          <option value="uterus">Uterus</option>
                          <option value="fallopian-tubes">
                            Fallopian tubes
                          </option>
                          <option value="vagina">Vagina</option>
                          <option value="testes">Testes</option>
                          <option value="penis">Penis</option>
                          <option value="seminal-vesicles">
                            Seminal vesicles
                          </option>
                          <option value="prostate-gland">Prostate gland</option>
                          <option value="skin">Skin</option>
                          <option value="hair">Hair</option>
                          <option value="nails">Nails</option>
                          <option value="muscles">Muscles</option>
                          <option value="bones">Bones</option>
                          <option value="joints">Joints</option>
                          <option value="cartilage">Cartilage</option>
                          <option value="tendons">Tendons</option>
                          <option value="ligaments">Ligaments</option>
                          <option value="eyes">Eyes</option>
                          <option value="ears">Ears</option>
                          <option value="nose">Nose</option>
                          <option value="tongue">Tongue</option>
                          <option value="teeth">Teeth</option>
                          <option value="salivary-glands">
                            Salivary glands
                          </option>
                          <option value="sweat-glands">Sweat glands</option>
                          <option value="mammary-glands">
                            Mammary glands (in females)
                          </option>
                          <option value="adipose-tissue">
                            Fat (adipose tissue)
                          </option>
                          <option value="lymph-nodes">Lymph nodes</option>
                          <option value="lymphatic-vessels">
                            Lymphatic vessels
                          </option>
                          <option value="thymus">Thymus</option>
                          <option value="breast">Breast</option>
                        </Select>
                      </VStack>
                    </HStack>
                    <HStack w="100%" marginTop="30px">
                      <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Department</Text>
                        <Select
                          id="department"
                          name="department"
                          h="30px"
                          w="70%"
                          fontWeight="600"
                          fontSize="16px"
                          variant="flushed"
                          value={inputCaseData.department}
                          onChange={(e) => handleCaseInput(e)}
                        >
                          <option hidden disabled value="">
                            Select option
                          </option>
                          <option value="cytopathology">Cytopathology</option>
                          <option value="hematopathology">
                            Hematopathology
                          </option>
                          <option value="histopathology">Histopathology</option>
                        </Select>
                      </VStack>
                      <VStack alignItems="left" spacing={0} w="40%">
                        <Text style={headerStyle}>Hospital</Text>
                        <Text fontSize="16px" fontWeight="600">
                          <Input
                            defaultValue={inputCaseData.hospital}
                            h="30px"
                            w="70%"
                            fontWeight="600"
                            fontSize="16px"
                            variant="flushed"
                            style={{ textTransform: "capitalize" }}
                            name="hospital"
                            onChange={handleCaseInput}
                          />
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack w="100%" marginTop="30px">
                      <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Clinician</Text>
                        <Input
                          defaultValue={inputCaseData.clinician}
                          h="30px"
                          w="70%"
                          fontWeight="600"
                          fontSize="16px"
                          variant="flushed"
                          style={{ textTransform: "capitalize" }}
                          name="clinician"
                          onChange={handleCaseInput}
                        />
                      </VStack>
                      <VStack alignItems="left" spacing={0} w="40%">
                        <Text style={headerStyle}>Specimen Size</Text>
                        <Select
                          id="specimen_size"
                          name="specimenSize"
                          h="30px"
                          w="70%"
                          fontWeight="600"
                          fontSize="16px"
                          variant="flushed"
                          style={{ textTransform: "capitalize" }}
                          value={inputCaseData.specimenSize}
                          onChange={(e) => handleCaseInput(e)}
                        >
                          <option hidden disabled value="">
                            Select option
                          </option>
                          <option value="large">Large</option>
                          <option value="medium">Medium</option>
                          <option value="small">Small</option>
                        </Select>
                      </VStack>
                    </HStack>
                    <HStack w="100%" marginTop="30px">
                      {/* <VStack alignItems="left" spacing={0} w="60%">
                        <Text style={headerStyle}>Requisition Form</Text>
                        <Text fontSize="16px" fontWeight="600">
                          -
                        </Text>
                      </VStack> */}
                      <VStack alignItems="left" spacing={0} w="80%">
                        <Text style={headerStyle}>Created</Text>
                        <Text fontSize="16px" fontWeight="600">
                          {moment(caseInfo?.caseCreationDate).format(
                            " DD/MM/YYYY HH:mm A"
                          )}
                        </Text>
                      </VStack>
                    </HStack>
                  </>
                )}
              </TabPanel>
            </TabPanels>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end" margin="10px">
              <Button
                onClick={handleCancelClick}
                display={isEditing ? "" : "none"}
                bgColor="#fff"
                color="#010202"
                border="1px solid #CFE2F1"
                size="md"
                width="90px"
                height="30px"
                fontSize="14px"
                _focus={{ outline: "none" }}
                borderRadius="5px"
                fontWeight="400"
              >
                Cancel
              </Button>
              <Button
                bgColor="#1B75BC"
                color="light.100"
                _hover={{ bgColor: "#1B75BC" }}
                size="md"
                _focus={{ outline: "none" }}
                borderRadius="5px"
                fontWeight="400"
                width="90px"
                height="30px"
                fontSize="14px"
                onClick={handleSaveClick}
                display={isEditing ? "" : "none"}
              >
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </Tabs>
      </ModalContent>
    </Modal>
  );
};

export default CaseEditModal;
