import React from "react";
import { Flex, Text, Button, Avatar, Box, HStack } from "@chakra-ui/react";
import { GrCircleInformation } from "react-icons/gr";

const ConversationHeader = ({ groupData, toggleScreen }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      px="22px"
      p="5px"
      // borderBottom="1px solid #dedede"
      minH=""
    >
      <Flex alignItems="center" gap="0.7rem">
        <Flex flexDir="column">
          <Text
            fontWeight="bold"
            textTransform="capitalize"
            fontSize="23px"
            pl="10px"
          >
            {groupData?.caseId
              ? groupData?.caseId
              : `${groupData?.firstName} ${groupData?.lastName}`}
          </Text>

          {/* <Text fontSize="12px">Case No-230-11022-UHID,</Text> */}
        </Flex>
      </Flex>
      <Box cursor="pointer" mr="10px">
        {/* <BiDotsVerticalRounded style={{ fontSize: "20px" }} /> */}
        <Button
          bgColor="#FFFFFF"
          _focus={{ outline: "none" }}
          border="1px solid #E8E8EB"
          borderRadius="5px"
          fontWeight={500}
          fontSize="14px"
          onClick={toggleScreen}
        >
          <HStack>
            <Box pr="5px">
              <GrCircleInformation size={18} />
            </Box>
            <Text>Details</Text>
          </HStack>
        </Button>
      </Box>
    </Flex>
  );
};

export default ConversationHeader;
