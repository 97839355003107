import React, { useState, useEffect, useRef } from "react";
import { Input } from "@chakra-ui/react";

const SearchFilterMenu = ({
  searchValue,
  setSearchValue,
  hideMenu,
  setFilterChecked,
  setPageSize,
  data,
  hideScrollbar,
  setHideScrollBar,
  organ,
  handleFilterClick,
  handleClearFilter,
}) => {
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchValue]);
  const [focusedOption, setFocusedOption] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".filter-menu")) {
        return;
      }
      hideMenu();
      setHideScrollBar(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideMenu]);
  return (
    <div
      className="filter-menu"
      style={{
        // padding: "10px",
        padding: "6px",
        width: "130px",
        // position: "relative",
        // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <Input
        type="text"
        ref={searchInputRef}
        placeholder="Search..."
        value={searchValue}
        onChange={(e) => {
          // if (e.target.value === "") {
          //   setPageSize(10);
          //   setFilterChecked(false);
          // }
          // if (e.target.value !== "") {
          //   setPageSize(data.length);
          //   setFilterChecked(true);
          // }
          setSearchValue(e.target.value);
          if (e.target.value) {
            handleFilterClick(organ);
          } else {
            handleClearFilter(organ);
          }
        }}
        style={{
          width: "100%",
          height: "30px",
          fontSize: "14px",
          padding: "0.5rem",
          // marginBottom: "1rem",
          borderRadius: "4px",
          border: "1px solid #ccc",
        }}
      />
    </div>
  );
};
export default SearchFilterMenu;
