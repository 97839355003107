import React, { useState } from "react";
import {
  Button,
  Flex,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useMediaQuery,
} from "@chakra-ui/react";
import { AiFillCaretDown, AiOutlineCloudUpload } from "react-icons/ai";
import { isActionValid } from "../../hooks/utility";
import SlidesInfo from "../../Case/SlidesInfo";
import UploadSlides from "../../NewCase/UploadSlides";

const SlidesTab = ({
  caseId,
  caseInfo,
  isLoading,
  setNotifyOpenSlide,
  permissions,
}) => {
  // localStorage.setItem("tab", "1");
  const [iflargeScreen] = useMediaQuery("(min-width:1880px)");
  const [isOpenUpload, setOpenUpload] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [ifWidthLessthan1500] = useMediaQuery("(max-width:1700px)");
  const [upload, setupload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stainType, setStainType] = useState("all");
  return (
    <Flex h="100%" w="100%" bgColor="light.1004" direction="column" minH="70vh">
      <Flex
        h="20%"
        w="100%"
        justifyContent="flex-end"
        mt="15px"
        paddingStart="0.8vw"
      >
        <Flex>
          {caseInfo?.slides?.length > 0 ? (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<AiFillCaretDown />}
                border="1px solid #E8E8EB"
                borderRadius="5px"
                bgColor="#fff"
                fontSize="13px"
                fontWeight={500}
                mr="10px"
              >
                <HStack>
                  <Text>Stain/Marker</Text>
                  <Text>{stainType !== "all" ? `${stainType}` : null}</Text>
                </HStack>
              </MenuButton>
              <MenuList p={0}>
                <MenuItem default onClick={() => setStainType("all")}>
                  All
                </MenuItem>
                <MenuItem onClick={() => setStainType("IHC")}>IHC</MenuItem>
                <MenuItem onClick={() => setStainType("H&E")}>H&E</MenuItem>
                {/* <MenuItem onClick={() => setStainType("specialStain")}>
                  Special stain
                </MenuItem> */}
              </MenuList>
            </Menu>
          ) : null}
          <Button
            border="1px solid #E8E8EB"
            borderRadius="5px"
            bgColor="#fff"
            fontSize="13px"
            fontWeight={500}
            mr="10px"
            // onClick={() => setOpenUpload(true)}
            onClick={() => setupload(true)}
            disabled={isActionValid("slideUpload", permissions)}
          >
            <HStack>
              {" "}
              <AiOutlineCloudUpload size={20} />
              <Text>Upload new slide</Text>
            </HStack>
          </Button>
        </Flex>
      </Flex>
      <SlidesInfo
        setNotifyOpenSlide={setNotifyOpenSlide}
        slides={caseInfo?.slides}
        caseId={caseId}
        isLoading={isLoading}
        stainType={stainType}
        caseInfo={caseInfo}
        permissions={permissions}
      />
      <Modal
        isOpen={upload}
        onClose={() => {
          setOpenModel(true);
          setupload(false);
        }}
        isCentered
        closeOnOverlayClick={false}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent
          background="light.200"
          w="60%"
          borderRadius={0}
          minH={
            loading
              ? openModel
                ? iflargeScreen
                  ? "645px"
                  : "540px"
                : "120px"
              : iflargeScreen
              ? "645px"
              : "540px"
          }
          maxH={
            loading
              ? openModel
                ? iflargeScreen
                  ? "645px"
                  : "540px"
                : "120px"
              : iflargeScreen
              ? "645px"
              : "540px"
          }
        >
          <ModalCloseButton />
          <ModalBody>
            <UploadSlides
              openModel={openModel}
              setOpenModel={setOpenModel}
              caseId={caseId}
              caseInfo={caseInfo}
              stainType={stainType}
              setClose={setupload}
              setLoading={setLoading}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default SlidesTab;
