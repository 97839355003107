import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  useToast,
  useMediaQuery,
  VStack,
  HStack,
  Spinner,
} from "@chakra-ui/react";

import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NewCaseDetails from "./NewCaseDetails";
import PatientDetails from "./PatientDetails";
import { resetNewCase } from "../state/Reducers/newCaseReducer";
import {
  useCreateCaseMutation,
  useAddUserToCaseMutation,
  useAddClinicalHistoryToCaseMutation,
  useAddGrossToCaseMutation,
  useAddRadiologyToCaseMutation,
  useGetUserInfoQuery,
} from "../state/API/HospitalApi";
import Environment from "../environment";
import UploadFiles from "./UploadFiles";
import CreatedCaseConfirmation from "./CreatedCaseConfirmation";

const NewCase = ({ userInfo }) => {
  const { user } = useAuth0();
  const [fileData, setFileData] = useState(false);
  const [fileDataClinical, setFileDataClinical] = useState(false);
  const [fileDataGross, setFileDataGross] = useState(false);
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [blurState, setBlurState] = useState(false);
  const [caseCreatedPage, setCaseCreatedPage] = useState(false);
  const [patientName, setpatientName] = useState(null);
  const [index, setIndex] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const { caseDetails } = useSelector((state) => state.newCaseState);
  const [createdCaseResponse, setCreatedCaseResponse] = useState(null);
  const navigate = useNavigate();
  const [createCase] = useCreateCaseMutation();
  const [addUser] = useAddUserToCaseMutation();
  const [addClinicalHistory] = useAddClinicalHistoryToCaseMutation();
  const [addGross] = useAddGrossToCaseMutation();
  const [addRadiology] = useAddRadiologyToCaseMutation();
  const toast = useToast();
  const [allFiles, setAllFiles] = useState([]);
  const [allFiles1, setAllFiles1] = useState([]);
  const [allFiles2, setAllFiles2] = useState([]);
  const dispatch = useDispatch();
  const handleReset = () => {
    dispatch(resetNewCase());
    navigate(`/dashboard/${userInfo?.userType}`);
  };
  const { data: userInfo1, isLoading } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user?.sub }
  );

  const grossForm = new FormData();
  caseDetails?.grossImages.forEach((element, i) => {
    grossForm.append("files", caseDetails?.grossImages[i]);
  });

  const clinicalHistoryForm = new FormData();
  caseDetails?.clinicalHistory.forEach((element, i) => {
    clinicalHistoryForm.append("files", caseDetails?.clinicalHistory[i]);
  });

  const radiologyForm = new FormData();
  caseDetails?.radiologyImages.forEach((element, i) => {
    radiologyForm.append("files", caseDetails?.radiologyImages[i]);
  });

  const handleCreateCase = async () => {
    try {
      setBlurState(true);
      const resp = await createCase({
        organizationId: userInfo?.organization?._id,
        subClaim: user?.sub,
        departmentTo: caseDetails.departmentTo,
        departmentFrom: caseDetails.departmentFrom,
        caseId: caseDetails.caseId,
        turnAroundTime: caseDetails.turnAroundTime,
        organs: [
          { organName: caseDetails.organ },
          { organSize: caseDetails.specimenSize },
        ],
        patientDetails: {
          patientName: {
            firstName: caseDetails.patientName,
            lastName: "",
          },
          gender: caseDetails.patientGender,
          dateOfBirth: caseDetails.patientDOB,
          patientAddress: caseDetails.patientAddress,
          patientDescription: caseDetails.patientDescription,
          contactNumber: caseDetails.patientContactNumber,
          uhid: caseDetails.uhid,
        },
        treatingHospitalDetails: {
          hospitalName: caseDetails.hospital,
          hospitalHelpline: caseDetails.helplineNumber,
        },
        caseStatus: caseDetails.urgentCase,
        treatingDoctor: caseDetails.clinician,
      });
      if (caseDetails?.grossImages) {
        const grossUrls = await axios.post(
          `${Environment.USER_URL}/upload_file_to_gross`,
          grossForm
        );
        const dataarray = [];
        grossUrls.data.urls.forEach((element, i) => {
          const obj = {
            docUrl: element,
            docDescription: caseDetails.grossDescription,
            uploadedBy: `${userInfo1.firstName} ${userInfo1.lastName}`,
            docName: allFiles2[i]?.name,
            docType:
              allFiles2[i]?.name.slice(-3) === "peg"
                ? "jpeg"
                : allFiles2[i]?.name.slice(-3),
            docSize: allFiles2[i]?.size,
          };
          if (obj.docName !== undefined) {
            dataarray.push(obj);
          }
        });
        await addGross({
          caseId: resp.data._id,
          docs: dataarray,
          subClaim: user?.sub,
        });
      }

      if (caseDetails?.clinicalHistory) {
        const clinicalHistoryUrls = await axios.post(
          `${Environment.USER_URL}/multi_clinical_history`,
          clinicalHistoryForm
        );

        const dataarray = [];
        clinicalHistoryUrls.data.urls.forEach((element, i) => {
          const obj = {
            docUrl: element,
            docDescription: caseDetails.clinicalHistoryDetails,
            uploadedBy: `${userInfo1.firstName} ${userInfo1.lastName}`,
            docName: allFiles[i]?.name,
            docType:
              allFiles[i]?.name.slice(-3) === "peg"
                ? "jpeg"
                : allFiles[i]?.name.slice(-3),
            docSize: allFiles[i]?.size,
          };
          if (obj.docName !== undefined) {
            dataarray.push(obj);
          }
        });
        await addClinicalHistory({
          caseId: resp.data._id,
          docs: dataarray,
        });
      }

      if (caseDetails?.radiologyImages) {
        const radioUrls = await axios.post(
          `${Environment.USER_URL}/upload_radiology_images`,
          radiologyForm
        );
        const dataarray = [];
        radioUrls.data.urls.forEach((element, i) => {
          const obj = {
            docUrl: element,
            docDescription: caseDetails.radiologyInstructions,
            uploadedBy: `${userInfo1.firstName} ${userInfo1.lastName}`,
            docName: allFiles1[i]?.name,
            docType:
              allFiles1[i]?.name.slice(-3) === "peg"
                ? "jpeg"
                : allFiles1[i]?.name.slice(-3),
            docSize: allFiles1[i]?.size,
          };
          if (obj.docName !== undefined) {
            dataarray.push(obj);
          }
        });
        await addRadiology({
          caseId: resp.data._id,
          docs: dataarray,
          subClaim: user?.sub,
        });
      }

      if (userInfo?.userType === "pathologist") {
        await addUser({
          userId: userInfo._id,
          caseId: resp.data._id,
          subClaim: user?.sub,
        });
      }
      setCaseCreatedPage(true);
      setCreatedCaseResponse(resp);
      setpatientName(caseDetails?.patientName);
    } catch {
      toast({
        title: "Error",
        description: `Some error while creating case`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    dispatch(resetNewCase());
    setBlurState(false);
    // navigate(`/dashboard/${userInfo?.userType}`);
  };

  const getFile = (file) => {
    setAllFiles(file);
  };
  const getFile1 = (file) => {
    setAllFiles1(file);
  };
  const getFile2 = (file) => {
    setAllFiles2(file);
  };
  return (
    <Flex
      h="100vh"
      w="100vw"
      // minH="800px"
      background="light.800"
      zIndex="100"
      alignItems="flex-end"
      justifyContent="center"
      mb="70px"
    >
      <Flex
        h="90%"
        pos="absolute"
        bottom={0}
        w="75%"
        bgColor="#fff"
        p="3% 6% 0 6%"
        direction="column"
        overflowY="scroll"
        css={{
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            width: "5px",
            marginTop: "58px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
      >
        {caseCreatedPage ? (
          <CreatedCaseConfirmation
            setCaseCreatedPage={setCaseCreatedPage}
            userInfo={userInfo}
            createdCaseResponse={createdCaseResponse}
            patientName={patientName}
            setIndex={setIndex}
          />
        ) : (
          <>
            <Flex w="100%" justifyContent="space-between">
              <VStack alignItems="flex-start">
                <Text
                  fontSize={ifWidthLessthan1920 ? "16px" : "20px"}
                  color="light.1006"
                  fontWeight={600}
                >
                  Create a case
                </Text>
                <Text fontSize={ifWidthLessthan1920 ? "12px" : "14px"}>
                  Fill details below to create a case
                </Text>
              </VStack>
              <HStack color="light.1006">
                <Button
                  w="150px"
                  bgColor="inherit"
                  border="1px solid rgba(27, 117, 188, 0.25)"
                  borderRadius="10px"
                  h="37px"
                  onClick={handleReset}
                >
                  Cancel
                </Button>
                {index === 2 ? (
                  <Button
                    w="150px"
                    borderRadius="10px"
                    h="37px"
                    bgColor="rgba(27, 117, 188, 0.25)"
                    onClick={handleCreateCase}
                    disabled={
                      !caseDetails?.departmentFrom ||
                      !caseDetails?.departmentTo ||
                      !caseDetails?.organ ||
                      !caseDetails?.clinician ||
                      !caseDetails?.hospital ||
                      !caseDetails?.patientName ||
                      !caseDetails?.uhid ||
                      !caseDetails?.caseId ||
                      !caseDetails?.patientGender ||
                      !caseDetails?.patientDOB ||
                      !caseDetails?.specimenSize
                    }
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    w="150px"
                    borderRadius="10px"
                    h="37px"
                    bgColor="rgba(27, 117, 188, 0.25)"
                    onClick={() => setIndex(index + 1)}
                  >
                    Next
                  </Button>
                )}
              </HStack>
            </Flex>
            {index === 0 ? (
              <NewCaseDetails
                setIndex={setIndex}
                isClicked={isClicked}
                setIsClicked={setIsClicked}
              />
            ) : index === 1 ? (
              <PatientDetails
                setIndex={setIndex}
                isClicked={isClicked}
                setIsClicked={setIsClicked}
              />
            ) : index === 2 ? (
              <UploadFiles
                fileDataClinical={fileDataClinical}
                setFileDataClinical={setFileDataClinical}
                fileDataGross={fileDataGross}
                setFileDataGross={setFileDataGross}
                setFileData={setFileData}
                fileData={fileData}
                getFile={getFile}
                getFile1={getFile1}
                getFile2={getFile2}
                setIndex={setIndex}
              />
            ) : null}
          </>
        )}
      </Flex>
      {blurState ? (
        <Flex
          zIndex={1000}
          position="absolute"
          bgColor="#fff"
          opacity="0.5"
          top={0}
          left={0}
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="xl" color="#000" />
        </Flex>
      ) : null}
    </Flex>
  );
};

export default NewCase;
