import {
  Flex,
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  useDisclosure,
  useMediaQuery,
  Divider,
  Image,
  Icon,
  VStack,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BsPersonCheck } from "react-icons/bs";
import moment from "moment";
import { BiUpload } from "react-icons/bi";
import axios from "axios";
import { AiOutlineMenu } from "react-icons/ai";
import UploadClinicalHistory from "../NewCase/UploadClinicalHistory";
import SlidesInfo from "./SlidesInfo";
import ClinicalHistory from "./ClinicalHistory";
import GrossImages from "./GrossImages";
import {
  useGetCaseInfoQuery,
  useGetUserInfoQuery,
  useUpdateRadiologyInfoMutation,
  useGetReportInfoQuery,
} from "../state/API/HospitalApi";
import UploadSlides from "../NewCase/UploadSlides";
import fileCount from "../Static/Images/fileCount.svg";
import Environment from "../environment";
import AssignCase from "./AssignCase";
import ReportDocument from "./Report";
import CaseDetails from "./CaseDetails";
import Timeline from "./CaseTimeline";
import SynopticReport from "../Components/SynopticReport/SynopticReport";

const Case = ({ setCaseData, caseId, caseInfoData }) => {
  const { user } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user?.sub }
  );
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [ifWidthLessthan1650] = useMediaQuery("(max-width:1650px)");
  const [ifWidthLessthan1200] = useMediaQuery("(max-width:1200px)");
  const { data: caseInfo, isLoading } = useGetCaseInfoQuery({
    caseId,
    subClaim: user?.sub,
  });
  const { data: slideInfo } = useGetReportInfoQuery({
    caseId,
  });
  // to fetch synoptic report
  const [fetchReport, setFetchReport] = useState(false);
  const [caseState, setCaseState] = useState("Slides");
  const { isOpen, onClose } = useDisclosure();
  const [isOpenUpload, setOpenUpload] = useState(false);
  setCaseData(caseInfo);
  localStorage.setItem("caseData", JSON.stringify(caseInfo));

  const [radiologyImages] = useUpdateRadiologyInfoMutation();
  const form = new FormData();
  const handleUpload = async (e) => {
    form.append("files", e.target.files[0]);
    const resp = await axios.post(
      `${Environment.USER_URL}/multi_media_file_upload`,
      form
    );
    await radiologyImages({
      caseId,
      urls: resp.data.urls,
      subClaim: user?.sub,
    });
  };
  // synoptic report helper
  useEffect(() => {
    return () => setFetchReport(false);
  }, [caseId]);
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      w="100%"
      h="100%"
      overflow="hidden"
      fontSize="paragraph"
      boxShadow="1px 1px 2px rgba(176, 200, 214, 0.15)"
    >
      {/* <Loading /> */}
      <Flex
        h="4.814vh"
        pl="0.937vw"
        justifyContent="space-between"
        alignItems="center"
        // boxShadow="1px 0px 2px rgba(21, 28, 37, 0.1)"
        background="white"
        borderBottom="none"
        borderLeft="1px solid rgba(176, 200, 214, 0.15)"
        boxShadow="1px 1px 2px rgba(176, 200, 214, 0.15)"
      >
        {ifWidthLessthan1650 &&
        (userInfo?.userType === "admin" ||
          userInfo?.userType === "technologist") ? (
          <Box>
            <Menu>
              <MenuButton
                h="4vh"
                as={Button}
                _focus={{ outline: "none" }}
                variant="outline"
              >
                <AiOutlineMenu color="#3B5D7C" />
              </MenuButton>
              <MenuList p={0}>
                <MenuItem onClick={() => setCaseState("Slides")}>
                  Slides
                </MenuItem>
                <MenuItem onClick={() => setCaseState("Clinical History")}>
                  Clinical History
                </MenuItem>
                <MenuItem onClick={() => setCaseState("Gross Images")}>
                  Gross Images
                </MenuItem>
                <MenuItem onClick={() => setCaseState("Case Information")}>
                  Case Information
                </MenuItem>
                <MenuItem onClick={() => setCaseState("Case Timeline")}>
                  Case Timeline
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        ) : ifWidthLessthan1200 ? (
          <Box>
            <Menu>
              <MenuButton
                h="4vh"
                as={Button}
                _focus={{ outline: "none" }}
                variant="outline"
              >
                <AiOutlineMenu color="#3B5D7C" />
              </MenuButton>
              <MenuList p={0}>
                <MenuItem onClick={() => setCaseState("Slides")}>
                  Slides
                </MenuItem>
                <MenuItem onClick={() => setCaseState("Clinical History")}>
                  Clinical History
                </MenuItem>
                <MenuItem onClick={() => setCaseState("Gross Images")}>
                  Gross Images
                </MenuItem>
                <MenuItem onClick={() => setCaseState("Case Information")}>
                  Case Information
                </MenuItem>
                <MenuItem onClick={() => setCaseState("Case Timeline")}>
                  Case Timeline
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        ) : (
          <Flex bg="#f6f6f6" gap="2px">
            <Button
              w="4.1vw"
              h="4.074vh"
              px="0.833vw"
              size="xs"
              bgColor={caseState === "Slides" ? "light.900" : "white"}
              color={caseState === "Slides" ? "light.700" : "#000"}
              borderBottom={caseState === "Slides" ? "2px solid #3B5D7C" : ""}
              boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
              fontWeight={400}
              borderRadius="0px"
              _focus={{ outline: "none" }}
              _hover={{ bgColor: "light.900" }}
              onClick={() => setCaseState("Slides")}
            >
              <Text fontSize={ifWidthLessthan1920 ? "14px" : "0.72916vw"}>
                Slides
              </Text>
            </Button>
            <Button
              w="7.1vw"
              h="4.074vh"
              size="xs"
              px="3.833vw"
              fontWeight={400}
              borderRadius="0px"
              bgColor={caseState === "Clinical History" ? "light.900" : "white"}
              color={caseState === "Clinical History" ? "light.700" : "#000"}
              boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
              borderBottom={
                caseState === "Clinical History" ? "2px solid #3B5D7C" : ""
              }
              _focus={{ outline: "none" }}
              _hover={{ bgColor: "light.900" }}
              onClick={() => setCaseState("Clinical History")}
            >
              <Text fontSize={ifWidthLessthan1920 ? "14px" : "0.72916vw"}>
                Clinical History
              </Text>
            </Button>

            <Button
              w="7.1vw"
              h="4.074vh"
              px="0.833vw"
              size="xs"
              fontWeight={400}
              borderRadius="0px"
              bgColor={caseState === "Gross Images" ? "light.900" : "white"}
              color={caseState === "Gross Images" ? "light.700" : "#000"}
              boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
              borderBottom={
                caseState === "Gross Images" ? "2px solid #3B5D7C" : ""
              }
              _focus={{ outline: "none" }}
              _hover={{ bgColor: "light.900" }}
              onClick={() => setCaseState("Gross Images")}
            >
              <Text fontSize={ifWidthLessthan1920 ? "14px" : "0.72916vw"}>
                Gross Images
              </Text>
            </Button>
            <Button
              w="9.1vw"
              h="4.074vh"
              px="0.833vw"
              size="xs"
              fontWeight={400}
              borderRadius="0px"
              bgColor={caseState === "Case Information" ? "light.900" : "white"}
              color={caseState === "Case Information" ? "light.700" : "#000"}
              boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
              borderBottom={
                caseState === "Case Information" ? "2px solid #3B5D7C" : ""
              }
              _focus={{ outline: "none" }}
              _hover={{ bgColor: "light.900" }}
              onClick={() => setCaseState("Case Information")}
            >
              <Text fontSize={ifWidthLessthan1920 ? "14px" : "0.72916vw"}>
                Case Information
              </Text>
            </Button>
            <Button
              w="7.5vw"
              h="4.074vh"
              px="0.833vw"
              size="xs"
              fontWeight={400}
              borderRadius="0px"
              bgColor={caseState === "Case Timeline" ? "light.900" : "white"}
              color={caseState === "Case Timeline" ? "light.700" : "#000"}
              boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
              borderBottom={
                caseState === "Case Timeline" ? "2px solid #3B5D7C" : ""
              }
              _focus={{ outline: "none" }}
              _hover={{ bgColor: "light.900" }}
              onClick={() => setCaseState("Case Timeline")}
            >
              <Text fontSize={ifWidthLessthan1920 ? "14px" : "0.72916vw"}>
                Case Timeline
              </Text>
            </Button>
          </Flex>
        )}

        {userInfo?.userType === "admin" ||
        userInfo?.userType === "technologist" ? (
          // caseInfo?.caseUsers.length > 0 ? (
          //   ""
          // ) :
          <Flex
            alignItems="center"
            justifyContent="space-evenly"
            display={isLoading ? "none" : "flex"}
            pr="0.6vw"
            minW="45%"
          >
            <Flex
              flexWrap="wrap"
              alignItems="center"
              // display={!toggleCaseSidebar ? "flex" : "none"}
            >
              <Divider orientation="vertical" h="4.814vh" />

              <Box fontSize="14px" px="0.2vw">
                <Text mb="-0.3rem !important">Case Assigned to :</Text>
                <Text color="light.700">
                  {caseInfo?.caseUsers[0]?.userId?.firstName === undefined &&
                  caseInfo?.caseUsers[0]?.userId?.lastName === undefined
                    ? "Case not assigned"
                    : `Dr.
                ${caseInfo?.caseUsers[0]?.userId?.firstName}
                ${caseInfo?.caseUsers[0]?.userId?.lastName}`}
                </Text>
              </Box>
            </Flex>
            <Menu>
              <MenuButton
                as={Button}
                size="xs"
                h="4.074vh"
                background="white"
                _hover={{ background: "none" }}
                _focus={{ outline: "none" }}
                _active={{ background: "light.900" }}
                leftIcon={<BsPersonCheck size={20} />}
                borderRadius="0px"
                color="rgba(59, 93, 124, 1)"
                fontSize={ifWidthLessthan1920 ? "14px" : "0.72916vw"}
                fontWeight="500"
              >
                {caseInfo?.caseUsers.length > 0
                  ? "Reassign Case"
                  : "Assign Case"}
              </MenuButton>
              <MenuList
                borderRadius="0px"
                borderColor="#3B5D7C"
                bg="light.100"
                p="0"
              >
                <MenuItem
                  _hover={{ bg: "light.100" }}
                  bg="light.100"
                  _focus={{ bg: "light.100" }}
                  p="0"
                >
                  <AssignCase caseId={caseId} userInfo={userInfo} />
                </MenuItem>
              </MenuList>
            </Menu>
            {/* {caseInfo?.reported ? ( */}
            <Menu autoSelect={false}>
              <MenuButton
                backgroundColor="#3B5D7C"
                color="#fff"
                as={Button}
                _hover={{ bg: "##B5D7C" }}
                size="sm"
                borderRadius="0"
                fontWeight="400"
                onClick={() => setFetchReport(true)}
              >
                View Report
              </MenuButton>

              <MenuList
                borderRadius="0"
                px="1.2vw"
                fontSize="14px"
                fontFamily="inter"
              >
                <MenuItem
                  borderBottom="1px solid #DEDEDE"
                  _hover={{ bg: "none" }}
                >
                  <ReportDocument
                    caseInfo={caseInfo}
                    slideInfo={slideInfo}
                    userInfo={userInfo}
                  />
                </MenuItem>

                <SynopticReport
                  caseInfo={caseInfo}
                  slideInfo={slideInfo}
                  userInfo={userInfo}
                  fetchReport={fetchReport}
                />
              </MenuList>
            </Menu>
            {/* ) : null} */}

            {/* <IconButton
              size="sm"
              bgColor="white"
              _focus={{ outline: "none" }}
              _hover={{
                color: "light.700",
                bgColor: "light.900",
              }}
              mx="0.7vw"
              borderRadius="0"
              icon={<FiMoreVertical />}
              onClick={setOpen}
              isDisabled
            /> */}
          </Flex>
        ) : (
          // Buttons to share, download a report, and calling. Will be implemented later
          <Flex mr="1.5vw">
            <HStack spacing="0.7vw">
              {/* <Button
                // width="7.1875vw"
                isDisabled
                height="3.5vh"
                _focus={{ outline: "none" }}
                _hover={{ bgColor: "none" }}
                borderRadius="0px"
                fontSize="paragraph"
                bgColor="light.700"
                color="#fff"
                fontWeight={400}
                // onClick={setOpen}
              >
                Add Instruction
              </Button>
              <Divider orientation="vertical" border="1.5px solid light.400" /> */}
              <Menu autoSelect={false}>
                <MenuButton
                  backgroundColor="#3B5D7C"
                  color="#fff"
                  as={Button}
                  _hover={{ bg: "##B5D7C" }}
                  size="sm"
                  borderRadius="0"
                  fontWeight="400"
                  onClick={() => setFetchReport(true)}
                >
                  View Report
                </MenuButton>

                <MenuList
                  borderRadius="0"
                  px="1.2vw"
                  fontSize="14px"
                  fontFamily="inter"
                >
                  <MenuItem
                    borderBottom="1px solid #DEDEDE"
                    _hover={{ bg: "none" }}
                  >
                    <ReportDocument
                      caseInfo={caseInfo}
                      slideInfo={slideInfo}
                      userInfo={userInfo}
                    />
                  </MenuItem>
                  <SynopticReport
                    caseInfo={caseInfo}
                    slideInfo={slideInfo}
                    userInfo={userInfo}
                    fetchReport={fetchReport}
                  />
                </MenuList>
              </Menu>
            </HStack>
          </Flex>
        )}
      </Flex>

      <Box
        h="100%"
        overflowX="auto"
        pl="1.1vw"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
        background="white"
        borderLeft="1px solid rgba(176, 200, 214, 0.15)"
        borderTop="1px solid rgba(176, 200, 214, 0.15)"
      >
        {userInfo?.userType === "admin" ||
        userInfo?.userType === "technologist" ||
        userInfo?.userType === "pathologist" ? (
          <Flex
            boxShadow="1px 2px 2px rgba(176, 200, 214, 0.15)"
            w="100%"
            minW="100%"
            alignItems="center"
            px="0.937vw"
            py="1.1vh"
            justifyContent="space-between"
            mt="1.1vh"
          >
            <VStack alignItems="flex-start">
              <Text fontSize={ifWidthLessthan1920 ? "12px" : "0.625vw"}>
                {moment(caseInfo?.caseCreationDate).format(
                  "dddd DD/MM/YYYY h:mm:ss a"
                )}
              </Text>
              <Text
                fontSize={ifWidthLessthan1920 ? "14px" : " 0.8333vw"}
                mt="-0rem !important"
              >
                {caseInfo?.caseName}
              </Text>
            </VStack>
            {caseState === "Slides" ? (
              <Flex alignItems="center">
                <Image src={fileCount} mr="4px" />
                <Text fontSize="paragraph" py="1.1vh" mr="0.625vw">
                  {caseInfo?.slides?.length}
                </Text>

                <Divider
                  orientation="vertical"
                  border="1.5px solid light.400"
                />

                <Button
                  size="sm"
                  _focus={{ outline: "none" }}
                  _hover={{ bgColor: "none" }}
                  borderRadius="0px"
                  fontSize="paragraph"
                  bgColor="light.500"
                  border="1px solid #3B5D7C"
                  color="light.700"
                  onClick={() => setOpenUpload(true)}
                  leftIcon={<BiUpload />}
                  ml="0.3vw"
                >
                  Upload Slide
                </Button>
              </Flex>
            ) : caseState === "Clinical History" ? (
              <Flex alignItems="center">
                {/* <Button
                  leftIcon={<HiOutlinePencilAlt />}
                  background="none"
                  sixe="xs"
                  color="light.700"
                  fontWeight="500"
                  fontSize="paragraph"
                  _focus={{ background: "none" }}
                  _hover={{ background: "none" }}
                  borderRadius="0px"
                  onClick={setOpen}
                >
                  Edit Info
                </Button> */}
                <Divider
                  orientation="vertical"
                  border="1.5px solid light.400"
                />
                <Flex
                  borderRadius="0px"
                  fontSize="12px"
                  bgColor="light.500"
                  border="1px solid #3B5D7C"
                  color="light.700"
                  ml="0.3vw"
                  position="relative"
                  py="0.833vh"
                  px="0.937vw"
                  alignItems="center"
                >
                  <Icon as={BiUpload} mr="0.416vw" />
                  <Input
                    type="file"
                    opacity="0"
                    css={{ width: "100%", position: "absolute" }}
                    onChange={handleUpload}
                    accept=".doc,.docx,.jpeg,.jpg,.png"
                  />
                  Upload Radiology Image
                </Flex>
              </Flex>
            ) : caseState === "Gross Images" ? (
              <Flex alignItems="center">
                {/* <Button
                  leftIcon={<HiOutlinePencilAlt />}
                  background="none"
                  sixe="xs"
                  color="light.700"
                  fontWeight="500"
                  fontSize="12px"
                  _focus={{ background: "none" }}
                  _hover={{ background: "none" }}
                  borderRadius="0px"
                  onClick={setOpen}
                >
                  Edit Info
                </Button>
                <Divider
                  orientation="vertical"
                  border="1.5px solid light.400"
                />
                <Button
                  size="sm"
                  _focus={{ outline: "none" }}
                  _hover={{ bgColor: "none" }}
                  borderRadius="0px"
                  fontSize="12px"
                  bgColor="light.500"
                  border="1px solid #3B5D7C"
                  color="light.700"
                  ml="0.3vw"
                  onClick={setOpen}
                >
                  Add more Images
                </Button> */}
              </Flex>
            ) : (
              ""
            )}
          </Flex>
        ) : (
          ""
        )}
        {caseState === "Slides" ? (
          <SlidesInfo
            slides={caseInfo?.slides}
            caseId={caseId}
            caseInfo={caseInfo}
            isLoading={isLoading}
            userInfo={userInfo}
          />
        ) : caseState === "Clinical History" ? (
          <ClinicalHistory caseInfo={caseInfo} userInfo={userInfo} />
        ) : caseState === "Gross Images" ? (
          <GrossImages caseInfo={caseInfo} userInfo={userInfo} />
        ) : caseState === "Case Information" ? (
          <CaseDetails caseInfoData={caseInfoData} />
        ) : caseState === "Case Timeline" ? (
          <Timeline caseInfoData={caseInfoData} caseInfo={caseInfo} />
        ) : (
          ""
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
        <ModalOverlay />
        <ModalContent maxH="968px" maxW="804px" background="light.200">
          <ModalHeader fontSize={ifWidthLessthan1920 ? "16px" : " 0.8333vw"}>
            Create Case
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <UploadClinicalHistory onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenUpload}
        onClose={() => setOpenUpload(false)}
        isCentered
        closeOnOverlayClick={false}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent
          background="light.200"
          w="fit-content"
          borderRadius={0}
          minH="350px"
        >
          <ModalCloseButton />
          <ModalBody>
            <UploadSlides
              caseId={caseId}
              caseInfoData={caseInfoData}
              caseInfo={caseInfo}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Case;
