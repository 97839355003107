import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  VStack,
  useMediaQuery,
  HStack,
  Button,
  Input,
  Select,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";
import moment from "moment";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useGetCaseInfoQuery,
  useGetUserInfoQuery,
} from "../../../state/API/HospitalApi";
import Environment from "../../../environment";
import ScrollBar from "../../other/Scrollbar";
import CaseEditModal from "../CaseEditModal";

const Overview = ({ caseInfo }) => {
  const toast = useToast();
  const { user } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [inputPatientData, setInputPatientData] = useState({
    patientName: "",
    gender: "",
    dob: "",
    address: "",
    UHID: "",
    contactNo: "",
  });
  const [inputCaseData, setInputCaseData] = useState({
    // caseId: "",
    organ: "",
    department: "",
    hospital: "",
    clinician: "",
    specimenSize: "",
  });
  const handleTabChange = (index) => {
    setActiveTab(index);
  };
  const openModal = () => {
    setActiveTab(1); // Set active tab to Case Details
    setIsEditing(false); // Reset editing mode when opening modal
    // setCaseNumber(selectedCase.caseNumber);
    // setCaseDescription(selectedCase.caseDescription);
    // setPatientName("");
    // setPatientAge("");
    // setPatientDiagnosis("");
    onOpen();
  };
  const handleEditClick = () => {
    setInputCaseData({
      // caseId: caseInfo?.caseId,
      organ: caseInfo?.organs[0].organName,
      department: caseInfo?.departmentFrom,
      hospital: caseInfo?.treatingHospitalDetails.hospitalName,
      clinician: caseInfo?.treatingDoctor,
      specimenSize: caseInfo?.organs[1].organSize,
    });
    setInputPatientData({
      patientName: `${caseInfo?.patient?.patientName?.firstName} ${caseInfo?.patient?.patientName?.lastName}`,
      gender: caseInfo?.patient?.gender,
      dob: `${caseInfo?.patient?.dateOfBirth}`,
      contactNo: caseInfo?.patient?.contactNumber
        ? caseInfo?.patient?.contactNumber
        : "NA",
      address: caseInfo?.patient?.patientAddress
        ? caseInfo?.patient?.patientAddress
        : "NA",
      UHID: caseInfo?.patient?.uhid,
    });

    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    onClose();
    setIsEditing(false);

    const resp = await axios.put(
      `${Environment.USER_URL}/edit_patient_info?_id=${caseInfo?.patient._id}`,
      {
        patientName: { firstName: inputPatientData.patientName, lastName: "" },
        gender: inputPatientData.gender,
        dateOfBirth: inputPatientData.dob,
        contactNumber: inputPatientData.contactNo,
        patientAddress: inputPatientData.address,
        uhid: inputPatientData.UHID,
      }
      // {
      //   patientName: { firstName: "test", lastName: "" },
      //   gender: "male",
      //   dateOfBirth: "1955-06-22",
      //   contactNumber: "+1 (338) 499-8399",
      //   patientAddress:
      //     "aksjdfajsdfj asdjf lkajsdflkjsadlkjfkl ajsdlfk jaslkdjf",
      //   uhid: "38474839384848",
      // }
    );

    const caseResp = await axios.put(
      `${Environment.USER_URL}/edit_case_info?_id=${caseInfo?._id}`,
      {
        organs: [
          { organName: inputCaseData.organ },
          { organSize: inputCaseData.specimenSize },
        ],
        treatingHospitalDetails: { hospitalName: inputCaseData.hospital },
        treatingDoctor: inputCaseData.clinician,
        departmentFrom: inputCaseData.department,
        subClaim: user?.sub,
      }
    );
    if (resp.status === 200 || caseResp === 200) {
      onClose();
      toast({
        title: "Case details updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(function () {
        window.location.replace(`/dashboard/case`);
        // window.location.reload();
      }, 1000);
    } else {
      toast({
        title: "Some error occured",
        description: "Please try again after some time",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    }
  };

  const handleCaseInput = (e) => {
    setInputCaseData({ ...inputCaseData, [e.target.name]: e.target.value });
  };
  const handlePatientInput = (e) => {
    setInputPatientData({
      ...inputPatientData,
      [e.target.name]: e.target.value,
    });
  };
  // const [isEditActive, setIsEditActive] = useState(false);
  // const [inputData, setInputData] = useState({
  //   caseId: "",
  //   organ: "",
  //   department: "",
  //   hospital: "",
  //   clinician: "",
  //   specimenSize: "",
  // });

  // useEffect(() => {
  //   if (caseInfo) {
  //     setInputData({
  //       caseId: caseInfo?.caseId,
  //       organ: caseInfo?.organs[0].organName,
  //       department: caseInfo?.departmentFrom,
  //       hospital: caseInfo?.treatingHospitalDetails.hospitalName,
  //       clinician: caseInfo?.treatingDoctor,
  //       specimenSize: caseInfo?.organs[1].organSize,
  //     });
  //   }
  // }, caseInfo);
  // const handleInput = (e) => {
  //   setInputData({ ...inputData, [e.target.name]: e.target.value });
  // };
  // const handleSubmit = async () => {
  //   const resp = await axios.put(
  //     `${Environment.USER_URL}/edit_case_info?_id=${caseInfo?._id}`,
  //     {
  //       organs: [
  //         { organName: inputData.organ },
  //         { organSize: inputData.specimenSize },
  //       ],
  //       treatingHospitalDetails: { hospitalName: inputData.hospital },
  //       treatingDoctor: inputData.clinician,
  //       caseId: inputData.caseId,
  //       departmentFrom: inputData.department,
  //     }
  //   );
  //   if (resp.status === 200) {
  //     toast({
  //       title: "Case details updated",
  //       status: "success",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //     setTimeout(() => window.location.reload(), 1500);
  //   } else {
  //     toast({
  //       title: "Some error occured",
  //       description: "Please try again after some time",
  //       status: "error",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   }
  // };
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const headerStyle = {
    fontSize: ifWidthLessthan1920 ? "11px" : "13px",
    opacity: "0.7",
    fontFamily: "Inter",
  };
  const infoStyle = {
    fontSize: ifWidthLessthan1920 ? "12px" : "18px",
    fontFamily: "Inter",
  };
  return (
    <Flex
      h="36vh"
      minH="300px"
      w="100%"
      bgColor="#fff"
      borderRadius="10px"
      p="1vw"
      direction="column"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
          fontWeight="600"
        >
          Case overview
        </Text>
        <Button
          bgColor="inherit"
          onClick={() => openModal()}
          _hover={{ color: "#1B75BC", cursor: "pointer" }}
          _active={{ color: "#1B75BC", cursor: "pointer" }}
        >
          <BiLinkExternal
            size={16}
            style={{ color: "#1B75BC", cursor: "pointer" }}
          />
        </Button>
      </HStack>

      <ScrollBar>
        <VStack
          w="100%"
          wordBreak="break-all"
          direction="column"
          justifyContent="flex-start"
          spacing={ifWidthLessthan1920 ? "8px" : "15px"}
          mt="10px"
        >
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Case Id</Text>
              <Text style={infoStyle}>{caseInfo?.caseId}</Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%" pl="30px">
              <Text style={headerStyle}>Organ</Text>
              <Text style={infoStyle}>{caseInfo?.organs?.[0].organName}</Text>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Department</Text>
              <Text style={infoStyle}>{caseInfo?.departmentFrom}</Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%" pl="30px">
              <Text style={headerStyle}>Hospital</Text>
              <Text style={infoStyle}>
                {caseInfo?.treatingHospitalDetails?.hospitalName}
              </Text>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Clinician</Text>
              <Text style={infoStyle}>{caseInfo?.treatingDoctor}</Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%" pl="30px">
              <Text style={headerStyle}>Specimen size</Text>
              <Text style={infoStyle}>{caseInfo?.organs?.[1].organSize}</Text>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="100%">
              <Text style={headerStyle}>Created</Text>
              <Text style={infoStyle}>
                {moment(caseInfo?.caseCreationDate).format(
                  " DD/MM/YYYY HH:mm A"
                )}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </ScrollBar>
      <CaseEditModal
        isOpen={isOpen}
        onClose={onClose}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        isEditing={isEditing}
        handleEditClick={handleEditClick}
        handleCancelClick={handleCancelClick}
        handleSaveClick={handleSaveClick}
        caseInfo={caseInfo}
        inputPatientData={inputPatientData}
        inputCaseData={inputCaseData}
        setInputCaseData={setInputCaseData}
        setInputPatientData={setInputPatientData}
        handlePatientInput={handlePatientInput}
        handleCaseInput={handleCaseInput}
      />
    </Flex>
  );
};

export default Overview;
