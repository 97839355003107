/* eslint-disable no-unsafe-optional-chaining */
import React from "react";
import moment from "moment";
import {
  Flex,
  HStack,
  Text,
  useMediaQuery,
  Image,
  Button,
} from "@chakra-ui/react";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { HiOutlineClipboardList } from "react-icons/hi";
import ScrollBar from "../other/Scrollbar";
import slideUploadIcon from "../../Static/Images/slideUploadIcon.svg";
import NewCaseTimelineIcon from "../../Static/Images/NewCaseTimelineIcon.svg";
import CaseAssignIcon from "../../Static/Images/CaseAssignIcon.svg";

const TimelineExtended = ({ caseInfo, setTabIndex }) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  return (
    <Flex
      h="70vh"
      w="100%"
      bgColor="#fff"
      borderRadius="10px"
      p="1vw"
      direction="column"
    >
      <ScrollBar>
        <Flex w="100%" h="100%" direction="column">
          {caseInfo?.timeLine?.map((data, index) => {
            return (
              <HStack
                w="100%"
                minH="75px"
                spacing="0"
                alignItems="flex-start"
                key={uuidv4()}
              >
                <Flex
                  w="5%"
                  h="100%"
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                  mt="25px"
                >
                  <MdOutlineRadioButtonChecked color="#1B75BC" size={27} />
                  <Flex
                    w="2px"
                    h="100%"
                    bgColor={
                      index < caseInfo?.timeLine?.length - 1
                        ? "#1B75BC"
                        : "#fff"
                    }
                  />
                </Flex>
                <Flex w="95%" h="100%">
                  <Flex
                    minW="100%"
                    minH="70px"
                    h="fit-content"
                    background="#F9F9F9"
                    boxShadow="0px 1px 2px rgba(176, 200, 214, 0.08)"
                    borderRadius="10px"
                    p="4px 10px"
                    alignItems="center"
                  >
                    {/* <HStack w="100%">
                      <HiOutlineClipboardList color="#1B75BC" size={40} />
                      <Flex direction="column" w="90%">
                        <HStack w="100%" justifyContent="space-between">
                          <Text
                            fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {data.message}
                          </Text>

                          <Flex alignItems="center">
                            <Text
                              color="#000"
                              opacity={0.7}
                              fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                            >
                              {moment(data.time).fromNow()}
                            </Text>
                          </Flex>
                        </HStack>
                      </Flex>
                    </HStack> */}
                    <HStack w="100%">
                      {data.message.includes("created by") ? (
                        <Image
                          src={NewCaseTimelineIcon}
                          transform="scale(1.5)"
                        />
                      ) : data.message.includes("assigned to") ? (
                        <Image src={CaseAssignIcon} transform="scale(1.5)" />
                      ) : data.message.includes("reported by") ? (
                        <HiOutlineClipboardList
                          color="#1B75BC"
                          size="20px"
                          style={{ transform: "scale(1.5)" }}
                        />
                      ) : data.message.includes("uploaded by") ? (
                        <Image src={slideUploadIcon} transform="scale(1.5)" />
                      ) : null}

                      {/* <HiOutlineClipboardList color="#1B75BC" size="40px" /> */}
                      <Flex direction="column" w="90%">
                        <HStack w="100%" justifyContent="space-between">
                          <Text
                            fontSize={ifWidthLessthan1920 ? "14px" : "16px"}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            w="70%"
                          >
                            <Flex
                              alignItems="flex-start"
                              direction="column"
                              gap={0}
                            >
                              <Flex pl="5px">{data.message}</Flex>
                              <Flex pl="5px">
                                {data.message.includes("reported by") ? (
                                  <Button
                                    h="20px"
                                    w={ifWidthLessthan1920 ? "70px" : "80.5px"}
                                    backgroundColor="#F9F9F9"
                                    _hover={{ background: "#F9F9F9" }}
                                    onClick={() => setTabIndex(4)}
                                  >
                                    <Text
                                      fontSize={
                                        ifWidthLessthan1920 ? "12px" : "14px"
                                      }
                                      color="#1B75BC"
                                      textDecoration="underline"
                                      textUnderlineOffset="4px"
                                    >
                                      View Report
                                    </Text>
                                  </Button>
                                ) : data.message.includes("uploaded by") ? (
                                  <Button
                                    h="20px"
                                    w={ifWidthLessthan1920 ? "68px" : "79px"}
                                    backgroundColor="#F9F9F9"
                                    _hover={{ background: "#F9F9F9" }}
                                    onClick={() => setTabIndex(1)}
                                  >
                                    <Text
                                      fontSize={
                                        ifWidthLessthan1920 ? "12px" : "14px"
                                      }
                                      color="#1B75BC"
                                      textDecoration="underline"
                                      textUnderlineOffset="4px"
                                    >
                                      View Slides
                                    </Text>
                                  </Button>
                                ) : data.message.includes("created by") ? (
                                  <Button
                                    h="20px"
                                    w={ifWidthLessthan1920 ? "62px" : "72.5px"}
                                    backgroundColor="#F9F9F9"
                                    _hover={{ background: "#F9F9F9" }}
                                    onClick={() => setTabIndex(0)}
                                  >
                                    <Text
                                      fontSize={
                                        ifWidthLessthan1920 ? "12px" : "14px"
                                      }
                                      color="#1B75BC"
                                      textDecoration="underline"
                                      textUnderlineOffset="4px"
                                    >
                                      View Case
                                    </Text>
                                  </Button>
                                ) : data.message.includes("assigned to") ? (
                                  <Button
                                    h="20px"
                                    w={ifWidthLessthan1920 ? "92px" : "108px"}
                                    backgroundColor="#F9F9F9"
                                    _hover={{ background: "#F9F9F9" }}
                                    onClick={() => setTabIndex(0)}
                                  >
                                    <Text
                                      fontSize={
                                        ifWidthLessthan1920 ? "12px" : "14px"
                                      }
                                      color="#1B75BC"
                                      textDecoration="underline"
                                      textUnderlineOffset="4px"
                                    >
                                      Edit Assignment
                                    </Text>
                                  </Button>
                                ) : null}
                              </Flex>
                            </Flex>
                          </Text>

                          <Flex
                            alignItems="flex-end"
                            w="30%"
                            pt="13px"
                            pl="230px"
                          >
                            <Text
                              color="#000"
                              opacity={0.7}
                              fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {moment(data.time).fromNow()}
                            </Text>
                          </Flex>
                        </HStack>
                      </Flex>
                    </HStack>
                  </Flex>
                </Flex>
              </HStack>
            );
          })}
        </Flex>
      </ScrollBar>
    </Flex>
  );
};

export default TimelineExtended;
