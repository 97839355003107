import {
  Flex,
  HStack,
  Input,
  Select,
  Switch,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaUserAlt } from "react-icons/fa";

const RoleConfiguration = ({ rolesData, setRolesData }) => {
  const [ifWidthLessthan1500] = useMediaQuery("(max-width:1500px)");
  const [caseInfo, setCaseInfo] = useState("edit");
  const [patientInfo, setPatientInfo] = useState("edit");
  const [radiologyImage, setRadiologyImage] = useState("edit");
  const [grossImage, setGrossImage] = useState("edit");
  const [report, setReport] = useState("submit");
  const handleInput = (e) => {
    setRolesData({ ...rolesData, [e.target.name]: e.target.value });
  };
  const handleSwitch = (e) => {
    setRolesData({ ...rolesData, [e.target.name]: e.target.checked });
  };
  return (
    <Flex mt="2vh" fontSize="14px">
      <Flex w="50%" direction="column">
        <Text fontSize="18px" mb="2vh">
          Details
        </Text>
        <VStack alignItems="start" spacing={1} mb="2vh">
          <Text fontSize="14px">Role Title*</Text>
          <Input
            w={ifWidthLessthan1500 ? "90%" : "80%"}
            placeholder="Enter text here"
            name="roleTitle"
            onChange={handleInput}
          />
        </VStack>
        <Text fontSize="18px" mb="1vh">
          Permissions
        </Text>
        <Flex
          borderRadius="5"
          border="1px solid #DEDEDE"
          minH="180px"
          w={ifWidthLessthan1500 ? "90%" : "80%"}
          p="10px 18px"
          direction="column"
          mb="2vh"
        >
          <Text>Cases</Text>
          <Flex
            ml="5%"
            direction="column"
            h="100%"
            justifyContent="space-between"
          >
            <HStack justifyContent="space-between" w="100%">
              <Text>Create</Text>
              <Switch
                name="caseCreate"
                onChange={handleSwitch}
                value={rolesData.caseCreate}
              />
            </HStack>
            <HStack justifyContent="space-between" w="100%">
              <Text>Edit</Text>
              <Switch name="caseEdit" onChange={handleSwitch} />
            </HStack>
            <HStack justifyContent="space-between" w="100%">
              <Text>Delete</Text>
              <Switch name="caseDelete" onChange={handleSwitch} />
            </HStack>
            <HStack justifyContent="space-between" w="100%">
              <Text>Share</Text>
              <Switch name="caseShare" onChange={handleSwitch} />
            </HStack>
            <HStack justifyContent="space-between" w="100%">
              <Text>Assign</Text>
              <Switch name="caseAssign" onChange={handleSwitch} />
            </HStack>
          </Flex>
        </Flex>
        <Flex
          borderRadius="5"
          border="1px solid #DEDEDE"
          minH="155px"
          w={ifWidthLessthan1500 ? "90%" : "80%"}
          p="10px 18px"
          direction="column"
          mb="2vh"
        >
          <Text>Slide</Text>
          <Flex
            ml="5%"
            direction="column"
            h="100%"
            justifyContent="space-between"
          >
            <HStack justifyContent="space-between" w="100%">
              <Text>Upload</Text>
              <Switch name="slideUpload" onChange={handleSwitch} />
            </HStack>
            <HStack justifyContent="space-between" w="100%">
              <Text>Edit</Text>
              <Switch name="slideEdit" onChange={handleSwitch} />
            </HStack>
            <HStack justifyContent="space-between" w="100%">
              <Text>Delete</Text>
              <Switch name="slideDelete" onChange={handleSwitch} />
            </HStack>
            <HStack justifyContent="space-between" w="100%">
              <Text>Share</Text>
              <Switch name="slideShare" onChange={handleSwitch} />
            </HStack>
          </Flex>
        </Flex>
        <Flex
          w={ifWidthLessthan1500 ? "90%" : "80%"}
          h="40px"
          borderRadius="5"
          border="1px solid #DEDEDE"
          alignItems="center"
          pl="18px"
          pr="18px"
          direction="row"
          justifyContent="space-between"
          mb="1vh"
        >
          <Text w="35%">Case Information</Text>
          <Select
            variant="unstyled"
            w="30%"
            onChange={(e) => setCaseInfo(e.target.value)}
          >
            <option value="edit">Can Edit</option>
            <option value="view">Can View</option>
            <option value="delete">Can Delete</option>
          </Select>
          {caseInfo === "edit" ? (
            <Switch
              name="caseInfoEdit"
              onChange={handleSwitch}
              isChecked={rolesData.caseInfoEdit}
            />
          ) : caseInfo === "view" ? (
            <Switch
              name="caseInfoView"
              onChange={handleSwitch}
              isChecked={rolesData.caseInfoView}
            />
          ) : caseInfo === "delete" ? (
            <Switch
              name="caseInfoDelete"
              onChange={handleSwitch}
              isChecked={rolesData.caseInfoDelete}
            />
          ) : null}
        </Flex>
        <Flex
          w={ifWidthLessthan1500 ? "90%" : "80%"}
          h="40px"
          borderRadius="5"
          border="1px solid #DEDEDE"
          alignItems="center"
          pl="18px"
          pr="18px"
          direction="row"
          justifyContent="space-between"
          mb="1vh"
        >
          <Text w="35%">Patient Information</Text>
          <Select
            variant="unstyled"
            w="30%"
            onChange={(e) => setPatientInfo(e.target.value)}
          >
            <option value="edit">Can Edit</option>
            <option value="view">Can View</option>
            <option value="delete">Can Delete</option>
          </Select>
          {patientInfo === "edit" ? (
            <Switch
              name="patientInfoEdit"
              onChange={handleSwitch}
              isChecked={rolesData.patientInfoEdit}
            />
          ) : patientInfo === "view" ? (
            <Switch
              name="patientInfoView"
              onChange={handleSwitch}
              isChecked={rolesData.patientInfoView}
            />
          ) : caseInfo === "delete" ? (
            <Switch
              name="patientInfoDelete"
              onChange={handleSwitch}
              isChecked={rolesData.patientInfoDelete}
            />
          ) : null}
        </Flex>
        <Flex
          w={ifWidthLessthan1500 ? "90%" : "80%"}
          h="40px"
          borderRadius="5"
          border="1px solid #DEDEDE"
          alignItems="center"
          pl="18px"
          pr="18px"
          direction="row"
          justifyContent="space-between"
          mb="1vh"
        >
          <Text w="35%">Radiology Image</Text>
          <Select
            variant="unstyled"
            w="30%"
            onChange={(e) => setRadiologyImage(e.target.value)}
          >
            <option value="edit">Can Edit</option>
            <option value="view">Can View</option>
            <option value="delete">Can Delete</option>
            <option value="add">Can Add</option>
          </Select>
          {radiologyImage === "edit" ? (
            <Switch
              name="radiologyImageEdit"
              onChange={handleSwitch}
              isChecked={rolesData.radiologyImageEdit}
            />
          ) : radiologyImage === "view" ? (
            <Switch
              name="radiologyImageView"
              onChange={handleSwitch}
              isChecked={rolesData.radiologyImageView}
            />
          ) : radiologyImage === "delete" ? (
            <Switch
              name="radiologyImageDelete"
              onChange={handleSwitch}
              isChecked={rolesData.radiologyImageDelete}
            />
          ) : radiologyImage === "add" ? (
            <Switch
              name="radiologyImageAdd"
              onChange={handleSwitch}
              isChecked={rolesData.radiologyImageAdd}
            />
          ) : null}
        </Flex>
        <Flex
          w={ifWidthLessthan1500 ? "90%" : "80%"}
          h="40px"
          borderRadius="5"
          border="1px solid #DEDEDE"
          alignItems="center"
          pl="18px"
          pr="18px"
          direction="row"
          justifyContent="space-between"
          mb="1vh"
        >
          <Text w="35%">Gross Image</Text>
          <Select
            variant="unstyled"
            w="30%"
            onChange={(e) => setGrossImage(e.target.value)}
          >
            <option value="edit">Can Edit</option>
            <option value="view">Can View</option>
            <option value="delete">Can Delete</option>
            <option value="add">Can Add</option>
          </Select>
          {grossImage === "edit" ? (
            <Switch
              name="grossImageEdit"
              onChange={handleSwitch}
              isChecked={rolesData.grossImageEdit}
            />
          ) : grossImage === "view" ? (
            <Switch
              name="grossImageView"
              onChange={handleSwitch}
              isChecked={rolesData.grossImageView}
            />
          ) : grossImage === "delete" ? (
            <Switch
              name="grossImageDelete"
              onChange={handleSwitch}
              isChecked={rolesData.grossImageDelete}
            />
          ) : grossImage === "add" ? (
            <Switch
              name="grossImageAdd"
              onChange={handleSwitch}
              isChecked={rolesData.grossImageAdd}
            />
          ) : null}
        </Flex>
      </Flex>
      <Flex w="50%" pl="10%" direction="column" pt="5%">
        {/* <Flex w="90%" h="150px" mb="2vh">
          <Flex
            w="160px"
            h="100%"
            border="1px solid #DEDEDE"
            borderRadius="5px"
            alignItems="center"
            justifyContent="center"
          >
            <FaUserAlt size={100} color="green" />
          </Flex>
        </Flex> */}
        <Flex
          w={ifWidthLessthan1500 ? "90%" : "80%"}
          h="40px"
          borderRadius="5"
          border="1px solid #DEDEDE"
          alignItems="center"
          pl="18px"
          pr="18px"
          direction="row"
          justifyContent="space-between"
          mb="1vh"
        >
          <Text w="30%">Report</Text>
          <Select
            variant="unstyled"
            w="40%"
            fontSize="14px"
            onChange={(e) => setReport(e.target.value)}
          >
            <option value="submit">Can Submit</option>
            <option value="view">Can View</option>
            <option value="download">Can Download</option>
          </Select>
          {report === "submit" ? (
            <Switch
              name="reportSubmit"
              onChange={handleSwitch}
              isChecked={rolesData.reportSubmit}
            />
          ) : report === "view" ? (
            <Switch
              name="reportView"
              onChange={handleSwitch}
              isChecked={rolesData.reportView}
            />
          ) : report === "download" ? (
            <Switch
              name="reportDownload"
              onChange={handleSwitch}
              isChecked={rolesData.reportDownload}
            />
          ) : null}
        </Flex>
        <Flex
          w={ifWidthLessthan1500 ? "90%" : "80%"}
          h="40px"
          borderRadius="5"
          border="1px solid #DEDEDE"
          alignItems="center"
          pl="18px"
          pr="18px"
          direction="row"
          justifyContent="space-between"
          mb="1vh"
        >
          <Text>Download Ability</Text>
          <Switch name="downloadAbility" onChange={handleSwitch} />
        </Flex>
        <Flex
          w={ifWidthLessthan1500 ? "90%" : "80%"}
          h="40px"
          borderRadius="5"
          border="1px solid #DEDEDE"
          alignItems="center"
          pl="18px"
          pr="18px"
          direction="row"
          justifyContent="space-between"
          mb="1vh"
        >
          <Text>Chat</Text>
          <Switch name="chat" onChange={handleSwitch} />
        </Flex>
        <Flex
          w={ifWidthLessthan1500 ? "90%" : "80%"}
          h="40px"
          borderRadius="5"
          border="1px solid #DEDEDE"
          alignItems="center"
          pl="18px"
          pr="18px"
          direction="row"
          justifyContent="space-between"
          mb="1vh"
        >
          <Text>Reopen Case</Text>
          <Switch name="reopenCase" onChange={handleSwitch} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RoleConfiguration;
