import React, { useState } from "react";
import {
  Flex,
  VStack,
  Text,
  Box,
  useMediaQuery,
  Image,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  useToast,
  Link,
  ModalFooter,
  Progress,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import { SlPencil } from "react-icons/sl";
import { GrClose } from "react-icons/gr";
import { AiOutlineCloudUpload } from "react-icons/ai";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useDeleteGrossOfCaseMutation,
  useUpdateGrossHistoryMutation,
  useGetUserInfoQuery,
} from "../state/API/HospitalApi";
import { isActionValid } from "../hooks/utility";
import Environment from "../environment";

const GrossImages = ({ caseInfo, permissions }) => {
  const [fileGrossBoolean, setFileGrossBoolean] = useState(false);
  // localStorage.setItem("tab", "3");
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const [is1280] = useMediaQuery("(width: 1280px)");
  const [click, setClick] = useState(false);
  const toast = useToast();
  const [isModal1Open, setIsModal1Open] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [timeStamp, setTimeStamp] = useState("");
  const { user } = useAuth0();
  const [textAreaDescription, setTextArea] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [allFiles1, setAllFiles1] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [deleteGrossOfCase] = useDeleteGrossOfCaseMutation();
  const [updateGrossHistory] = useUpdateGrossHistoryMutation();
  const { data: userInfo, isLoading } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user?.sub }
  );

  const onSelectFile = async (e) => {
    const { files } = e.target;
    // setFileGrossBoolean(true);
    const filesArray = Array.from(files);
    if (filesArray.length > 0) {
      setFileGrossBoolean(true);
    }
    setAllFiles(filesArray);
  };
  const onDeselectFile = async (e, value) => {
    const updatedFiles = allFiles.filter((file) => file.name !== value);
    if (updatedFiles.length === 0) {
      setFileGrossBoolean(false);
    }
    setAllFiles(updatedFiles);
  };
  const setModalIdFunction = async (id) => {
    const updatedFiles = caseInfo?.gross?.grossImageURLs
      ?.filter((obj) => obj.isDeleted === false)
      .filter((object) => object._id === id);
    setAllFiles1(updatedFiles);
    const dateObj = new Date(updatedFiles[0].uploadedAt);
    const options = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObj
    );

    const parts = formattedDate.split(" ");
    const time = `${dateObj.getHours()}:${dateObj.getMinutes()}`;
    const day = parts[1].replace(",", "");
    const month = parts[0];

    const formattedDateString = `${time} ${day} ${month} ${dateObj.getFullYear()}`;
    setTimeStamp(formattedDateString);
  };
  const handelDeleteFile = async (id) => {
    try {
      await deleteGrossOfCase({
        caseId: caseInfo._id,
        docId: id,
        subClaim: user?.sub,
      }).unwrap();

      toast({
        title: "Status",
        description: "Document removed",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Registration failed, Try again: ", err);
      toast({
        title: "Error",
        description: "Could not delete image. Try again later.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    setIsModal2Open(false);
  };
  const handleUpdateGrossHistory = async () => {
    setClick(true);
    const form = new FormData();
    allFiles.map((file) => form.append("files", file));
    try {
      const grossHistoryUrls = await axios.post(
        `${Environment.USER_URL}/upload_file_to_gross`,
        form,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );
      const dataarray = [];
      grossHistoryUrls.data.urls.forEach((element, i) => {
        const obj = {
          docUrl: element,
          docDescription: textAreaDescription,
          uploadedBy: `${userInfo.firstName} ${userInfo.lastName}`,
          docName: allFiles[i]?.name,
          docType:
            allFiles[i]?.name.slice(-3) === "peg"
              ? "jpeg"
              : allFiles[i]?.name.slice(-3),
          docSize: allFiles[i]?.size,
        };
        if (obj.docName !== undefined) {
          dataarray.push(obj);
        }
      });

      await updateGrossHistory({
        caseId: caseInfo._id,
        docs: dataarray,
        subClaim: user?.sub,
      });

      toast({
        title: "Success",
        description: `Gross Images updated`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch {
      toast({
        title: "Error",
        description: `Some error occurred.`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    setAllFiles([]);
    setUploadProgress(0);
    setIsModal1Open(false);
    setTextArea("");
    setClick(false);
  };

  return (
    <Box bgColor="light.500">
      <HStack w="100%" justifyContent="flex-end">
        <Button
          mt="10px"
          mr="26px"
          bgColor="#FFFFFF"
          _focus={{ outline: "none" }}
          onClick={() => setIsModal1Open(true)}
          border="1px solid #E8E8EB"
          borderRadius="5px"
          fontWeight={500}
          fontSize={ifSmallScreen ? "13px" : "0.713vw"}
          disabled={isActionValid("grossImageAdd", permissions)}
        >
          <HStack px="15px" py="11px">
            <Box pr="10px">
              <AiOutlineCloudUpload size={22} />
            </Box>
            <Text>Upload new image</Text>
          </HStack>
        </Button>
      </HStack>
      {!isActionValid("grossImageView", permissions) ? (
        <Box
          w="100%"
          h="100%"
          minH="72vh"
          py="1vw"
          fontSize={ifSmallScreen ? "14px" : " 0.72916vw"}
          bgColor="light.500"
          // w="100%"
          display="flex"
          justifyContent="space-between"
          overflow="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
            },
          }}
        >
          <VStack>
            <Flex
              maxW="100%"
              flexWrap="wrap"
              gap={is1280 ? "40px" : "25px"}
              pl="1.6vw"
              pr="0.90vw"
            >
              {caseInfo?.gross?.grossImageURLs
                ?.filter((obj) => obj.isDeleted === false)
                .map((object) => {
                  return (
                    <Flex
                      key={Math.random()}
                      border="1px solid #E8E8EB"
                      borderRadius="10px"
                      minW="280px"
                      maxW="280px"
                      minH="450px"
                      maxH="450px"
                      background="light.100"
                      onClick={() => {
                        setModalIdFunction(object._id);
                        setIsModal2Open(true);
                      }}
                      overflow="hidden"
                      css={{ cursor: "-webkit-grab" }}
                    >
                      <Flex
                        pt="17px"
                        pb="17px"
                        pl="20px"
                        pr="20px"
                        direction="column"
                      >
                        <Flex p="15px" direction="column">
                          {object?.docUrl.slice(-3) === "png" ||
                          object?.docUrl.slice(-3) === "jpg" ||
                          object?.docUrl.slice(-4) === "jpeg" ? (
                            <Box width="200px" height="200px">
                              <Image
                                src={object.docUrl}
                                alt="Image description"
                                width="100%"
                                height="100%"
                                objectFit="contain"
                                css={{ cursor: "-webkit-grab" }}
                              />
                            </Box>
                          ) : object?.docUrl.slice(-3) === "pdf" ? (
                            <Flex direction="column" minH="250px">
                              <object
                                data={object.docUrl}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                style={{
                                  overflow: "hidden",
                                  cursor: "-webkit-grab",
                                }}
                              >
                                <p>
                                  Alternative text - include a link{" "}
                                  <a href={object.docUrl}>to the PDF!</a>
                                </p>
                              </object>
                            </Flex>
                          ) : (
                            <iframe
                              title={object.docUrl}
                              src={`https://view.officeapps.live.com/op/embed.aspx?src=${object.docUrl}&embedded=true`}
                              frameBorder="0"
                            />
                          )}
                        </Flex>
                        {/* <Link href={object                    <Box w="100%" h="100%" wordBreak="break-word"></Box>?.docUrl} isExternal> */}
                        <Flex
                          alignItems="center"
                          fontSize={ifSmallScreen ? "14px" : "0.729vw"}
                          fontWeight={600}
                          pt="12px"
                        >
                          {" "}
                          <Box w="100%" wordBreak="break-word">
                            <Text color="#000000">{object?.docName}</Text>
                            {/* <HiExternalLink
                    style={{
                      color: "#3B5D7C",
                    }}
                  /> */}
                          </Box>
                        </Flex>
                        {/* </Link> */}
                        <Flex direction="column">
                          <Flex
                            fontSize="13px"
                            color="#6A6A6A"
                            fontWeight={500}
                            pt="16px"
                            pb="10px"
                          >
                            <Text>Description</Text>
                          </Flex>
                          <Flex
                            fontSize="13px"
                            color="#000000"
                            fontWeight={500}
                          >
                            <Text
                              w="100%"
                              wordBreak="break-word"
                              // overflow="hidden"
                              textOverflow="ellipsis"
                              css={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                boxOrient: "vertical",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 4,
                              }}
                            >
                              {object?.docDescription
                                ? object?.docDescription
                                : " No Description"}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  );
                })}
            </Flex>
          </VStack>

          {/* modal start 1 */}
          <Modal
            blockScrollOnMount={false}
            isOpen={isModal1Open}
            onClose={() => setIsModal1Open(false)}
            height={ifSmallScreen ? "500px" : "635px"}
            width={ifSmallScreen ? "500px" : "716px"}
            size="xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                fontSize={ifSmallScreen ? "13px" : "15.5px"}
                font-weight="500"
                pl={ifSmallScreen ? "20px" : "27px"}
                pb={ifSmallScreen ? "10px" : "15px"}
                pt={ifSmallScreen ? "10px" : "19px"}
                bg="#F0F2FF"
                borderStyle="solid"
                borderWidth="0px 0px 2px 0px"
                borderColor="#DEDEDE"
              >
                Upload Gross Image
              </ModalHeader>
              <ModalCloseButton
                pb={ifSmallScreen ? "20px" : "15px"}
                pt={ifSmallScreen ? "10px" : "21px"}
                mr={ifSmallScreen ? "10px" : "10.5"}
                _hover={{ opacity: 1 }}
              />
              <ModalBody bg="#FCFCFC">
                <Flex>
                  <HStack
                    mt={ifSmallScreen ? "5px" : "20px"}
                    ml={ifSmallScreen ? "1px" : "2px"}
                    justifyContent="space-between"
                  >
                    <Flex
                      width={fileGrossBoolean === false ? "529px" : "240px"}
                      height={ifSmallScreen ? "245px" : "272px"}
                      border="1px dashed #1B75BC"
                      borderRadius="5px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Input
                        id="clinical_history"
                        name="clinicalHistory"
                        position="absolute"
                        opacity="0"
                        onInput={(e) => onSelectFile(e)}
                        type="file"
                        accept="image/*"
                        width={ifSmallScreen ? "200px" : "240px"}
                        height={ifSmallScreen ? "245px" : "272px"}
                        multiple
                      />
                      <Box
                        fontSize="12px"
                        color="#000000"
                        fontWeight="300"
                        px="15px"
                        py="121px"
                        textAlign="center"
                      >
                        Drag & drop your files here or{" "}
                        <Link href="/" color="#1B75BC">
                          browse
                        </Link>{" "}
                        to upload.
                      </Box>
                    </Flex>
                    <Flex
                      display={fileGrossBoolean === false ? "none" : "flex"}
                      width="285px"
                      // height="272px"
                      height={ifSmallScreen ? "245px" : "272px"}
                      borderRadius="5px"
                      bg="#FFFFFF"
                      direction="column"
                      overflow="auto"
                      css={{
                        "&::-webkit-scrollbar": {
                          width: "4px",
                        },
                        "&::-webkit-scrollbar-track": {
                          width: "6px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#C4C4C4",
                        },
                      }}
                    >
                      <Flex pl="13px" pt="8px">
                        <Text
                          color="#000000"
                          fontSize={ifSmallScreen ? "13px" : "15.5px"}
                          fontWeight="500"
                          width="100%"
                        >
                          Uploaded form
                        </Text>
                      </Flex>
                      {allFiles?.map((file) => {
                        return allFiles?.length > 0 ? (
                          <Flex
                            mr="10px"
                            ml="10px"
                            mt="14px"
                            key={Math.random()}
                            bg="#1B75BC0A"
                          >
                            <HStack
                              // bg="#1B75BC0A"
                              h="58px"
                              w="270px"
                              borderRadius="6px"
                            >
                              <Flex
                                h="38px"
                                w="38px"
                                bg="#1B75BC"
                                fontSize="12px"
                                color="#FFFFFF"
                                alignItems="center"
                                justifyContent="center"
                                ml="6px"
                              >
                                {file.name.slice(-3) === "peg"
                                  ? "jpeg"
                                  : file.name.slice(-3)}
                              </Flex>
                              <Flex direction="column" gap="2px" pl="5px">
                                <Text
                                  fontSize={ifSmallScreen ? "10px" : "12.5px"}
                                  fontWeight="400"
                                  pb="4px"
                                >
                                  {file?.name}
                                </Text>
                                {uploadProgress ? (
                                  <Progress
                                    colorScheme="blue"
                                    height="3px"
                                    width="135px"
                                    value={uploadProgress}
                                  />
                                ) : (
                                  " "
                                )}
                              </Flex>
                              {/* <Box pb="6px" pl="3px" pr="3px">
                            <MdModeEdit size={15.5} />
                          </Box> */}
                            </HStack>
                            <Box
                              as="button"
                              pb="4px"
                              pl="40px"
                              pr="5px"
                              onClick={(e) => {
                                onDeselectFile(e, file?.name);
                              }}
                            >
                              <GrClose size={13.5} />
                            </Box>
                          </Flex>
                        ) : null;
                      })}
                    </Flex>
                  </HStack>
                </Flex>
                <Flex
                  mt={ifSmallScreen ? "10px" : "32px"}
                  ml={ifSmallScreen ? "1px" : "2px"}
                  direction="column"
                >
                  <Text
                    fontSize={ifSmallScreen ? "13px" : "15.5px"}
                    fontWeight="500"
                    color="#000000"
                    pb={ifSmallScreen ? "10px" : "15px"}
                  >
                    Description
                  </Text>
                  <Flex border="1px solid #1B75BC" borderRadius="3px">
                    <Textarea
                      resize="none"
                      height={ifSmallScreen ? "80px" : "119px"}
                      width="530px"
                      fontSize="15.5px"
                      fontWeight="400"
                      borderRadius="3px"
                      id="gross_history_details"
                      name="grossHistoryDetails"
                      value={textAreaDescription}
                      p="8px"
                      placeholder="Enter text here"
                      minLength="150"
                      maxLength="450"
                      // onChange={(e) => handleCaseDetails(e)}
                      onChange={(e) => setTextArea(e.target.value)}
                    />
                  </Flex>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setIsModal1Open(false);
                  }}
                  colorScheme="#1B75BC"
                  mr={ifSmallScreen ? "6" : "10"}
                  color="#010202"
                  // fontSize={ifSmallScreen ? "10px" : "14px"}
                  fontWeight="400"
                  border="1px solid #1B75BC"
                  borderRadius="5px"
                  // height={ifSmallScreen ? "25px" : "36px"}
                  height={ifSmallScreen ? "25px" : "36px"}
                  fontSize={ifSmallScreen ? "13px" : "14px"}
                  width="81px"
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="#1B75BC"
                  bg="#1B75BC"
                  color="#E6EBF3"
                  // fontSize={ifSmallScreen ? "10px" : "14px"}
                  fontWeight="400"
                  borderRadius="5px"
                  // height={ifSmallScreen ? "25px" : "36px"}
                  width="81px"
                  _hover={{ opacity: 1 }}
                  onClick={handleUpdateGrossHistory}
                  isDisabled={click}
                  height={ifSmallScreen ? "25px" : "36px"}
                  fontSize={ifSmallScreen ? "13px" : "14px"}
                >
                  Save
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/* modal end  1 */}

          {/* modal start 3 */}
          <Modal
            blockScrollOnMount={false}
            isOpen={isModal2Open}
            onClose={() => setIsModal2Open(false)}
          >
            <ModalOverlay />
            {/* <ModalContent maxH="650px" maxW="1100px" minH="650px" minW="1100px"> */}
            <ModalContent
              maxH={ifSmallScreen ? "480px" : "650px"}
              maxW={ifSmallScreen ? "1000px" : "1100px"}
              minH={ifSmallScreen ? "480px" : "650px"}
              minW={ifSmallScreen ? "1000px" : "1100px"}
            >
              <ModalHeader>
                <Flex justifyContent="flex-end" pr="32px">
                  <Button
                    as="button"
                    height="33px"
                    width="166px"
                    border="1px solid #E8E8EB"
                    color="red"
                    fontSize="13px"
                    borderRadius="5px"
                    onClick={() => {
                      handelDeleteFile(allFiles1[0]?._id);
                    }}
                    disabled={isActionValid("grossDelete", permissions)}
                  >
                    <Flex alignItems="center" justifyContent="center">
                      Delete Document{" "}
                      <Box>
                        <FiTrash2 />
                      </Box>
                    </Flex>
                  </Button>

                  <ModalCloseButton
                    _hover={{ opacity: 1 }}
                    mt="9px"
                    border="1px solid #E8E8EB"
                  />
                </Flex>
              </ModalHeader>
              <ModalBody>
                <Flex>
                  {allFiles1[0]?.docUrl.slice(-3) === "png" ||
                  allFiles1[0]?.docUrl.slice(-3) === "jpg" ||
                  allFiles1[0]?.docUrl.slice(-4) === "jpeg" ? (
                    // <Flex pb="17px" pl="20px" pr="20px" direction="column">
                    //   <Image
                    //     src={allFiles1[0]?.docUrl}
                    //     alt=""
                    //     width="630px"
                    //     height="550px"
                    //   />
                    // </Flex>
                    <Flex pb="17px" pl="20px" pr="20px" direction="column">
                      {/* <Flex width="630px" height="550px"> */}
                      {/* <Flex
                      width={ifSmallScreen ? "350px" : "630px"}
                      height={ifSmallScreen ? "350px" : "550px"}
                    >
                      <Image
                        src={allFiles1[0]?.docUrl}
                        alt=""
                        css={{ cursor: "-webkit-grab" }}
                      />
                    </Flex> */}
                      <Box
                        width={ifSmallScreen ? "350px" : "630px"}
                        height={ifSmallScreen ? "350px" : "550px"}
                      >
                        <Image
                          src={allFiles1[0]?.docUrl}
                          alt="Image description"
                          width="100%"
                          height="100%"
                          objectFit="contain"
                          css={{ cursor: "-webkit-grab" }}
                        />
                      </Box>
                    </Flex>
                  ) : allFiles1[0]?.docUrl.slice(-3) === "pdf" ? (
                    <Flex pb="17px" pl="20px" pr="20px">
                      <object
                        data={allFiles1[0]?.docUrl}
                        type="application/pdf"
                        // width="670"
                        // height="550"
                        width="630px"
                        height="550px"
                        style={{
                          overflow: "hidden",
                        }}
                      >
                        <p>
                          Alternative text - include a link{" "}
                          <a href={allFiles1[0]?.docUrl}>to the PDF!</a>
                        </p>
                      </object>
                    </Flex>
                  ) : (
                    <Flex
                      direction="column"
                      pt="17px"
                      pb="17px"
                      pl="20px"
                      pr="20px"
                    >
                      <iframe
                        title={allFiles1[0]?.docUrl}
                        // src={`https://docsUrl.google.com/gview?url=${object.docurl}&embedded=true`}
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${allFiles1[0]?.docUrl}&embedded=true`}
                        frameBorder="0"
                        width="630px"
                        height="550px"
                        style={{
                          overflow: "hidden",
                        }}
                      />
                    </Flex>
                  )}
                  <Flex direction="column" gap="20px">
                    <Box w="60%" wordBreak="break-word">
                      <Text
                        fontSize={ifSmallScreen ? "16px" : "24px"}
                        fontWeight="600"
                        color="#000000"
                      >
                        {allFiles1[0]?.docName}
                      </Text>
                    </Box>
                    <Flex gap="25px">
                      <Flex direction="column">
                        <Text
                          fontSize={ifSmallScreen ? "13px" : "15px"}
                          fontWeight="500"
                          color="#6A6A6A"
                          pb="3px"
                        >
                          Uploaded On
                        </Text>
                        <Text
                          fontSize={ifSmallScreen ? "12px" : "16px"}
                          fontWeight="500"
                          color="#000000"
                        >
                          {timeStamp}
                        </Text>
                      </Flex>
                      <Flex direction="column" pl="35px">
                        <Text
                          fontSize={ifSmallScreen ? "13px" : "15px"}
                          fontWeight="500"
                          color="#6A6A6A"
                          pb="3px"
                        >
                          Uploaded By
                        </Text>
                        <Text
                          fontSize={ifSmallScreen ? "12px" : "16px"}
                          fontWeight="500"
                          color="#000000"
                        >
                          {allFiles1[0]?.uploadedBy}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex>
                      <Flex direction="column">
                        <Text
                          fontSize={ifSmallScreen ? "13px" : "15px"}
                          fontWeight="500"
                          color="#6A6A6A"
                          pb="3px"
                        >
                          Size
                        </Text>
                        <Text
                          fontSize={ifSmallScreen ? "12px" : "16px"}
                          fontWeight="500"
                          color="#000000"
                          pb="3px"
                        >
                          {(Number(allFiles1[0]?.docSize) / 1024).toFixed(2)}
                          {" MB"}
                        </Text>
                      </Flex>
                      <Flex direction="column" pl="112px">
                        <Text
                          fontSize={ifSmallScreen ? "13px" : "15px"}
                          fontWeight="500"
                          color="#6A6A6A"
                          pb="3px"
                        >
                          Type
                        </Text>
                        <Text
                          fontSize={ifSmallScreen ? "12px" : "16px"}
                          fontWeight="500"
                          color="#000000"
                        >
                          {allFiles1[0]?.docType?.toUpperCase()}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap="20px">
                      <Flex direction="column">
                        <Flex
                          fontSize={ifSmallScreen ? "13px" : "15px"}
                          fontWeight="500"
                          color="#6A6A6A"
                          pb="3px"
                        >
                          <Box mr="3px">
                            <Text>Description </Text>
                          </Box>
                          <Flex mt="3px" ml="5px">
                            <SlPencil size="13px" />
                          </Flex>
                        </Flex>
                        <Box w="100%" wordBreak="break-word">
                          <Text
                            fontSize={ifSmallScreen ? "12px" : "16px"}
                            fontWeight="500"
                            color="#000000"
                          >
                            {allFiles1[0]?.docDescription}
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
          {/* modal end  3 */}
        </Box>
      ) : (
        <Flex
          w="100%"
          minH="70vh"
          justifyContent="center"
          alignItems="center"
          fontSize="16px"
        >
          You are not allowed to view gross images
        </Flex>
      )}
    </Box>
  );
};

export default GrossImages;
