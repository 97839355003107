export default {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
    borderRadius: "5px",
  },

  "&multiLine": {
    width: "95%",
    control: {
      fontFamily: "monospace",
      minHeight: 63,
      borderRadius: "5px",
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
      borderRadius: "5px",
      paddingLeft: "10px",
    },
    input: {
      padding: 9,
      border: "1px solid #96A9BA",
      borderRadius: "5px",
      paddingLeft: "10px",
    },
  },

  "&singleLine": {
    // display: "inline-block",
    width: "92%",

    highlighter: {
      padding: "6px",
      width: "110%",
      height: "100%",
      border: "2px inset transparent",
      borderRadius: "5px",
      paddingLeft: "10px",
    },
    input: {
      padding: 1,
      border: "1px solid #96A9BA",
      height: "100%",
      borderRadius: "5px",
      paddingLeft: "10px",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};
