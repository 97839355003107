/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useMediaQuery,
  Image,
  Button,
  Flex,
  useDisclosure,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToast,
  Box,
} from "@chakra-ui/react";
import moment from "moment";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { usePagination, useSortBy, useTable, useFilters } from "react-table";
import { v4 as uuidv4 } from "uuid";
import {
  AiOutlineCheckCircle,
  AiFillCaretDown,
  AiFillCaretUp,
} from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
import { TbUrgent } from "react-icons/tb";
import { MdPendingActions } from "react-icons/md";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FiAlertTriangle, FiMoreVertical } from "react-icons/fi";
import {
  useGetCaseInfoQuery,
  useGetUserInfoQuery,
} from "../../state/API/HospitalApi";
import Environment from "../../environment";
import NewCaseIcon from "../../Static/Images/NewIcon.png";
import RangeFilterMenu from "./RangeFilterMenu";
import SearchFilterMenu from "./SearchFilterMenu";
import DateFilterMenu from "./DateFilterMenu";
import FilterMenu from "./FilterMenu";
import CaseEditModal from "./CaseEditModal";
import CaseDeleteModal from "./CaseDeleteModal";

const FilterButton = ({
  handleFilterIconClick,
  showFilterMenu,
  children,
  column,
  setPageSize,
  data,
  setFilterChecked,
  isFilterChecked,
  hideScrollbar,
  setHideScrollBar,
  selected,
}) => {
  const filterButtonRef = useRef();
  const handleFilterButtonClick = () => {
    handleFilterIconClick(column.Header?.toLowerCase(), "");
    // if (!isFilterChecked) {
    //   setPageSize(data.length);
    //   setFilterChecked(true);
    // }
    setHideScrollBar(true);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ position: "relative", marginLeft: "auto" }}>
        {/* <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<BiFilterAlt size="16" />}
          />
          <MenuList minH="48px" minW="48px">
            {children}
          </MenuList>
        </Menu> */}
        <button
          type="button"
          ref={filterButtonRef}
          onClick={(e) => {
            e.stopPropagation();
            handleFilterButtonClick();
          }}
          style={{
            maximumWidth: "10px",
            height: "20px",
            paddingLeft: "3px",
            width: "8px",
          }}
        >
          <BiFilterAlt size="16" color={selected ? "#1B75BC" : "gray"} />
        </button>
        {showFilterMenu && (
          <div
            style={{
              position: "absolute",
              zIndex: 1000,
              right: "-35px",
              // backgroundColor: "#fff",
              // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              // border: "1px solid #ccc",
              // width: "50px",
              // height: "50px",
              // display: "flex",
              // flexDirection: "column",
            }}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

const Table = ({
  columns,
  data,
  isFilterChecked,
  setFilterChecked,
  filterOptions,
  setFilterOptions,
  selectedOrgan,
  setSelectedOrgan,
  selectedDepartment,
  setSelectedDepartment,
  selectedClinician,
  setSelectedClinician,
  selectedHospital,
  setSelectedHospital,
  showOrganFilterMenu,
  setShowOrganFilterMenu,
  showDepartmentFilterMenu,
  setShowDepartmentFilterMenu,
  showClinicianFilterMenu,
  setShowClinicianFilterMenu,
  showHospitalFilterMenu,
  setShowHospitalFilterMenu,
  showPatienNameFilterMenu,
  setShowPatientNameFilterMenu,
  showPatientUHIDFilterMenu,
  setShowPatientUHIDFilterMenu,
  showCaseIdFilterMenu,
  setShowCaseIdFilterMenu,
  searchTerm,
  setSearchTerm,
  showDateFilterMenu,
  setShowDateFilterMenu,
  selectedDateFilter,
  setSelectedDateFilter,
  searchDate,
  setSearchDate,
  searchPatientName,
  setSearchPatientName,
  searchPatientUHID,
  setSearchPatientUHID,
  searchCaseID,
  setSearchCaseID,
  selectedSlideRange,
  setSelectedSlideRange,
  showSlideFilterMenu,
  setShowSlideFilterMenu,
  filterData,
  permissions,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user?.sub }
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const toast = useToast();
  const [inputPatientData, setInputPatientData] = useState({
    patientName: "",
    gender: "",
    dob: "",
    address: "",
    UHID: "",
    contactNo: "",
  });
  const [inputCaseData, setInputCaseData] = useState({
    // caseId: "",
    organ: "",
    department: "",
    hospital: "",
    clinician: "",
    specimenSize: "",
  });
  const { data: caseInfo, isLoading } = useGetCaseInfoQuery({
    caseId,
    subClaim: user?.sub,
  });
  const handleTabChange = (index) => {
    setActiveTab(index);
  };
  const openModalWithCase = (selectedCase) => {
    setCaseId(selectedCase);
    setActiveTab(0); // Set active tab to Case Details
    setIsEditing(false); // Reset editing mode when opening modal
    // setCaseNumber(selectedCase.caseNumber);
    // setCaseDescription(selectedCase.caseDescription);
    // setPatientName("");
    // setPatientAge("");
    // setPatientDiagnosis("");
    onOpen();
  };
  const openModaDelete = (selectedCase) => {
    setSelectedId(selectedCase);
    setIsModalOpen(true);
  };
  const handleEditClick = () => {
    setInputCaseData({
      // caseId: caseInfo?.caseId,
      organ: caseInfo?.organs[0].organName,
      department: caseInfo?.departmentFrom,
      hospital: caseInfo?.treatingHospitalDetails.hospitalName,
      clinician: caseInfo?.treatingDoctor,
      specimenSize: caseInfo?.organs[1].organSize,
    });
    setInputPatientData({
      patientName: `${caseInfo?.patient?.patientName?.firstName} ${caseInfo?.patient?.patientName?.lastName}`,
      gender: caseInfo?.patient?.gender,
      dob: `${caseInfo?.patient?.dateOfBirth}`,
      contactNo: caseInfo?.patient?.contactNumber
        ? caseInfo?.patient?.contactNumber
        : "NA",
      address: caseInfo?.patient?.patientAddress
        ? caseInfo?.patient?.patientAddress
        : "NA",
      UHID: caseInfo?.patient?.uhid,
    });

    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    onClose();
    setIsEditing(false);

    const resp = await axios.put(
      `${Environment.USER_URL}/edit_patient_info?_id=${caseInfo?.patient._id}`,
      {
        patientName: { firstName: inputPatientData.patientName, lastName: "" },
        gender: inputPatientData.gender,
        dateOfBirth: inputPatientData.dob,
        contactNumber: inputPatientData.contactNo,
        patientAddress: inputPatientData.address,
        uhid: inputPatientData.UHID,
      }
      // {
      //   patientName: { firstName: "test", lastName: "" },
      //   gender: "male",
      //   dateOfBirth: "1955-06-22",
      //   contactNumber: "+1 (338) 499-8399",
      //   patientAddress:
      //     "aksjdfajsdfj asdjf lkajsdflkjsadlkjfkl ajsdlfk jaslkdjf",
      //   uhid: "38474839384848",
      // }
    );

    const caseResp = await axios.put(
      `${Environment.USER_URL}/edit_case_info?_id=${caseInfo?._id}`,
      {
        organs: [
          { organName: inputCaseData.organ },
          { organSize: inputCaseData.specimenSize },
        ],
        treatingHospitalDetails: { hospitalName: inputCaseData.hospital },
        treatingDoctor: inputCaseData.clinician,
        departmentFrom: inputCaseData.department,
        subClaim: user?.sub,
      }
    );
    if (resp.status === 200 || caseResp === 200) {
      onClose();
      toast({
        title: "Case details updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(function () {
        window.location.replace(`/dashboard/${userInfo?.userType}`);
        // window.location.reload();
      }, 1000);
    } else {
      toast({
        title: "Some error occured",
        description: "Please try again after some time",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    }
  };

  const handleCaseInput = (e) => {
    setInputCaseData({ ...inputCaseData, [e.target.name]: e.target.value });
  };
  const handlePatientInput = (e) => {
    setInputPatientData({
      ...inputPatientData,
      [e.target.name]: e.target.value,
    });
  };
  const firstFieldRef = useRef(null);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },

    useFilters,
    useSortBy,
    usePagination
  );

  const [searchState, setSearchState] = useState({
    caseNumber: "",
    date: "",
    organ: "",
    department: "",
    patientName: "",
    uhid: "",
    clinician: "",
    slides: "",
    hospitalName: "",
  });
  const [hideScrollbar, setHideScrollBar] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([]);

  const handleFilterClick = (filter) => {
    setSelectedFilter([...selectedFilter, filter]);
  };

  const handleClearFilter = (filter) => {
    setSelectedFilter(selectedFilter.filter((f) => f !== filter));
  };

  const handleDateFilterChange = (value) => {
    setSelectedDateFilter(value);
  };

  useEffect(() => {
    setFilter("caseNumber", searchState.caseNumber);
    setFilter("date", searchState.date);
    setFilter("organ", searchState.organ);
    setFilter("department", searchState.department);
    setFilter("patientName", searchState.patientName);
    setFilter("uhid", searchState.uhid);
    setFilter("clinician", searchState.clinician);
    setFilter("slides", searchState.slides);
    setFilter("hospitalName", searchState.hospitalName);
  }, [searchState]);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };
  // const handleDateSearchChange = (event) => {
  //   const { value } = event.target;
  //   setSearchDate(value);
  // };

  const handleFilterChange = (column, event) => {
    const { name, checked } = event.target;

    if (name === "All") {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        [column]: checked ? ["All"] : [],
      }));
      // setFilterChecked(false);
      // setPageSize(10);
    } else {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        [column]: checked
          ? prevOptions[column].includes("All")
            ? [name]
            : [...prevOptions[column], name]
          : prevOptions[column].filter((option) => option !== name),
      }));
      // setFilterChecked(true);
      // setPageSize([data.length]);
    }
  };
  const handleFilterIconClick = (column) => {
    if (column === "Organ") {
      setSelectedOrgan("");
      setShowOrganFilterMenu(!showOrganFilterMenu);
      setShowDepartmentFilterMenu(false);
      setShowClinicianFilterMenu(false);
      setShowHospitalFilterMenu(false);
      setShowDateFilterMenu(false);
      setShowPatientNameFilterMenu(false);
      setShowPatientUHIDFilterMenu(false);
      setShowCaseIdFilterMenu(false);
      setShowSlideFilterMenu(false);
    }
    if (column === "Department") {
      setSelectedDepartment("");
      setShowDepartmentFilterMenu(!showDepartmentFilterMenu);
      setShowOrganFilterMenu(false);
      setShowClinicianFilterMenu(false);
      setShowHospitalFilterMenu(false);
      setShowDateFilterMenu(false); // Add this line
      setShowPatientNameFilterMenu(false);
      setShowPatientUHIDFilterMenu(false);
      setShowCaseIdFilterMenu(false);
      setShowSlideFilterMenu(false);
    }
    if (column === "Clinician") {
      setSelectedClinician("");
      setShowClinicianFilterMenu(!showClinicianFilterMenu);
      setShowOrganFilterMenu(false);
      setShowDepartmentFilterMenu(false);
      setShowHospitalFilterMenu(false);
      setShowDateFilterMenu(false);
      setShowPatientNameFilterMenu(false);
      setShowPatientUHIDFilterMenu(false);
      setShowCaseIdFilterMenu(false);
      setShowSlideFilterMenu(false);
    }
    if (column === "Hospital") {
      setSelectedHospital("");
      setShowHospitalFilterMenu(!showHospitalFilterMenu);
      setShowOrganFilterMenu(false);
      setShowDepartmentFilterMenu(false);
      setShowClinicianFilterMenu(false);
      setShowDateFilterMenu(false); // Add this line
      setShowPatientNameFilterMenu(false);
      setShowPatientUHIDFilterMenu(false);
      setShowCaseIdFilterMenu(false);
      setShowSlideFilterMenu(false);
    }
    if (column === "Date Created") {
      // Add this block
      // setSelectedDateFilter(null);
      setShowDateFilterMenu(!showDateFilterMenu);
      setShowOrganFilterMenu(false);
      setShowDepartmentFilterMenu(false);
      setShowClinicianFilterMenu(false);
      setShowHospitalFilterMenu(false);
      setShowPatientNameFilterMenu(false);
      setShowPatientUHIDFilterMenu(false);
      setShowCaseIdFilterMenu(false);
      setShowSlideFilterMenu(false);
    }
    if (column === "Case ID") {
      // Add this block
      // setSelectedDateFilter(null);
      setShowDateFilterMenu(false);
      setShowOrganFilterMenu(false);
      setShowDepartmentFilterMenu(false);
      setShowClinicianFilterMenu(false);
      setShowHospitalFilterMenu(false);
      setShowPatientNameFilterMenu(false);
      setShowPatientUHIDFilterMenu(false);
      setShowCaseIdFilterMenu(!showCaseIdFilterMenu);
      setShowSlideFilterMenu(false);
    }
    if (column === "Patient UHID") {
      // Add this block
      // setSelectedDateFilter(null);
      setShowDateFilterMenu(false);
      setShowOrganFilterMenu(false);
      setShowDepartmentFilterMenu(false);
      setShowClinicianFilterMenu(false);
      setShowHospitalFilterMenu(false);
      setShowPatientNameFilterMenu(false);
      setShowPatientUHIDFilterMenu(!showPatientUHIDFilterMenu);
      setShowCaseIdFilterMenu(false);
      setShowSlideFilterMenu(false);
    }
    if (column === "Patient Name") {
      // setSelectedDateFilter(null);
      setShowDateFilterMenu(false);
      setShowOrganFilterMenu(false);
      setShowDepartmentFilterMenu(false);
      setShowClinicianFilterMenu(false);
      setShowHospitalFilterMenu(false);
      setShowPatientNameFilterMenu(!showPatienNameFilterMenu);
      setShowPatientUHIDFilterMenu(false);
      setShowCaseIdFilterMenu(false);
      setShowSlideFilterMenu(false);
    }
    if (column === "Slides") {
      // setSelectedDateFilter(null);
      setShowDateFilterMenu(false);
      setShowOrganFilterMenu(false);
      setShowDepartmentFilterMenu(false);
      setShowClinicianFilterMenu(false);
      setShowHospitalFilterMenu(false);
      setShowPatientNameFilterMenu(false);
      setShowPatientUHIDFilterMenu(false);
      setShowCaseIdFilterMenu(false);
      setShowSlideFilterMenu(!showSlideFilterMenu);
    }
  };

  const uniqueOrganNames = Array.from(
    new Set(filterData.map((row) => row.organ?.toLowerCase()))
  );

  const uniqueDepartmentNames = Array.from(
    new Set(filterData.map((row) => row.department?.toLowerCase()))
  );

  const uniqueClinicianNames = Array.from(
    new Set(filterData.map((row) => row.clinician?.toLowerCase()))
  );

  const uniqueHospitalNames = Array.from(
    new Set(filterData.map((row) => row.hospitalName?.toLowerCase()))
  );

  const getStatusIcon = (row) => {
    if (
      row.original.isReported === true ||
      row.original.isSynopticReported === true
    ) {
      return <AiOutlineCheckCircle size="20" color="#3F62CB" />;
    }
    if (row.original.status === "urgent") {
      return <TbUrgent size="20" color="#D06768" />;
    }
    if (moment.duration(moment().diff(row.original.createdOn)).asDays() < 2) {
      return <Image src={NewCaseIcon} w="20px" />;
    }
    return <MdPendingActions size="20" color="#FF9900" />;
  };

  const [isLargerThanSm] = useMediaQuery("(min-width: 1280px)");
  const [isLargerThanMd] = useMediaQuery("(min-width: 1290px)");
  const [isLargerThanLg] = useMediaQuery("(min-width: 1880px)");
  const [isLargerThanXl] = useMediaQuery("(min-height: 1980px)");
  // default value
  const getComponentSize = () => {
    let size = "340px";
    switch (true) {
      case isLargerThanXl:
        size = "65vh";
        break;
      case isLargerThanLg:
        size = "64vh";
        break;
      case isLargerThanMd:
        size = "60vh";
        break;
      case isLargerThanSm:
        size = "340px";
        break;
      default:
        size = "340px";
        break;
    }
    return size;
  };

  const getPaginationSize = () => {
    let size = "125px";
    switch (true) {
      case isLargerThanXl:
        size = "500px";
        break;
      case isLargerThanLg:
        size = "480px";
        break;
      case isLargerThanMd:
        size = "186px";
        break;
      case isLargerThanSm:
        size = "125px";
        break;
      default:
        size = "125px";
        break;
    }
    return size;
  };

  const getPaginationSingleElementHandleSize = () => {
    let size = "184px";
    switch (true) {
      case isLargerThanXl:
        size = "500px";
        break;
      case isLargerThanLg:
        size = "455px";
        break;
      case isLargerThanMd:
        size = "246px";
        break;
      case isLargerThanSm:
        size = "184px";
        break;
      default:
        size = "184px";
        break;
    }
    return size;
  };
  const componentSize = getComponentSize();
  const componentPaginationSize = getPaginationSize();
  const componentSibgleElementPaginationSize =
    getPaginationSingleElementHandleSize();

  // Render the UI for your table
  return (
    <Flex
      direction="column"
      w="100%"
      maxHeight="340px"
      minHeight={componentSize}
      // height="100%"

      // position="relative"
      overflow={hideScrollbar ? "hidden" : ""}
    >
      <div
        style={{
          position: "relative",
          maxHeight: { componentSize },
          // minHeight: "340px",
          flex: "1",
        }}
      >
        <table
          {...getTableProps()}
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead
            style={{
              backgroundColor: "#f6f6f6",
              position: "sticky", // Make the table header sticky
              top: "0", // Stick the table header at the top
              zIndex: "20",
            }}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={uuidv4()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={uuidv4()}
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      padding: "10px 30px 10px 0px",
                      textAlign: "left",
                      borderBottom: "1px solid #E8E8E8",
                      fontWeight: "bold",
                      fontSize: "13px",
                    }}
                  >
                    <Flex justifyContent="flex-start" alignItems="center">
                      {column.id !== "status" ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <Box paddingRight="4px">
                              <AiFillCaretDown
                                size="14"
                                onClick={() =>
                                  column.toggleSortBy(!column.isSorted)
                                }
                                color="gray"
                              />
                            </Box>
                          ) : (
                            <Box paddingRight="4px">
                              <AiFillCaretUp
                                size="14"
                                onClick={() => {
                                  column.toggleSortBy(!column.isSortedDesc);
                                }}
                                color="gray"
                              />
                            </Box>
                          )
                        ) : (
                          <VStack
                            paddingRight="4px"
                            spacing={-1}
                            onClick={() => column.toggleSortBy(column.isSorted)}
                          >
                            <AiFillCaretUp size="11.8" color="gray" />
                            <AiFillCaretDown size="11.8" color="gray" />
                          </VStack>
                        )
                      ) : null}
                      <Flex alignItems="center">
                        {column.render("Header")}
                        {column.Header === "Organ" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Organ")
                            }
                            showFilterMenu={showOrganFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Organ")}
                          >
                            <FilterMenu
                              uniqueNames={uniqueOrganNames}
                              filterOptions={filterOptions.Organ}
                              selectedValue={selectedOrgan}
                              searchTerm={searchTerm}
                              handleSearchChange={handleSearchChange}
                              hideMenu={() => setShowOrganFilterMenu(false)}
                              setPageSize={setPageSize}
                              data={data}
                              setFilterChecked={setFilterChecked}
                              isFilterChecked={isFilterChecked}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Organ"
                              handleFilterChange={handleFilterChange}
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Department" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Department")
                            }
                            showFilterMenu={showDepartmentFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Department")}
                          >
                            <FilterMenu
                              uniqueNames={uniqueDepartmentNames}
                              filterOptions={filterOptions.Department}
                              selectedValue={selectedDepartment}
                              searchTerm={searchTerm}
                              handleSearchChange={handleSearchChange}
                              hideMenu={() =>
                                setShowDepartmentFilterMenu(false)
                              }
                              setPageSize={setPageSize}
                              data={data}
                              setFilterChecked={setFilterChecked}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Department"
                              handleFilterChange={handleFilterChange}
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Clinician" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Clinician")
                            }
                            showFilterMenu={showClinicianFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Clinician")}
                          >
                            <FilterMenu
                              uniqueNames={uniqueClinicianNames}
                              filterOptions={filterOptions.Clinician}
                              selectedValue={selectedClinician}
                              searchTerm={searchTerm}
                              handleSearchChange={handleSearchChange}
                              hideMenu={() => setShowClinicianFilterMenu(false)}
                              setPageSize={setPageSize}
                              data={data}
                              setFilterChecked={setFilterChecked}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Clinician"
                              handleFilterChange={handleFilterChange}
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Hospital" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Hospital")
                            }
                            showFilterMenu={showHospitalFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Hospital")}
                          >
                            <FilterMenu
                              uniqueNames={uniqueHospitalNames}
                              filterOptions={filterOptions.Hospital}
                              selectedValue={selectedHospital}
                              searchTerm={searchTerm}
                              handleSearchChange={handleSearchChange}
                              hideMenu={() => setShowHospitalFilterMenu(false)}
                              setPageSize={setPageSize}
                              data={data}
                              setFilterChecked={setFilterChecked}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Hospital"
                              handleFilterChange={handleFilterChange}
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Date Created" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Date Created")
                            }
                            showFilterMenu={showDateFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Date Created")}
                          >
                            <DateFilterMenu
                              dateFilterOptions={[
                                { label: "1 day", value: 1 },
                                { label: "3 days", value: 3 },
                                { label: "1 week", value: 7 },
                                { label: "2 weeks", value: 14 },
                                { label: "1 month", value: 30 },
                                { label: "2 months", value: 60 },
                                { label: "6 months", value: 180 },
                              ]}
                              // dateFilterOptions={dateFilterOptiondata}
                              selectedDateFilter={selectedDateFilter}
                              hideMenu={() => setShowDateFilterMenu(false)}
                              // setSearchDate={handleDateSearchChange}
                              setSearchDate={setSearchDate}
                              // setSearchDate={setSearchDate}
                              searchDate={searchDate}
                              setFilterChecked={setFilterChecked}
                              setPageSize={setPageSize}
                              data={data}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Date Created"
                              handleDateFilterChange={handleDateFilterChange}
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Case ID" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Case ID")
                            }
                            showFilterMenu={showCaseIdFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Case ID")}
                          >
                            <SearchFilterMenu
                              searchValue={searchCaseID}
                              setSearchValue={setSearchCaseID}
                              hideMenu={() => setShowCaseIdFilterMenu(false)}
                              setFilterChecked={setFilterChecked}
                              setPageSize={setPageSize}
                              data={data}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Case ID"
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Patient Name" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Patient Name")
                            }
                            showFilterMenu={showPatienNameFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Patient Name")}
                          >
                            <SearchFilterMenu
                              searchValue={searchPatientName}
                              setSearchValue={setSearchPatientName}
                              hideMenu={() =>
                                setShowPatientNameFilterMenu(false)
                              }
                              setFilterChecked={setFilterChecked}
                              setPageSize={setPageSize}
                              data={data}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Patient Name"
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Patient UHID" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Patient UHID")
                            }
                            showFilterMenu={showPatientUHIDFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Patient UHID")}
                          >
                            <SearchFilterMenu
                              searchValue={searchPatientUHID}
                              setSearchValue={setSearchPatientUHID}
                              hideMenu={() =>
                                setShowPatientUHIDFilterMenu(false)
                              }
                              setFilterChecked={setFilterChecked}
                              setPageSize={setPageSize}
                              data={data}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Patient UHID"
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                        {column.Header === "Slides" && (
                          <FilterButton
                            handleFilterIconClick={() =>
                              handleFilterIconClick("Slides")
                            }
                            showFilterMenu={showSlideFilterMenu}
                            column={column}
                            setPageSize={setPageSize}
                            data={data}
                            setFilterChecked={setFilterChecked}
                            isFilterChecked={isFilterChecked}
                            hideScrollbar={hideScrollbar}
                            setHideScrollBar={setHideScrollBar}
                            selected={selectedFilter.includes("Slides")}
                          >
                            <RangeFilterMenu
                              rangeFilterOptions={[
                                // { label: "All", value: "0-0" },
                                { label: "1-20", value: "1-20" },
                                { label: "20-30", value: "20-30" },
                                { label: "30-50", value: "30-50" },
                              ]}
                              selectedRange={selectedSlideRange}
                              handleRangeFilterChange={(value) =>
                                setSelectedSlideRange(value)
                              }
                              hideMenu={() => setShowSlideFilterMenu(false)}
                              setFilterChecked={setFilterChecked}
                              setPageSize={setPageSize}
                              data={data}
                              hideScrollbar={hideScrollbar}
                              setHideScrollBar={setHideScrollBar}
                              handleFilterClick={handleFilterClick}
                              organ="Slides"
                              handleClearFilter={handleClearFilter}
                            />
                          </FilterButton>
                        )}
                      </Flex>
                    </Flex>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {page.length > 0 ? (
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={uuidv4()}
                    onClick={() =>
                      navigate("/dashboard/case", {
                        state: {
                          caseId: row.original.id.split("-")[0],
                          permissions,
                        },
                      })
                    }
                    style={{
                      borderBottom: "1px solid #E8E8E8",
                      cursor: "pointer",
                      height: "60px",
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={uuidv4()}
                          style={{
                            padding: "10px 6px",
                            textAlign: "left",
                            fontSize: "14px",
                          }}
                        >
                          {/* {cell.column.id === "status"
                            ? getStatusIcon(row)
                            : cell.render("Cell")} */}

                          {cell.column.Header === "Hospital" ? (
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <span style={{ marginRight: "8px" }}>
                                {cell.value}
                              </span>
                              {/* <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  alert("hii");
                                }}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                Open Modal
                              </button> */}
                              <Flex justifyContent="flex-end">
                                <Menu>
                                  <MenuButton
                                    as={IconButton}
                                    icon={<FiMoreVertical color="black" />}
                                    colorScheme="black"
                                    height="30px"
                                    width="30px"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />
                                  <MenuList minWidth="80px">
                                    <MenuItem
                                      // onClick={() => handleOpenModal(slide)}
                                      onClick={(e) => {
                                        openModalWithCase(cell.row.original.id);
                                        e.stopPropagation();
                                      }}
                                    >
                                      Edit Case
                                    </MenuItem>
                                    <MenuItem
                                      onClick={(e) => {
                                        openModaDelete(cell.row.original.id);
                                        e.stopPropagation();
                                      }}
                                    >
                                      Delete Case
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </Flex>
                            </Flex>
                          ) : cell.column.id === "status" ? (
                            getStatusIcon(row)
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr
                style={{
                  height: "100%", // Fill the table body with a single row
                }}
              >
                <td
                  colSpan={headerGroups[0].headers.length}
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    height: "240px",
                  }}
                >
                  No data found
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <div
          className="Pagination"
          style={{
            marginTop: `${
              page.length === 10
                ? "0px"
                : page.length === 1
                ? componentSibgleElementPaginationSize
                : componentPaginationSize
            }`,
            position: "sticky",
            top: "0",
            bottom: "0", // Stick the pagination bar to the bottom
            zIndex: "1", // Make sure the pagination is above the table content
            backgroundColor: "white",
            padding: "5px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            maxHeight: "300px", // Adjust the maximum height as needed
          }}
        >
          <Button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            type="button"
            title="Go to first page"
            style={{
              backgroundColor: "#E8E8E8",
              color: "#333",
              border: "none",
              padding: "5px 10px",
              margin: "0 2px",
              cursor: "pointer",
            }}
          >
            <FaChevronLeft />
          </Button>

          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            type="button"
            title="Go to next page"
            style={{
              backgroundColor: "#E8E8E8",
              color: "#333",
              border: "none",
              padding: "5px 10px",
              margin: "0 5px",
              cursor: "pointer",
            }}
          >
            <FaChevronRight />
          </Button>
          <span>
            | Page size:{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              style={{ padding: "3px" }}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </span>
        </div>
      </div>

      <CaseEditModal
        isOpen={isOpen}
        onClose={onClose}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        isEditing={isEditing}
        handleEditClick={handleEditClick}
        handleCancelClick={handleCancelClick}
        handleSaveClick={handleSaveClick}
        caseInfo={caseInfo}
        inputPatientData={inputPatientData}
        inputCaseData={inputCaseData}
        setInputCaseData={setInputCaseData}
        setInputPatientData={setInputPatientData}
        handlePatientInput={handlePatientInput}
        handleCaseInput={handleCaseInput}
        permissions={permissions}
      />

      <CaseDeleteModal
        isOpen={isModalOpen}
        onClose={closeModal}
        selectedId={selectedId}
        permissions={permissions}
      />
    </Flex>
  );
};

const CaseTable = ({ filterData, permissions }) => {
  const [isFilterChecked, setFilterChecked] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    Organ: ["All"],
    Department: ["All"],
    Clinician: ["All"],
    Hospital: ["All"],
  });
  const [selectedOrgan, setSelectedOrgan] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedClinician, setSelectedClinician] = useState("");
  const [selectedHospital, setSelectedHospital] = useState("");
  const [showOrganFilterMenu, setShowOrganFilterMenu] = useState(false);
  const [showDepartmentFilterMenu, setShowDepartmentFilterMenu] =
    useState(false);
  const [showClinicianFilterMenu, setShowClinicianFilterMenu] = useState(false);
  const [showHospitalFilterMenu, setShowHospitalFilterMenu] = useState(false);
  const [showPatienNameFilterMenu, setShowPatientNameFilterMenu] =
    useState(false);
  const [showPatientUHIDFilterMenu, setShowPatientUHIDFilterMenu] =
    useState(false);
  const [showCaseIdFilterMenu, setShowCaseIdFilterMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDateFilterMenu, setShowDateFilterMenu] = useState(false); // Add this line
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [searchDate, setSearchDate] = useState("");
  const [searchPatientName, setSearchPatientName] = useState("");
  const [searchPatientUHID, setSearchPatientUHID] = useState("");
  const [searchCaseID, setSearchCaseID] = useState("");
  const [selectedSlideRange, setSelectedSlideRange] = useState(null);
  const [showSlideFilterMenu, setShowSlideFilterMenu] = useState(false);
  const [data, setData] = useState([]);

  const columns = React.useMemo(() => [
    {
      Header: "",
      accessor: "status",
      disableSortBy: true,
    },
    {
      Header: "Case ID",
      accessor: "caseNumber",
      Filter: FilterButton,
    },
    {
      Header: "Date Created",
      accessor: "date",
      Filter: FilterButton,
    },
    {
      Header: "Organ",
      accessor: "organ",
      Filter: FilterButton,
    },
    {
      Header: "Department",
      accessor: "department",
      Filter: FilterButton,
    },
    {
      Header: "Patient Name",
      accessor: "patientName",
      Filter: FilterButton,
    },
    {
      Header: "Patient UHID",
      accessor: "uhid",
      Filter: FilterButton,
    },
    {
      Header: "Clinician",
      accessor: "clinician",
      Filter: FilterButton,
    },
    {
      Header: "Slides",
      accessor: "slides",
      Filter: FilterButton,
    },
    {
      Header: "Hospital",
      accessor: "hospitalName",
      Filter: FilterButton,
    },
  ]);
  // const applyDateFilter = (rowDate) => {
  //   if (!selectedDateFilter && !searchDate) return true;
  //   const currentDate = new Date();
  //   const rowDateParts = rowDate.split("/");
  //   const rowDateObj = new Date(
  //     `${rowDateParts[2]}-${rowDateParts[1]}-${rowDateParts[0]}`
  //   );
  //   if (selectedDateFilter && searchDate) {
  //     const startDate = new Date(
  //       new Date(searchDate) - selectedDateFilter.value * 24 * 60 * 60 * 1000
  //     );
  //     return rowDateObj >= startDate && rowDateObj <= new Date(searchDate);
  //   }
  //   if (selectedDateFilter) {
  //     const startDate = new Date(
  //       currentDate - selectedDateFilter.value * 24 * 60 * 60 * 1000
  //     );
  //     return rowDateObj >= startDate && rowDateObj <= currentDate;
  //   }
  //   if (searchDate) {
  //     const formattedSearchDate = new Date(searchDate);
  //     return rowDateObj >= formattedSearchDate;
  //   }
  //   return true;
  // };
  const applyDateFilter = (rowDate) => {
    if (!selectedDateFilter && !searchDate) return true;
    localStorage.setItem("dateSelect", selectedDateFilter);
    const parseDate = (dateString) => {
      const dateParts = dateString.split("/");
      return new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    };

    const currentDate = new Date();
    const rowDateObj = parseDate(rowDate);

    if (selectedDateFilter && searchDate) {
      const startDate = new Date(
        parseDate(searchDate) - selectedDateFilter.value * 24 * 60 * 60 * 1000
      );
      return rowDateObj >= startDate && rowDateObj <= parseDate(searchDate);
    }

    if (selectedDateFilter) {
      const startDate = new Date(
        currentDate - selectedDateFilter.value * 24 * 60 * 60 * 1000
      );
      return rowDateObj >= startDate && rowDateObj <= currentDate;
    }

    if (searchDate) {
      const formattedSearchDate = parseDate(searchDate);
      return rowDate === searchDate;
    }

    return true;
  };
  useEffect(() => {
    setData(
      filterData !== undefined
        ? filterData
            .filter((row) =>
              filterOptions.Organ.includes("All")
                ? true
                : filterOptions.Organ.includes(row?.organ?.toLowerCase())
            )
            .filter((row) =>
              filterOptions.Department.includes("All")
                ? true
                : filterOptions.Department.includes(
                    row.department?.toLowerCase()
                  )
            )
            .filter((row) =>
              filterOptions.Clinician.includes("All")
                ? true
                : filterOptions.Clinician.includes(row.clinician?.toLowerCase())
            )
            .filter((row) =>
              filterOptions.Hospital.includes("All")
                ? true
                : filterOptions.Hospital.includes(
                    row.hospitalName?.toLowerCase()
                  )
            )
            .filter((row) => applyDateFilter(row.date))
            .filter((row) =>
              row.patientName
                .toLowerCase()
                .includes(searchPatientName.toLowerCase())
            )
            .filter((row) =>
              row.uhid.toLowerCase().includes(searchPatientUHID.toLowerCase())
            )
            .filter((row) =>
              row.caseNumber.toLowerCase().includes(searchCaseID.toLowerCase())
            )
            .filter((row) => {
              if (!selectedSlideRange) {
                return true;
              }
              const slideValue = parseInt(row.slides, 10);
              const [min, max] = selectedSlideRange.split("-").map(Number);
              if (min === 0) {
                return true;
              }
              return slideValue >= min && slideValue <= max;
            })
        : []
    );
  }, [
    searchPatientName,
    selectedSlideRange,
    searchCaseID,
    searchPatientUHID,
    searchPatientName,
    filterOptions,
    filterData,
    selectedDateFilter,
    searchDate,
  ]);

  return filterData ? (
    <Flex maxH="65vh" overflowY="scroll" minH="80px">
      <Table
        columns={columns}
        data={data}
        filterData={filterData}
        isFilterChecked={isFilterChecked}
        setFilterChecked={setFilterChecked}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        selectedOrgan={selectedOrgan}
        setSelectedOrgan={setSelectedOrgan}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedClinician={selectedClinician}
        setSelectedClinician={setSelectedClinician}
        selectedHospital={selectedHospital}
        setSelectedHospital={setSelectedHospital}
        showOrganFilterMenu={showOrganFilterMenu}
        setShowOrganFilterMenu={setShowOrganFilterMenu}
        showDepartmentFilterMenu={showDepartmentFilterMenu}
        setShowDepartmentFilterMenu={setShowDepartmentFilterMenu}
        showClinicianFilterMenu={showClinicianFilterMenu}
        setShowClinicianFilterMenu={setShowClinicianFilterMenu}
        showHospitalFilterMenu={showHospitalFilterMenu}
        setShowHospitalFilterMenu={setShowHospitalFilterMenu}
        showPatienNameFilterMenu={showPatienNameFilterMenu}
        setShowPatientNameFilterMenu={setShowPatientNameFilterMenu}
        showPatientUHIDFilterMenu={showPatientUHIDFilterMenu}
        setShowPatientUHIDFilterMenu={setShowPatientUHIDFilterMenu}
        showCaseIdFilterMenu={showCaseIdFilterMenu}
        setShowCaseIdFilterMenu={setShowCaseIdFilterMenu}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showDateFilterMenu={showDateFilterMenu}
        setShowDateFilterMenu={setShowDateFilterMenu}
        selectedDateFilter={selectedDateFilter}
        setSelectedDateFilter={setSelectedDateFilter}
        searchDate={searchDate}
        setSearchDate={setSearchDate}
        searchPatientName={searchPatientName}
        setSearchPatientName={setSearchPatientName}
        searchPatientUHID={searchPatientUHID}
        setSearchPatientUHID={setSearchPatientUHID}
        searchCaseID={searchCaseID}
        setSearchCaseID={setSearchCaseID}
        selectedSlideRange={selectedSlideRange}
        setSelectedSlideRange={setSelectedSlideRange}
        showSlideFilterMenu={showSlideFilterMenu}
        setShowSlideFilterMenu={setShowSlideFilterMenu}
        permissions={permissions}
      />
    </Flex>
  ) : (
    <Flex w="100%" alignItems="center" justifyContent="center">
      Loading data
    </Flex>
  );
};

export default CaseTable;
