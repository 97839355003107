/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Flex, HStack, Text, VStack, Avatar, Input } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import { NEW_CHAT_SUBSCRIPTION } from "../../state/API/graphql/ChatQuery";
import {
  useGetFilterDataQuery,
  useGetUserInfoQuery,
  useGetUsersListInOrgQuery,
  useSearchUsersByNameQuery,
  useSearchCaseByIDQuery,
  useGetRecentUserChatQuery,
  useLazyGetRecentUserChatQuery,
  useGetRecentGroupChatQuery,
  useLazyGetRecentGroupChatQuery,
  useGetSubscriptionIdListQuery,
} from "../../state/API/HospitalApi";
import ScrollBar from "../../Components/other/Scrollbar";
import Environment from "../../environment";

const ChatLists = ({
  setGroupData,
  setActiveGroup,
  tab,
  searchName,
  searchCase,
  activeGroup,
}) => {
  const [getRecentUserProfiles] = useLazyGetRecentUserChatQuery();
  const [getRecentGroupProfiles] = useLazyGetRecentGroupChatQuery();
  const { user } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user?.sub }
  );

  const { data: userRecentChatProfile } = useGetRecentUserChatQuery(
    {
      userId: userInfo?._id,
      searchTerm: searchName,
    },
    { skip: !userInfo?._id }
  );
  const { data: userRecentGroupChatProfile } = useGetRecentGroupChatQuery(
    {
      userId: userInfo?._id,
      searchTerm: searchCase,
    },
    { skip: !userInfo?._id }
  );
  const { data: userSubscriptionIdList } = useGetSubscriptionIdListQuery(
    {
      userId: userInfo?._id,
    },
    { skip: !userInfo?._id }
  );
  const { data: subscribedChatData } = useSubscription(NEW_CHAT_SUBSCRIPTION, {
    variables: {
      toId: [userInfo?._id],
    },
  });
  const { data: subscribedCaseChatData } = useSubscription(
    NEW_CHAT_SUBSCRIPTION,
    {
      variables: {
        toId: userSubscriptionIdList,
      },
    }
  );

  useEffect(() => {
    if (!subscribedChatData) return;
    getRecentUserProfiles({ userId: userInfo?._id, searchTerm: "" });
  }, [subscribedChatData, userRecentChatProfile]);
  useEffect(() => {
    if (!subscribedCaseChatData) return;
    getRecentGroupProfiles({ userId: userInfo?._id, searchTerm: "" });
  }, [subscribedCaseChatData]);
  const { data: usersList } = useGetUsersListInOrgQuery(
    {
      organizationId: userInfo?.organization?._id,
    },
    { skip: !userInfo?.organization?._id }
  );

  // const { data: userByName } = useSearchUsersByNameQuery({
  //   searchTerm: searchName,
  //   organizationId: userInfo?.organization?._id,
  // });
  // const { data: casesById } = useSearchCaseByIDQuery({
  //   searchTerm: searchCase,
  //   organizationId: userInfo?.organization?._id,
  // });

  const [chatGroups, setChatGroups] = useState();
  const [data, setData] = useState("");
  const sharedCases = userInfo?.caseShared?.map((cases) => cases?.caseId);

  // const handleSubmit = async () => {
  //   const resp = await axios.get(
  //     `${Environment.USER_URL}/recent_chats?userId=${userInfo?._id}`
  //   );
  //   setData(data);
  // };

  useEffect(() => {
    setActiveGroup("");
    setGroupData("");
  }, [tab]);
  // console.log(userRecentChatProfile);
  return (
    <ScrollBar>
      <Flex flexDirection="column">
        {/* {tab === 0 ? (
          <Input
            onChange={(e) => setSearchName(e.target.value)}
            placeholder="Search user"
          />
        ) : (
          <Input
            onChange={(e) => setSearchCase(e.target.value)}
            placeholder="Search case"
          />
        )} */}

        {tab === 1
          ? userRecentGroupChatProfile?.map((cases) => {
              return (
                <Flex
                  mr="5px"
                  ml="5px"
                  h="7.5vh"
                  minH="58px"
                  border="none"
                  w="100%"
                  bg="white"
                  _hover={{
                    bgColor: "#F6F6F6",
                    cursor: "pointer",
                    transition: "0.2s",
                  }}
                  bgColor={
                    activeGroup === cases?._id && "rgba(27, 117, 188, 0.21)"
                  }
                  onClick={() => {
                    setGroupData(cases);
                    setActiveGroup(cases?._id);
                  }}
                  letterSpacing="0.7px"
                  fontSize="caseInfo"
                  borderBottom="1px solid #DEDEDE"
                  key={uuidv4()}
                >
                  <HStack marginStart="0.8vw" border="none" w="100%">
                    <Avatar
                      h="40px"
                      w="40px"
                      size="sm"
                      name={cases?.caseId.slice(
                        cases?.caseId.length - 5,
                        cases?.caseId.length
                      )}
                      getInitials={(name) => name.slice(0, 2)}
                    />
                    <VStack alignItems="flex-start" spacing={3} w="100%">
                      <Flex
                        w="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        pt="0"
                        pr="1"
                        mt="-0rem !important"
                      >
                        <Text w="90%" fontSize="caseHeader" isTruncated>
                          {cases?.caseId}
                        </Text>
                      </Flex>
                    </VStack>
                  </HStack>
                </Flex>
              );
            })
          : userRecentChatProfile?.map((User) => {
              return (
                <Flex
                  mr="5px"
                  ml="5px"
                  h="7.5vh"
                  minH="58px"
                  border="none"
                  w="100%"
                  bg="white"
                  _hover={{
                    bgColor: "#F6F6F6",
                    cursor: "pointer",
                    transition: "0.2s",
                  }}
                  onClick={() => {
                    setGroupData(User);
                    setActiveGroup(User?._id);
                  }}
                  bgColor={
                    activeGroup === User?._id && "rgba(27, 117, 188, 0.21)"
                  }
                  letterSpacing="0.7px"
                  fontSize="caseInfo"
                  borderBottom="1px solid #DEDEDE"
                  key={uuidv4()}
                >
                  <HStack marginStart="0.8vw" border="none" w="100%">
                    <Avatar
                      h="40px"
                      w="40px"
                      size="sm"
                      name={`${User?.firstName} ${User.lastName}`}
                      getInitials={(name) => name.slice(0, 2)}
                    />
                    <VStack alignItems="flex-start" spacing={3} w="100%">
                      <Flex
                        w="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        pt="0"
                        pr="1"
                        mt="-0rem !important"
                      >
                        <Text w="90%" fontSize="caseHeader" isTruncated>
                          {`${User?.firstName} ${User.lastName}`}
                        </Text>
                      </Flex>
                    </VStack>
                  </HStack>
                </Flex>
              );
            })}
      </Flex>
    </ScrollBar>
  );
};

export default ChatLists;
