import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  HStack,
  Text,
  VStack,
  Avatar,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidv4 } from "uuid";
import {
  useGetUserInfoQuery,
  useGetUsersListInOrgQuery,
  useGetCaseListDataQuery,
} from "../../state/API/HospitalApi";
import ScrollBar from "../../Components/other/Scrollbar";

const ChatModal = ({
  isOpen,
  onClose,
  optionData,
  setGroupData,
  setActiveGroup,
}) => {
  const { user } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user?.sub }
  );
  // const [searchName, setSearchName] = useState("");
  // const [searchCase, setSearchCase] = useState("");

  const { data: usersList } = useGetUsersListInOrgQuery(
    {
      organizationId: userInfo?.organization?._id,
      userId: userInfo?._id,
    },
    { skip: !userInfo?.organization?._id }
  );

  const { data: chatList } = useGetCaseListDataQuery(
    {
      organizationId: userInfo?.organization?._id,
      doctor: "",
      organ: "",
      hospital: "",
      caseStatus: "",
      userId: userInfo?._id,
    },
    { skip: !userInfo?.organization?._id }
  );
  // const chatList = [];

  // const { data: userByName } = useSearchUsersByNameQuery({
  //   searchTerm: searchName,
  //   organizationId: userInfo?.organization?._id,
  // });
  // const { data: casesById } = useSearchCaseByIDQuery({
  //   searchTerm: searchCase,
  //   organizationId: userInfo?.organization?._id,
  // });
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="#1B75BC">
          {optionData === 1 ? "Contacts" : "Groups"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ScrollBar>
            <Flex flexDirection="column">
              {optionData === 1 &&
                usersList?.map((User) => {
                  return (
                    <Flex
                      h="7.5vh"
                      minH="58px"
                      border="none"
                      w="100%"
                      bg="white"
                      _hover={{
                        bgColor: "#F6F6F6",
                        cursor: "pointer",
                        transition: "0.2s",
                      }}
                      onClick={() => {
                        setGroupData(User);
                        setActiveGroup(User?._id);
                        onClose();
                      }}
                      letterSpacing="0.7px"
                      fontSize="caseInfo"
                      borderBottom="1px solid #DEDEDE"
                      key={uuidv4()}
                    >
                      <HStack marginStart="0.8vw" border="none" w="100%">
                        <Avatar
                          h="40px"
                          w="40px"
                          size="sm"
                          name={`${User?.firstName} ${User.lastName}`}
                          getInitials={(name) => name.slice(0, 2)}
                        />
                        <VStack alignItems="flex-start" spacing={3} w="100%">
                          <Flex
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            pt="0"
                            pr="1"
                            mt="-0rem !important"
                          >
                            <Text w="90%" fontSize="caseHeader" isTruncated>
                              {`${User?.firstName} ${User.lastName}`}
                            </Text>
                          </Flex>
                        </VStack>
                      </HStack>
                    </Flex>
                  );
                })}
              {optionData === 2 &&
                chatList?.map((cases) => {
                  return (
                    <Flex
                      h="7.5vh"
                      minH="58px"
                      border="none"
                      w="100%"
                      bg="white"
                      _hover={{
                        bgColor: "#F6F6F6",
                        cursor: "pointer",
                        transition: "0.2s",
                      }}
                      onClick={() => {
                        setGroupData(cases);
                        setActiveGroup(cases?._id);
                        onClose();
                      }}
                      letterSpacing="0.7px"
                      fontSize="caseInfo"
                      borderBottom="1px solid #DEDEDE"
                      key={uuidv4()}
                    >
                      <HStack marginStart="0.8vw" border="none" w="100%">
                        <Avatar
                          h="40px"
                          w="40px"
                          size="sm"
                          name={cases.caseId.slice(
                            cases.caseId.length - 5,
                            cases.caseId.length
                          )}
                          getInitials={(name) => name.slice(0, 2)}
                        />
                        <VStack alignItems="flex-start" spacing={3} w="100%">
                          <Flex
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            pt="0"
                            pr="1"
                            mt="-0rem !important"
                          >
                            <Text w="90%" fontSize="caseHeader" isTruncated>
                              {cases?.caseId}
                            </Text>
                          </Flex>
                        </VStack>
                      </HStack>
                    </Flex>
                  );
                })}
            </Flex>
          </ScrollBar>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChatModal;
