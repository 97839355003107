import React, { useRef, useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

const DateFilterMenu = ({
  dateFilterOptions,
  selectedDateFilter,
  handleDateFilterChange,
  hideMenu,
  setSearchDate,
  searchDate,
  setFilterChecked,
  setPageSize,
  data,
  hideScrollbar,
  setHideScrollBar,
  organ,
  handleFilterClick,
  handleClearFilter,
}) => {
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchDate]);

  const [focusedOption, setFocusedOption] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".filter-menu")) {
        return;
      }
      hideMenu();
      setHideScrollBar(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideMenu]);

  return (
    <div
      className="filter-menu"
      style={{
        padding: "1rem",
        width: "190px",
        position: "relative",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <Input
        name="inputfield"
        type="text"
        ref={searchInputRef}
        placeholder="Search by date..."
        value={searchDate}
        onChange={(e) => {
          setSearchDate(e.target.value);
          if (e.target.value) {
            handleFilterClick(organ);
          } else {
            handleClearFilter(organ);
          }
        }}
        style={{
          width: "100%",
          padding: "0.5rem",
          marginBottom: "1rem",
          borderRadius: "4px",
          border: "1px solid #ccc",
          fontSize: "14px",
        }}
      />
      <div
        style={{
          width: "160px",
          height: "110px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "2px",
          },
          "&::-webkit-scrollbar-track": {
            width: "2px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
      >
        <ul style={{ listStyle: "none", padding: 0 }}>
          {dateFilterOptions.map((option) => (
            <li key={option.label}>
              <label
                htmlFor={option.label}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  transition: "background-color 0.3s, box-shadow 0.3s",
                  backgroundColor:
                    focusedOption === option.label
                      ? "rgba(49, 130, 206, 0.2)"
                      : "transparent",
                  padding: "0.5rem",
                  borderRadius: "4px",
                  border: "1px solid transparent",
                  marginBottom: "0.25rem",
                }}
                onMouseEnter={() => setFocusedOption(option.label)}
                onMouseLeave={() => setFocusedOption(null)}
              >
                <input
                  type="radio"
                  id={option.label}
                  value={option.value}
                  onChange={(e) => {
                    handleDateFilterChange(option);
                    handleFilterClick(organ);
                  }}
                  checked={selectedDateFilter?.label === option.label}
                  style={{
                    position: "absolute",
                    opacity: 0,
                  }}
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      width: "14px",
                      height: "14px",
                      border: "1px solid #ccc",
                      borderRadius: "2px",
                      marginRight: "20px", // Add some space here
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background:
                        selectedDateFilter?.label === "All"
                          ? "#007bff"
                          : "white",
                    }}
                  >
                    {selectedDateFilter?.label === option.label && (
                      <CheckIcon color="green" />
                    )}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      fontSize: "13px",
                      fontWeight:
                        selectedDateFilter?.label === option.label
                          ? "bold"
                          : "normal",
                    }}
                  >
                    {option.label}
                  </span>
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
      <button
        onClick={(e) => {
          hideMenu();
          setHideScrollBar(false);
          handleDateFilterChange(null);
          setSearchDate(null);
          handleClearFilter(organ);
          // setPageSize(10);
          // setFilterChecked(false);
        }}
        type="button"
        style={{
          marginTop: "10px",
          marginLeft: "95px",
          padding: "5px 15px",
          backgroundColor: "#ccc",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "13px",
        }}
      >
        Clear
      </button>
    </div>
  );
};

export default DateFilterMenu;
