import html2canvas from "html2canvas";

export const getTimestamp = () =>
  new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  }).format(Date.now());

export const getCanvasImage = async (viewerId) => {
  const canvas = await html2canvas(
    document.querySelector(`#${`viewer${viewerId}`} .openseadragon-canvas`),
    {
      backgroundColor: null,
      logging: true,
      allowTaint: false,
      useCORS: true,
      removeContainer: false,
    }
  );
  return canvas.toDataURL("image/png");
};

// check if case has enough slides for a particular project type
// for single slide - 1 and for multi slide more than 1
export const isCaseViewable = (projectType, numOfSlides) => {
  if (projectType === "singleSlide" && numOfSlides !== 1) return false;
  if (projectType === "multiSlide" && numOfSlides < 2) return false;
  return true;
};

// to get id (number) from user subclaim
export const getUserId = (user) =>
  user ? user.sub.substring(user.sub.indexOf("|") + 1) : "";

// extract slide url from .dzi file
export const getSlideUrl = (url) =>
  url ? `${url.substring(0, url.lastIndexOf("."))}_files/8/0_0.jpeg` : "";

export const isActionValid = (action, allowedActions = []) => {
  return !allowedActions.includes(action);
};
