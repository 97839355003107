import { React, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import { Mention, MentionsInput } from "react-mentions";
import {
  Flex,
  Text,
  Button,
  Box,
  Avatar,
  Input,
  Spinner,
  VStack,
  CloseButton,
  HStack,
  useMediaQuery,
} from "@chakra-ui/react";
import moment from "moment";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineSend, AiOutlineRight } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import defaultStyle from "./defaultStyle";
import defaultMentionStyle from "./defautMentionStyle";
import {
  CHAT_SUBSCRIPTION,
  FETCH_CONVERSATION,
  SEND_MESSAGE,
} from "../../state/API/graphql/ChatQuery";
import ScrollBar from "../../Components/other/Scrollbar";
import {
  useAddMultipleUserToCaseMutation,
  useGetUsersListQuery,
  useGetCaseInfoQuery,
  useLazyGetRecentGroupChatQuery,
} from "../../state/API/HospitalApi";
import QueryChat from "./QueryChat";
import Environment from "../../environment";

const formats = {
  sameDay: "[Today]",
  nextDay: "[Tomorrow]",
  nextWeek: "dddd",
  lastDay: "[Yesterday]",
  lastWeek: "[Last] dddd",
  sameElse: "DD/MM/YYYY",
};

const DateSeperatorComponent = ({ messageSepratorDate }) => {
  return (
    <Text
      my="10px"
      alignSelf="center"
      fontSize="12px"
      fontWeight="500"
      color="#52585D"
    >
      {moment(messageSepratorDate).calendar(new Date(), formats)}
    </Text>
  );
};
// const RightMessageComponent = ({ data, setQueryChat }) => {
//   return data?.mentionedUsers?.length > 0 ? (
//     <Box
//       key={uuidv4()}
//       p="0.7rem"
//       bg="rgba(176, 200, 214, 0.15)"
//       onClick={() => setQueryChat(data)}
//       cursor="pointer"
//       alignSelf="flex-end"
//       maxW="506px"
//       minW="220px"
//       borderRadius="0"
//     >
//       <Flex color="#3B5D7C" fontSize="12px" justifyContent="space-between">
//         <Text color="#3B5D7C" fontSize="12px">
//           QUERY
//         </Text>
//         <Flex>
//           <Text>{`Dr.${data.mentionedUsers[0].toName}`}</Text>
//           {data.mentionedUsers.length > 1 && (
//             <Text ml="0.3rem">{`  +${data.mentionedUsers.length - 1}`}</Text>
//           )}
//         </Flex>
//       </Flex>

//       <Text color="#52585D" fontSize="12px" fontWeight="bold">
//         {data.payload.body}
//       </Text>
//       <Text color="#212224" fontSize="10px" textAlign="right">
//         {/* {data.sendAt.fromNow()} */}
//         {moment(data.createdAt).format("HH:mm")}
//       </Text>
//     </Box>
//   ) : (
//     <Box
//       key={uuidv4()}
//       p="14px 19px"
//       bg="#D6D6D6"
//       alignSelf="flex-end"
//       maxW="506px"
//       borderRadius="14px 0px 14px 14px"
//     >
//       <Text color="#52585D" fontSize="12px">
//         {data.payload.body}
//       </Text>
//       <Text color="#212224" fontSize="10px" textAlign="right">
//         {/* {data.sendAt.fromNow()} */}
//         {moment(data.createdAt).format("HH:mm")}
//       </Text>
//     </Box>
//   );
// };

// const LeftMessageComponent = ({ data, setQueryChat, index }) => {
//   return (
//     <Flex>
//       <Avatar
//         h="36px"
//         w="36px"
//         size="sm"
//         name={data?.fromName}
//         // color="#fff"
//         // src="https://wallpapers.com/images/hd/cool-profile-pictures-red-anime-fw4wgkj905tjeujb.jpg"
//         // bg="#3B5D7C"
//         marginTop="-3px"
//         // icon={<AiOutlineUser fontSize="1.5rem" />}
//       />
//       {data?.mentionedUsers?.length > 0 ? (
//         <Box
//           key={uuidv4()}
//           p="0.7rem"
//           bg="rgba(176, 200, 214, 0.15)"
//           onClick={() => setQueryChat(data)}
//           cursor="pointer"
//           alignSelf="flex-end"
//           maxW="506px"
//           minW="220px"
//           borderRadius="0"
//           ml="10px"
//         >
//           <Flex color="#3B5D7C" fontSize="12px" justifyContent="space-between">
//             <Text color="#3B5D7C" fontSize="12px">
//               QUERY
//             </Text>
//             <Text>{`${data.fromName}`}</Text>
//           </Flex>
//           <Text color="#52585D" fontSize="12px" fontWeight="bold">
//             {data.payload.body}
//           </Text>
//           <Text color="#212224" fontSize="10px" textAlign="right">
//             {/* {data.sendAt.fromNow()} */}
//             {moment(data.createdAt).format("HH:mm")}
//           </Text>
//         </Box>
//       ) : (
//         <Flex direction="column" mt={index === 1 ? "-20px" : "0"}>
//           {index === 1 && (
//             <Text
//               marginTop="-10px"
//               fontSize="14px"
//               textTransform="capitalize"
//               ml="10px"
//               fontWeight={600}
//               mb="10px"
//             >
//               {data.fromName}
//             </Text>
//           )}
//           <Box
//             key={uuidv4()}
//             p="14px 19px"
//             bg="#E8E8EB"
//             alignSelf="flex-start"
//             maxW="506px"
//             borderRadius=" 0px 14px 14px 14px"
//             ml="10px"
//           >
//             <Text color="#52585D" fontSize="12px">
//               {data.payload.body}
//             </Text>

//             <Text textAlign="right" fontSize="10px" color="#212224">
//               {moment(data.createdAt).format("HH:mm")}
//             </Text>
//           </Box>
//         </Flex>
//       )}
//     </Flex>
//   );
// };

const RightMessageComponent = ({ data, setQueryChat, handleButtonClick }) => {
  return data?.mentionedUsers?.length > 0 ? (
    <Box
      key={uuidv4()}
      p="0.7rem"
      bg="rgba(176, 200, 214, 0.15)"
      onClick={() => setQueryChat(data)}
      cursor="pointer"
      alignSelf="flex-end"
      maxW="506px"
      minW="220px"
      borderRadius="0"
    >
      <Flex
        color="rgba(59, 93, 124, 1)"
        fontSize="13px"
        justifyContent="space-between"
      >
        <Flex fontWeight="bold">
          <Text>QUERY </Text>
          <Text paddingLeft="12px">{`Dr.${data.mentionedUsers[0].toName}`}</Text>
        </Flex>
        <Text color="rgba(33, 34, 36, 1)" paddingLeft="12px" fontSize="12px">
          {/* {data.sendAt.fromNow()} */}
          {moment(data.createdAt).format("HH:mm")}
        </Text>
      </Flex>

      <Flex justifyContent="space-between" color="rgba(33, 34, 36, 1)">
        <Text fontSize="12px" fontWeight="bold">
          {data.payload?.body !== undefined && data.payload?.body}
        </Text>
        {!data?.annotation?.hash && <AiOutlineRight size="13px" />}
      </Flex>
      {data?.annotation?.hash && (
        <Flex justifyContent="space-between">
          <Text
            color="rgba(59, 93, 124, 1)"
            fontSize="12px"
            fontWeight="bold"
            onClick={() =>
              handleButtonClick(data.slideId, data.caseId, data.annotation.hash)
            }
            textDecor="underline"
            textUnderlineOffset="5px"
          >
            open slide region
          </Text>
          <AiOutlineRight size="13px" />
        </Flex>
      )}
    </Box>
  ) : (
    <Box
      key={uuidv4()}
      p="14px 19px"
      bg="#D6D6D6"
      alignSelf="flex-end"
      maxW="506px"
      borderRadius="14px 0px 14px 14px"
    >
      <Text color="#52585D" fontSize="12px">
        {data.payload?.body !== undefined && data.payload?.body}
      </Text>
      <Text color="#212224" fontSize="10px" textAlign="right">
        {/* {data.sendAt.fromNow()} */}
        {moment(data.createdAt).format("HH:mm")}
      </Text>
    </Box>
  );
};

const LeftMessageComponent = ({
  data,
  setQueryChat,
  index,
  groupMessages,
  handleButtonClick,
}) => {
  return (
    <Flex direction="column">
      <Flex>
        {groupMessages[index]?.fromName !==
        groupMessages[index + 1]?.fromName ? (
          <Avatar
            h="36px"
            w="36px"
            size="sm"
            name={data?.fromName}
            mt="28px"
            // color="#fff"
            // src="https://wallpapers.com/images/hd/cool-profile-pictures-red-anime-fw4wgkj905tjeujb.jpg"
            // bg="#3B5D7C"
            // icon={<AiOutlineUser fontSize="1.5rem" />}
          />
        ) : (
          <Avatar size="sm" visibility="hidden" />
        )}
        {data?.mentionedUsers?.length > 0 ? (
          <Box
            key={uuidv4()}
            p="0.7rem"
            bg="rgba(176, 200, 214, 0.15)"
            onClick={() => setQueryChat(data)}
            cursor="pointer"
            alignSelf="flex-end"
            maxW="506px"
            minW="220px"
            borderRadius="0"
            ml="10px"
          >
            <Flex
              color="rgba(59, 93, 124, 1)"
              fontSize="13px"
              justifyContent="space-between"
            >
              <Flex fontWeight="bold">
                <Text>QUERY </Text>
                <Text paddingLeft="12px">{`Dr.${data.mentionedUsers[0].toName}`}</Text>
              </Flex>
              <Text
                color="rgba(33, 34, 36, 1)"
                paddingLeft="12px"
                fontSize="12px"
              >
                {/* {data.sendAt.fromNow()} */}
                {moment(data.createdAt).format("HH:mm")}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" color="rgba(33, 34, 36, 1)">
              <Text fontSize="12px" fontWeight="bold">
                {data.payload?.body !== undefined && data.payload?.body}
              </Text>
              {!data?.annotation?.hash && <AiOutlineRight size="12px" />}
            </Flex>
            {data?.annotation?.hash && (
              <Flex justifyContent="space-between">
                <Text
                  color="rgba(59, 93, 124, 1)"
                  fontSize="12px"
                  fontWeight="bold"
                  onClick={() =>
                    handleButtonClick(
                      data.slideId,
                      data.caseId,
                      data.annotation.hash
                    )
                  }
                  textDecor="underline"
                  textUnderlineOffset="5px"
                >
                  open slide region
                </Text>
                <AiOutlineRight size="12px" />
              </Flex>
            )}
          </Box>
        ) : (
          <Box
            key={uuidv4()}
            p="14px 19px"
            bg="#D6D6D6"
            alignSelf="flex-end"
            maxW="506px"
            borderRadius={
              groupMessages[index]?.fromName !==
              groupMessages[index + 1]?.fromName
                ? "14px 14px 14px 0px"
                : "14px 14px 14px 14px"
            }
            ml="10px"
          >
            <Text color="#52585D" fontSize="12px">
              {data.payload?.body !== undefined && data.payload?.body}
            </Text>
            <Text color="#212224" fontSize="10px" textAlign="right">
              {/* {data.sendAt.fromNow()} */}
              {moment(data.createdAt).format("HH:mm")}
            </Text>
          </Box>
        )}
      </Flex>
      {groupMessages[index]?.fromName !== groupMessages[index + 1]?.fromName ? (
        <Text
          fontSize="12px"
          paddingTop="8px"
          paddingLeft="48px"
          fontWeight="bold"
        >
          {groupMessages[index]?.fromName}
        </Text>
      ) : null}
    </Flex>
  );
};
const Conversation = ({
  userInfo,
  groupChatId,
  groupData,
  isScreenOpen,
  setIsScreenOpen,
  setParticipantData,
  userProfile,
}) => {
  const [isHeight604] = useMediaQuery("(min-height: 604px)");
  const [isHeight610] = useMediaQuery("(min-height: 610px)");
  const [isHeight720] = useMediaQuery("(min-height: 720px)");
  const [isHeight760] = useMediaQuery("(min-height: 760px)");
  const [isHeight962] = useMediaQuery("(min-height: 962px)");
  const [isHeight1080] = useMediaQuery("(min-height: 1080px)");
  const getComponentSize = () => {
    let size = "425px";
    switch (true) {
      case isHeight1080:
        size = "505px";
        break;
      case isHeight962:
        size = "410px";
        break;
      case isHeight760:
        size = "254px";
        break;
      case isHeight720:
        size = "165px";
        break;
      case isHeight610:
        size = "130px";
        break;
      case isHeight604:
        size = "40px";
        break;
      default:
        size = "40px";
        break;
    }
    return size;
  };
  const componentSize = getComponentSize();
  let lastDate = "1999-01-01";
  let count = 0;
  const [getRecentGroupProfiles] = useLazyGetRecentGroupChatQuery();
  const [groupMessages, setGroupMessages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [queryChat, setQueryChat] = useState("");
  const [messageInput, setMessageInput] = useState({
    mentionedText: "",
    text: "",
    mentionedUsers: [],
  });
  const messageRef = useRef(null);
  const bottomRef = useRef(null);
  const { data: users = [], isLoading: isUsersListLoading } =
    useGetUsersListQuery({ caseId: groupChatId });

  const [addUsersToCase] = useAddMultipleUserToCaseMutation();
  const [
    fetchMessages,
    { loading: isConversationLoading, refetch, data: msgData, error },
  ] = useLazyQuery(FETCH_CONVERSATION);
  const navigate = useNavigate();
  const { data: caseInfo, isLoading } = useGetCaseInfoQuery({
    subClaim: userProfile?.sub,
    caseId: groupChatId,
  });

  useEffect(() => {
    if (msgData && msgData.readChat.success) {
      const totalPages = msgData.readChat?.meta?.totalPages;
      if (totalPages) {
        setTotalPage(totalPages);
      }
      // const receivedData = msgData.readChat.data.map((obj: any) => {
      // 	return { ...obj };
      // });
      // const sortedDesc: any = receivedData.sort(
      // 	(objA: any, objB: any) =>
      // 		objA.createdAt.getTime() - objB.createdAt.getTime()
      // );

      if (pageNumber === 1) setGroupMessages(msgData.readChat.data.reverse());
      else {
        const newData = [...msgData.readChat.data];
        setGroupMessages(newData.reverse().concat(groupMessages));
      }
    }
  }, [msgData]);

  useEffect(() => {
    if (pageNumber > 1) {
      fetchMessages({
        variables: {
          query: {
            filter: {
              toId: groupChatId,
              fromId: userInfo?._id,
            },
            paginate: {
              limit: 25,
              pageNumber,
            },
          },
        },
      });
    }
  }, [pageNumber]);

  useEffect(() => {
    setGroupMessages([]);

    fetchMessages({
      variables: {
        query: {
          filter: {
            toId: groupChatId,
            fromId: userInfo?._id,
          },
          paginate: {
            limit: 25,
            pageNumber: 1,
          },
        },
      },
      fetchPolicy: "no-cache",
    });
    setPageNumber(1);
    setTotalPage(1);
  }, [groupChatId]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    if (pageNumber === 1) bottomRef.current?.scrollIntoView();
  }, [groupMessages]);
  const [sendNewMessage, { error: newMessageError }] =
    useMutation(SEND_MESSAGE);

  const sendMessage = async (e) => {
    e.preventDefault();
    // get userId's
    const ids = messageInput?.mentionedUsers?.map((item) => item.toId);
    const newIds = [...new Set(ids)];
    getRecentGroupProfiles({ userId: userInfo?._id, searchTerm: "" });
    const userIds = users
      ?.filter((user) => newIds.includes(user?.id))
      .map((user) => user.userId);

    const newMessage = messageInput.text.trim();
    if (!newMessage) return;
    setGroupMessages([
      ...groupMessages,
      // {
      //   from: userInfo?._id,
      //   createdAt: moment(),
      //   payload: { body: newMessage },
      //   mentionedUsers: messageInput.mentionedUsers,
      //   fromName: `${userInfo.firstName} ${userInfo.lastName}`,
      // },
    ]);

    e.target.reset();
    setMessageInput({
      mentionedText: "",
      text: "",
      mentionedUsers: [],
    });
    const { data } = await sendNewMessage({
      variables: {
        body: {
          app: "hospital",
          from: userInfo?._id,
          isDeleted: false,
          payload: {
            body: newMessage,
            types: "message",
          },
          to: groupChatId,
          toName: "",
          fromImage: "",
          fromName: `${userInfo.firstName} ${userInfo.lastName}`,
          mentionedUsers: messageInput.mentionedUsers,
        },
      },
    });
    getRecentGroupProfiles({ userId: userInfo?._id, searchTerm: "" });
    refetch();
    if (messageInput?.mentionedUsers?.length > 0) {
      if (userInfo?.userType === "technologist") {
        // find user assigned to case
        const caseAssignedTo = groupData?.caseUsers?.map(
          (userData) => userData?.userId
        );
        // remove assigned user
        const UsersId = userIds
          ? userIds?.filter((id) => id !== caseAssignedTo[0]).map((Id) => Id)
          : [];
        if (UsersId?.length > 0) {
          await addUsersToCase({
            caseId: groupChatId,
            userIds: UsersId,
            subClaim: userProfile?.sub,
          });
        }
      } else {
        await addUsersToCase({
          caseId: groupChatId,
          userIds,
          subClaim: userProfile?.sub,
        });
      }
    }
  };

  // const handleScroll: any = (e: any) => {
  // 	if (isConversationLoading) return;
  // 	const element = e.target;
  // 	if (element.scrollTop === 0) {
  // 		// setIsMessageLoading(true);
  // 		setPageNumber(pageNumber + 1);
  // 	}
  // 	// else if (
  // 	// 	element.scrollTop + element.clientHeight >=
  // 	// 	element.scrollHeight
  // 	// ) {
  // 	// 	setPageNumber(1);
  // 	// }

  // 	// const height = element.scrollHeight - element.clientHeight;
  // 	// const percent = (element.scrollTop / height) * 100;
  // 	// console.log("percent", percent);
  // 	// if (percent <= 30) setIsMessageLoading(true);
  // 	// console.log(
  // 	// 	"percent Processing",
  // 	// 	element.scrollHeight,
  // 	// 	element.clientHeight,
  // 	// 	element.scrollTop + element.clientHeight
  // 	// );
  // };

  const { data: subscribedMessageData } = useSubscription(CHAT_SUBSCRIPTION, {
    variables: {
      toId: groupChatId,
      fromId: userInfo?._id,
    },
  });

  // console.log(subscribedMessageData);

  useEffect(() => {
    if (subscribedMessageData) {
      const newMessages = [
        ...groupMessages,
        subscribedMessageData.newChat.data,
      ];

      setGroupMessages(newMessages);
      getRecentGroupProfiles({ userId: userInfo?._id, searchTerm: "" });
    }
  }, [subscribedMessageData]);
  useEffect(() => {
    setParticipantData(groupMessages);
  }, [msgData, groupMessages]);
  if (isConversationLoading)
    return (
      <Spinner
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%,-50%)"
      />
    );

  // const fetchUsers = (query, callback) => {
  //   if (!query) return;

  //   setTimeout(() => {
  //     const filteredUsers = users.filter((user) =>
  //       user.display.toLowerCase().includes(query)
  //     );
  //     callback(filteredUsers);
  //   }, 2000);
  // };

  const handleInputChange = (e, mentionedText, text, mentions) => {
    const mentionedUsers = mentions.map((mention) => ({
      toId: mention.id,
      toName: mention.display,
      message: messageInput.text,
    }));
    setMessageInput({
      mentionedText,
      text,
      mentionedUsers,
    });
  };

  const handleButtonClick = async (slideValue, caseValue, hashValue) => {
    const resp = await axios.get(
      `${Environment.USER_URL}/slide_details?slideId=${slideValue}`
    );
    if (resp.data) {
      navigate("/viewer", {
        state: {
          caseId: resp?.data.case,
          slide: resp?.data,
          hash: hashValue,
        },
      });
    }
  };

  return (
    <>
      <Flex h="100%">
        <Flex direction="column" h="100%" w={isScreenOpen ? "70%" : "100%"}>
          <ScrollBar>
            <Flex>
              <Box id="message" h="100%" paddingTop="40px" px="22px" w="100%">
                {isConversationLoading && pageNumber > 1 ? (
                  <Spinner />
                ) : (
                  pageNumber < totalPage && (
                    <Flex
                      justifyContent="center"
                      marginTop="-30px"
                      marginBottom="10px"
                    >
                      <Text
                        cursor="pointer"
                        fontSize="12px"
                        onClick={() => setPageNumber(pageNumber + 1)}
                      >
                        Click to load more
                      </Text>
                    </Flex>
                  )
                )}
                {groupMessages.length === 0 && (
                  <Flex
                    h="100%"
                    alignItems="center"
                    justifyContent="center"
                    flexDir="column"
                    gap="0.5rem"
                  >
                    <Text fontSize="20px" fontWeight="700">
                      You&apos;re starting a new conversation
                    </Text>
                    <Text fontSize="14px">Type your first message below</Text>
                  </Flex>
                )}
                <Flex
                  flexDir="column"
                  gap="1rem"
                  // paddingTop={groupMessages.length === 0 ? "0px" : "60vh"}
                >
                  {/* {groupMessages.length > 0 && (
						<Text
							my="10px"
							alignSelf="center"
							fontSize="12px"
							fontWeight="500"
							color="#52585D"
						>
							{moment().calendar(new Date(), formats)}
						</Text>
					)} */}

                  {groupMessages.map((data, index) => {
                    if (
                      lastDate !== moment(data.createdAt).format("MM-DD-YYYY")
                    ) {
                      lastDate = moment(data.createdAt).format("MM-DD-YYYY");
                      return (
                        <>
                          <DateSeperatorComponent
                            messageSepratorDate={data?.createdAt}
                          />
                          {data?.from === userInfo?._id ? (
                            <RightMessageComponent
                              data={data}
                              key={uuidv4()}
                              QueryChat={QueryChat}
                              setQueryChat={setQueryChat}
                              handleButtonClick={handleButtonClick}
                            />
                          ) : (
                            <LeftMessageComponent
                              groupMessages={groupMessages}
                              data={data}
                              key={uuidv4()}
                              index={index}
                              QueryChat={QueryChat}
                              setQueryChat={setQueryChat}
                              handleButtonClick={handleButtonClick}
                            />
                          )}
                        </>
                      );
                    }
                    return data?.from === userInfo?._id ? (
                      <RightMessageComponent
                        data={data}
                        key={uuidv4()}
                        QueryChat={QueryChat}
                        setQueryChat={setQueryChat}
                        handleButtonClick={handleButtonClick}
                      />
                    ) : (
                      <LeftMessageComponent
                        groupMessages={groupMessages}
                        data={data}
                        key={uuidv4()}
                        index={index}
                        QueryChat={QueryChat}
                        setQueryChat={setQueryChat}
                        handleButtonClick={handleButtonClick}
                      />
                    );
                  })}
                  <Box ref={bottomRef} />
                </Flex>
              </Box>
            </Flex>
          </ScrollBar>

          <Box
            // marginTop="auto"
            display="flex"
            h="40px"
            // border="1px solid rgba(150, 169, 186, 0.7)"
            marginLeft="0px!important"
            mr="10px"
            paddingLeft="12px"
            // marginBottom="3px"
          >
            {/* <Flex
					gap="1rem"
					alignItems="center"
					fontSize="20px"
					h="100%"
					color="#3b5d7c"
				>
					<RiAttachment2 />
					<MdOutlineKeyboardVoice />
				</Flex> */}
            <form
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
              onSubmit={sendMessage}
            >
              <MentionsInput
                singleLine
                forceSuggestionsAboveCursor
                allowSuggestionsAboveCursor
                appendSpaceOnAdd
                inputRef={messageRef}
                value={messageInput.mentionedText}
                onChange={handleInputChange}
                // placeholder={"Mention people using '@'"}
                a11ySuggestionsListLabel="Suggested mentions"
                style={defaultStyle}
              >
                <Mention data={users} style={defaultMentionStyle} />
              </MentionsInput>
              <Button
                type="submit"
                bg="light.1006"
                fontSize="16px"
                color="#fff"
                h="100%"
                ml="10px"
                borderRadius="5px"
                _hover={{ bg: "#3b5d7c" }}
                isDisabled={!messageInput.text.trim()}
              >
                Send &nbsp;&nbsp;{" "}
                <span>
                  <AiOutlineSend />
                </span>
              </Button>
            </form>
          </Box>
          {queryChat && (
            <QueryChat
              setQueryChat={setQueryChat}
              queryChat={queryChat}
              userInfo={userInfo}
              caseInfo={groupData}
            />
          )}
        </Flex>

        {isScreenOpen && (
          <Box
            w="30%"
            h="100%"
            style={{
              borderLeft: "1px solid #e0e0e0", // Add a 1px solid border
              boxShadow: "-3px 0px 3px rgba(0, 0, 0, 0.2)", // Add a left-side shadow
            }}
          >
            <Box paddingTop="5px" paddingLeft="23px">
              <HStack justifyContent="space-between" mr="20px">
                <Text fontSize="16px" color="#1B75BC" fontWeight="bold">
                  {caseInfo?.caseId}
                </Text>
                <CloseButton
                  onClick={(e) => {
                    setIsScreenOpen(false);
                  }}
                />
              </HStack>
              <Text fontSize="13px" color="#000000">
                {moment(caseInfo?.caseCreationDate).format(
                  "ddd DD/MM/YYYY HH:mm A"
                )}
                {/* Wed 7/12/2022 1:05 PM */}
                {/* <span style={{ padding: "0 24px" }}>hii</span> */}
              </Text>
            </Box>
            <Box paddingLeft="23px">
              <Text
                color="#212224"
                fontWeight="bold"
                fontSize="15px"
                pb="8px"
                pt="10px"
              >
                CASE DETAILS
              </Text>
              <Text
                fontSize="14px"
                borderBottom="1px solid #DEDEDE"
                w="95%"
                paddingBottom="5px"
                marginBottom="10px"
              >
                Department:
                <span
                  style={{ paddingLeft: "32px", textTransform: "capitalize" }}
                >
                  {caseInfo?.departmentFrom}
                </span>
              </Text>
              <Text
                fontSize="14px"
                borderBottom="1px solid #DEDEDE"
                w="95%"
                paddingBottom="5px"
                marginBottom="10px"
              >
                Organ:
                <span
                  style={{ paddingLeft: "72px", textTransform: "capitalize" }}
                >
                  {caseInfo?.organs ? caseInfo.organs[0].organName : "NA"}
                </span>
              </Text>{" "}
              <Text
                fontSize="14px"
                borderBottom="1px solid #DEDEDE"
                w="95%"
                paddingBottom="5px"
                marginBottom="10px"
              >
                Specimen Size:
                <span
                  style={{ paddingLeft: "16px", textTransform: "capitalize" }}
                >
                  {caseInfo?.organs ? caseInfo.organs[1].organSize : "NA"}
                </span>
              </Text>{" "}
              <Text
                fontSize="14px"
                borderBottom="1px solid #DEDEDE"
                w="95%"
                paddingBottom="5px"
                marginBottom="10px"
              >
                Hospital:
                <span
                  style={{ paddingLeft: "59px", textTransform: "capitalize" }}
                >
                  {caseInfo?.treatingHospitalDetails
                    ? caseInfo?.treatingHospitalDetails.hospitalName
                    : "NA"}
                </span>
              </Text>{" "}
              <Text
                fontSize="14px"
                borderBottom="1px solid #DEDEDE"
                w="95%"
                paddingBottom="5px"
                marginBottom="10px"
              >
                Clinician:
                <span
                  style={{ paddingLeft: "59px", textTransform: "capitalize" }}
                >
                  Dr. {caseInfo?.treatingDoctor}
                </span>
              </Text>{" "}
              <Text
                fontSize="14px"
                borderBottom="1px solid #DEDEDE"
                w="95%"
                paddingBottom="5px"
                marginBottom="10px"
              >
                {`Clinician's No:`}
                <span style={{ paddingLeft: "26px" }}>
                  {caseInfo?.treatingHospitalDetails?.hospitalHelpline
                    ? caseInfo?.treatingHospitalDetails?.hospitalHelpline
                    : "NA"}
                </span>
              </Text>{" "}
              {/* <Text
              fontSize="14px"
              borderBottom="1px solid #DEDEDE"
              w="95%"
              paddingBottom="5px"
              marginBottom="10px"
            >
              Refer Case To:
              <span style={{ paddingLeft: "24px" }}>Dr. Siddhesh Kale </span>
            </Text> */}
            </Box>
            <Box direction="column" h={componentSize}>
              <Text
                color="#212224"
                fontWeight="bold"
                fontSize="15px"
                pb="8px"
                pt="10px"
                paddingLeft="23px"
              >
                ACTIVE QUERIES
              </Text>
              <ScrollBar>
                {groupMessages.map((data, index) => {
                  if (data?.mentionedUsers?.length > 0) {
                    count += 1;
                  }
                  return (
                    data?.mentionedUsers?.length > 0 && (
                      <Box
                        key={uuidv4()}
                        p="10px"
                        bg="rgba(176, 200, 214, 0.15)"
                        onClick={() => setQueryChat(data)}
                        cursor="pointer"
                        alignSelf="flex-start"
                        w="90%"
                        borderRadius="0"
                        ml="23px"
                        mb="5px"
                      >
                        <Flex
                          color="rgba(59, 93, 124, 1)"
                          fontSize="14px"
                          justifyContent="space-between"
                        >
                          <Flex fontWeight="bold">
                            <Text>
                              QUERY {`#${count.toString().padStart(3, "0")}`}
                            </Text>
                            <Text paddingLeft="12px">{`Dr.${data.mentionedUsers[0].toName}`}</Text>
                          </Flex>
                          <Text color="rgba(33, 34, 36, 1)">
                            {/* {data.sendAt.fromNow()} */}
                            {moment(data.createdAt).format("HH:mm")}
                          </Text>
                        </Flex>
                        <Flex
                          justifyContent="space-between"
                          color="rgba(33, 34, 36, 1)"
                        >
                          <Text fontSize="13px" fontWeight="bold">
                            {data.payload?.body !== undefined &&
                              data.payload?.body}
                          </Text>
                          {!data?.annotation?.hash && (
                            <AiOutlineRight size="13px" />
                          )}
                        </Flex>
                        {data?.annotation?.hash && (
                          <Flex justifyContent="space-between">
                            <Text
                              color="rgba(59, 93, 124, 1)"
                              fontSize="13px"
                              fontWeight="bold"
                              onClick={() =>
                                handleButtonClick(
                                  data.slideId,
                                  data.caseId,
                                  data.annotation.hash
                                )
                              }
                              textDecor="underline"
                              textUnderlineOffset="5px"
                            >
                              open slide region
                            </Text>
                            <AiOutlineRight size="13px" />
                          </Flex>
                        )}
                      </Box>
                    )
                  );
                })}
              </ScrollBar>
            </Box>
          </Box>
        )}
      </Flex>
      {/* <Box h="5px" /> */}
    </>
  );
};

export default Conversation;
