import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../Loading/loading";
import {
  useGetUserInfoQuery,
  useGetCaseInfoQuery,
  useGetUsersListQuery,
} from "../../state/API/HospitalApi";

const ViewerRedirect = () => {
  const { user } = useAuth0();
  const location = useLocation();
  const { caseId, slide, hash } = location.state;
  const { data: userInfo } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user?.sub }
  );

  const { data: caseInfo, isLoading } = useGetCaseInfoQuery({
    subClaim: user?.sub,
    caseId,
  });

  const { data: mentionUsers = [], isLoading: isUsersListLoading } =
    useGetUsersListQuery("");

  return isLoading || isUsersListLoading ? (
    <Loading />
  ) : (
    <Navigate
      to={`/viewer/${caseInfo?._id}`}
      replace
      state={{
        userInfo,
        caseInfo,
        slide,
        hash,
        mentionUsers,
      }}
    />
  );
};

export default ViewerRedirect;
