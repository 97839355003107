/* eslint-disable react/no-children-prop */
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  useBoolean,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  IconButton,
  HStack,
  Text,
  Stack,
  Box,
  Radio,
  RadioGroup,
  MenuOptionGroup,
  MenuItemOption,
} from "@chakra-ui/react";
import { FiFilter } from "react-icons/fi";
import { MdAddCircleOutline } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { SearchIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useGetOrgRolesQuery } from "../state/API/HospitalApi";
import ViewerPermission from "./ViewerPermission";

const MembersList = ({
  orgDetails,
  userInf,
  setSearchUser,
  searchUser,
  statSelect,
  ...restProps
}) => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [flag, setFlag] = useBoolean();
  const [TilHover, setTilHover] = useState(false);
  const [buttonOff, setButtonOff] = useState(true);
  const [buttonOffNext, setButtonOffNext] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [itemOffset, setItemOffSet] = useState(0);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [number, setNumber] = useState(0);
  const [list, setList] = useState(currentItems);
  const [radioButton, setRadioButton] = useState("All");
  const data = orgDetails?.users === undefined ? [] : orgDetails.users;
  const itemsPerPage = 15;
  let srNo = 0;
  const { data: rolesInfo } = useGetOrgRolesQuery(
    {
      organizationId: userInf?.organization?._id,
    },
    { skip: !userInf }
  );

  useEffect(() => {
    setFilterList(rolesInfo);
  }, [rolesInfo]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    if (
      (radioButton === "Technologist" || radioButton === "Pathologist") &&
      pageCount * itemsPerPage < endOffset
    ) {
      setItemOffSet(0);
      setButtonOff(true);
    }
    if (endOffset === pageCount * itemsPerPage) {
      setButtonOffNext(true);
    } else {
      setButtonOffNext(false);
    }

    setCurrentItems(
      [...data]
        .reverse()
        .filter((userData) => {
          if (
            userData.userType === radioButton.toLowerCase() ||
            radioButton === "All"
          ) {
            return userData;
          }
          return null;
        })
        .slice(itemOffset, endOffset)
    );
    setPageCount(
      Math.ceil(
        [...data].filter((userData) => {
          if (
            userData.userType === radioButton.toLowerCase() ||
            radioButton === "All"
          ) {
            return userData;
          }
          return null;
        }).length / itemsPerPage
      )
    );
    setNumber(itemOffset);
    // console.log(pageCount);
  }, [itemOffset, itemsPerPage, data, radioButton, pageCount]);

  useEffect(() => {
    if (searchUser === "") {
      setList(currentItems);
      setSearchEnabled(false);
    } else {
      setList(data);
      setSearchEnabled(true);
    }
  }, [searchUser, currentItems, searchEnabled, radioButton, pageCount]);

  const handlePageClick = (event) => {
    const newOffSet =
      ((event.selected > pageCount ? pageCount : event.selected) *
        itemsPerPage) %
      data.length;
    if (newOffSet === 0) {
      setButtonOff(true);
    } else {
      setButtonOff(false);
    }
    setItemOffSet(newOffSet);
  };

  return (
    <Flex direction="column" w="100%" pb="29px">
      <Flex
        w="100%"
        h={ifSmallScreen ? "calc(100vh - 84px)" : "calc(100vh - 9.06vh)"}
        bgColor="#fff"
        p="20px"
        direction="column"
        {...restProps}
      >
        <HStack w="100%" h="7%" mb="2%">
          <Text
            minW="140px"
            color="light.1006"
            fontWeight="600"
            fontSize="21px"
          >
            All Users
          </Text>
          <Flex w="100%">
            <InputGroup w="30%">
              <InputLeftElement h={8} w={5} marginLeft={3}>
                <IconButton
                  variant="link"
                  padding={0}
                  h={5}
                  w={5}
                  bgColor="white"
                  icon={<SearchIcon color="gray.300" />}
                  _hover={{ cursor: "auto" }}
                />
              </InputLeftElement>
              <Input
                maxLength={65}
                placeholder="Search Users"
                h={34.5}
                border="2px solid #3B5D7C"
                borderRadius={2}
                bgColor="#fff"
                onChange={(e) => setSearchUser(e.target.value)}
                _focus={{
                  outline: "none",
                  border: "1px solid #e8e8e8",
                }}
                _hover={{
                  outline: "none",
                  border: "1px solid #e8e8e8",
                }}
                // outline={TilHover ? "1px solid rgba(0, 21, 63, 1)" : ""}
                onClick={() => {
                  setTilHover(!TilHover);
                }}
              />
            </InputGroup>
          </Flex>

          <Menu>
            <MenuButton
              w="105px"
              h="35px"
              _focus={{ outline: "none" }}
              borderRadius="10px"
              bgColor="inherit"
              color="#000"
              border="1px solid #E8E8E8"
            >
              <HStack w="100%" justifyContent="center">
                <MdAddCircleOutline />
                <Text>Add</Text>
              </HStack>
            </MenuButton>
            <MenuList
              borderRadius="5px"
              p={0}
              minWidth="138px"
              css={{
                transform: "translate3d(0px, -8px, 0px)!important;",
              }}
              mt="10px"
            >
              <MenuItem onClick={() => navigate("/dashboard/add-new-user", {})}>
                New User
              </MenuItem>
              <Flex justifyContent="center" alignItems="center">
                <Flex bgColor="#DEDEDE" width="94%" h="0.5px" />
              </Flex>
              <MenuItem onClick={() => navigate("/dashboard/add-new-role")}>
                New role
              </MenuItem>
            </MenuList>
          </Menu>

          <Menu closeOnSelect={false}>
            <MenuButton
              w="135px"
              h="35px"
              _focus={{ outline: "none" }}
              borderRadius="10px"
              bgColor="inherit"
              color="#000"
              border="1px solid #E8E8E8"
            >
              <HStack
                w="100%"
                justifyContent="space-between"
                spacing={0}
                p="0 5px"
              >
                <HStack w="80%" spacing={1}>
                  <FiFilter />
                  <Text>Filter</Text>
                </HStack>
                <ChevronDownIcon />
              </HStack>
            </MenuButton>
            <MenuList
              borderRadius="5px"
              minWidth="135px"
              p={0}
              css={{
                transform: "translate3d(0px, -8px, 0px)!important;",
              }}
              mt="10px"
            >
              <MenuOptionGroup
                type="checkbox"
                onChange={(e) => setFilterList(e)}
              >
                {rolesInfo?.map((role) => {
                  return (
                    <MenuItemOption key={role} value={role}>
                      {role}
                    </MenuItemOption>
                  );
                })}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </HStack>

        <Flex
          w="100%"
          bgColor="light.800"
          minH="50px"
          alignItems="center"
          ps="2%"
          fontSize="14px"
          fontWeight={600}
        >
          <Flex minW="7%">Sr No.</Flex>
          <Flex minW="20%">Name</Flex>
          <Flex minW="13%">Role</Flex>
          <Flex minW="15%">Enroll date</Flex>
          <Flex minW="20%">Email</Flex>
          <Flex minW="13%">Contact</Flex>
          <Flex minW="13%">Hospital</Flex>
        </Flex>
        <Flex
          direction="column"
          overflow="scroll"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
            },
          }}
        >
          {list
            ?.filter((user) => {
              if (searchUser === "") {
                return user;
              }
              if (
                user?.firstName
                  ?.toLowerCase()
                  .includes(searchUser.toLowerCase()) ||
                user?.lastName
                  .toLowerCase()
                  .includes(searchUser.toLowerCase()) ||
                user?.emailAddress
                  .toLowerCase()
                  .includes(searchUser.toLowerCase()) ||
                user?.phoneNumber
                  ?.toString()
                  .indexOf(searchUser?.toString()) !== -1
              ) {
                return user;
              }
              return null;
            })
            .filter((userData) => {
              // console.log(userData.userType);
              if (filterList?.includes(userData?.role)) {
                return userData;
              }
              return null;
            })
            .map((userInfo) => {
              if (userInfo?.userType !== "admin") srNo += 1;
              return userInfo?.userType !== "admin" ? (
                <Flex direction="column" w="100%">
                  <Flex
                    w="100%"
                    minH="48px"
                    alignItems="center"
                    ps="2%"
                    fontSize="14px"
                    key={userInfo._id}
                  >
                    <Flex minW="7%">
                      {number === 0 || searchEnabled === true
                        ? srNo
                        : srNo + number}
                    </Flex>
                    <Flex minW="20%" wordBreak="break-word">
                      {userInfo.userType === "pathologist" && "Dr. "}
                      {userInfo.firstName.charAt(0).toUpperCase() +
                        userInfo.firstName.slice(1)}{" "}
                      {userInfo.lastName.charAt(0).toUpperCase() +
                        userInfo.lastName.slice(1)}
                    </Flex>
                    <Flex minW="13%" wordBreak="break-word">
                      {userInfo.role !== undefined
                        ? userInfo.role.charAt(0).toUpperCase() +
                          userInfo.role.slice(1)
                        : userInfo.userType.charAt(0).toUpperCase() +
                          userInfo.userType.slice(1)}
                    </Flex>
                    <Flex minW="15%" wordBreak="break-word">
                      {userInfo.enrollDate
                        ? userInfo.enrollDate.substring(0, 10)
                        : ""}
                    </Flex>
                    <Flex
                      minW="20%"
                      wordBreak="break-word"
                      pr={ifSmallScreen ? "2%" : "0"}
                    >
                      {userInfo.emailAddress}
                    </Flex>
                    <Flex minW="13%" wordBreak="break-word">
                      {userInfo.phoneNumber}
                    </Flex>
                    <Flex w="13%">
                      {userInfo.hospital !== undefined ? userInfo.hospital : ""}
                    </Flex>
                  </Flex>
                  <Box
                    width="100%"
                    bgColor="#AEAEAE"
                    height="0.2px"
                    marginBottom="2px"
                  />
                </Flex>
              ) : null;
            })}
          <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize="20px" fontWeight="500">
                Viewer Permissions
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody px="0" alignItems="center">
                <ViewerPermission onClose={onClose} />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
      </Flex>
      <Box
        css={
          searchUser === ""
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        {radioButton === "Technologist" && (
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            forcePage={
              itemOffset + 1 > pageCount * itemsPerPage
                ? 0
                : itemOffset / itemsPerPage
            }
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName={buttonOff ? "page-num-disable" : "page-num"}
            nextLinkClassName={
              buttonOffNext || pageCount === 1 ? "page-num-disable" : "page-num"
            }
            activeLinkClassName="active"
          />
        )}
        {radioButton === "All" && (
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            forcePage={
              itemOffset + 1 > pageCount * itemsPerPage
                ? 0
                : itemOffset / itemsPerPage
            }
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName={buttonOff ? "page-num-disable" : "page-num"}
            nextLinkClassName={
              buttonOffNext || pageCount === 1 ? "page-num-disable" : "page-num"
            }
            activeLinkClassName="active"
          />
        )}
        {radioButton === "Pathologist" && (
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            forcePage={
              itemOffset + 1 > pageCount * itemsPerPage
                ? 0
                : itemOffset / itemsPerPage
            }
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName={buttonOff ? "page-num-disable" : "page-num"}
            nextLinkClassName={
              buttonOffNext || pageCount === 1 ? "page-num-disable" : "page-num"
            }
            activeLinkClassName="active"
          />
        )}
      </Box>
    </Flex>
  );
};

export default MembersList;
