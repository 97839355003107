import React from "react";
import { Text, HStack, VStack, useMediaQuery } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetCaseInfoQuery } from "../state/API/HospitalApi";

const CaseDetails = ({ caseInfoData }) => {
  const { user } = useAuth0();
  const { data: caseInfo } = useGetCaseInfoQuery({
    caseId: caseInfoData?._id,
    subClaim: user?.sub,
  });
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  return (
    <VStack
      fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
      py="1.25vh"
      px="0.937vw"
      alignItems="flex-start"
      background="light.100"
      my="1vh"
      mb="28px"
      boxShadow="1px 1px 2px rgba(176, 200, 214, 0.1)"
    >
      <Text fontWeight={700}>Case Details</Text>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5206vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Department:
        </Text>
        <Text minW="55%" maxW="55%">
          {caseInfo?.departmentFrom}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Organ:
        </Text>
        <Text minW="55%" maxW="55%">
          {caseInfo?.organs[0]?.organName}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Specimen Size:
        </Text>
        <Text minW="55%" maxW="55%">
          {caseInfo?.organs[1]?.organSize}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Hospital:
        </Text>
        <Text minW="55%" maxW="55%">
          {caseInfo?.treatingHospitalDetails?.hospitalName}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Clinician:
        </Text>
        <Text minW="55%" maxW="55%" textTransform="capitalize">
          Dr. {caseInfo?.treatingDoctor}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Helpline No:
        </Text>
        <Text minW="55%" maxW="55%">
          {caseInfo?.treatingHospitalDetails?.hospitalHelpline
            ? caseInfo?.treatingHospitalDetails?.hospitalHelpline
            : "-"}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Case id:
        </Text>
        <Text minW="55%" maxW="55%">
          {caseInfo?.caseId ? caseInfo?.caseId : "NA"}
        </Text>
      </HStack>
      <Text fontWeight={700} pt="1.5vh">
        Patient Details
      </Text>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Patient Name:
        </Text>
        <Text minW="55%" maxW="55%">
          {`${caseInfo?.patient?.patientName?.firstName}${caseInfo?.patient?.patientName?.lastName}`}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Gender:
        </Text>
        <Text minW="55%" maxW="55%">
          {caseInfo?.patient?.gender ? caseInfo?.patient?.gender : "-"}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Age:
        </Text>
        <Text minW="40%" maxW="40%">
          {caseInfo?.patient?.age?.years ? caseInfo?.patient?.age?.years : "-"}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Contact Number:
        </Text>
        <Text minW="55%" maxW="55%">
          {caseInfo?.patient?.contactNumber
            ? caseInfo?.patient?.contactNumber
            : "-"}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          Address:
        </Text>
        <Text minW="55%" maxW="55%">
          {caseInfo?.patient?.patientAddress
            ? caseInfo?.patient?.patientAddress
            : "-"}
        </Text>
      </HStack>
      <HStack
        w="100%"
        borderBottom="1px solid rgba(0,0,0,0.2)"
        minH="2.0812vh"
        px="0.5208vw"
        pb="0.5.740vh"
        pt="1.11vh"
      >
        <Text minW="20%" maxW="20%">
          UHID:
        </Text>
        <Text minW="55%" maxW="55%">
          {caseInfo?.patient?.uhid ? caseInfo?.patient?.uhid : "-"}
        </Text>
      </HStack>
    </VStack>
  );
};

export default CaseDetails;
