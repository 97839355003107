import { useAuth0 } from "@auth0/auth0-react";
import { Flex, useDisclosure } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Loading from "../Components/Loading/loading";
import useUserAuthentication from "../hooks/useUserAuthentication";
import { useGetUserInfoQuery } from "../state/API/HospitalApi";
import NewCase from "./NewCase";

import Header from "../DashboardLayout/Header";

const CreateCase = () => {
  const { user } = useAuth0();
  const { data: userInfo, isLoading } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user?.sub }
  );
  const isUserAuthenticated = useUserAuthentication();

  const [searchCase, setSearchCase] = useState("");
  const [leftPanelToggle, setLeftPanelToggle] = useState(
    localStorage.getItem("leftPanelToggle")
  );

  const [caseInfoData, setCaseInfoData] = useState(
    localStorage.getItem("caseInfoData")
  );

  useEffect(() => {
    localStorage.setItem("leftPanelToggle", leftPanelToggle);
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [leftPanelToggle, caseInfoData]);
  return !isUserAuthenticated || isLoading ? (
    <Loading />
  ) : (
    <Flex flexDir="column" w="100vw" h="100vh" overflow="hidden">
      <Header userInfo={userInfo} setSearchCase={setSearchCase} />
      <Flex w="100vw" alignItems="flex-start" alignContent="flex-start">
        <Flex w="100%" flexDirection="column">
          <Flex flexDir="column">
            <NewCase userInfo={userInfo} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreateCase;
