import React, { useEffect } from "react";
import { CloseIcon, CheckIcon } from "@chakra-ui/icons";

const RangeFilterMenu = ({
  rangeFilterOptions,
  selectedRange,
  handleRangeFilterChange,
  hideMenu,
  setFilterChecked,
  setPageSize,
  data,
  hideScrollbar,
  setHideScrollBar,
  organ,
  handleFilterClick,
  handleClearFilter,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".filter-menu")) {
        return;
      }
      hideMenu();
      setHideScrollBar(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideMenu]);

  const menuStyle = {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    padding: "8px", // Adjusted padding
    width: "120px", // Adjusted width
  };

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    margin: "4px 0", // Adjusted margin
    cursor: "pointer",
    fontWeight: "normal",
  };

  const inputStyle = {
    position: "absolute",
    opacity: 0,
  };

  const customRadioStyle = {
    width: "14px",
    height: "14px",
    border: "1px solid #ccc",
    borderRadius: "2px",
    marginRight: "10px", // Adjusted space between checkbox and text
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    background: selectedRange === "All" ? "#007bff" : "white",
  };

  const textLabelStyle = {
    flex: 1, // Take available space
    marginLeft: "20px", // Add space between checkbox and text
    fontWeight: "normal",
    display: "flex", // Align items horizontally
    alignItems: "center", // Center items vertically
  };

  const closeButtonStyle = {
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "4px 8px", // Adjusted padding
    cursor: "pointer",
  };

  const listStyle = {
    listStyle: "none", // Remove bullet points
    padding: 0,
  };

  return (
    <div className="filter-menu" style={menuStyle}>
      <ul style={listStyle}>
        {rangeFilterOptions.map((option) => (
          <li key={option.label}>
            <label
              htmlFor={`slideRange-${option.value}`}
              style={{
                ...labelStyle,
                fontWeight: selectedRange === option.value ? "bold" : "normal",
              }}
            >
              <input
                type="radio"
                id={`slideRange-${option.value}`}
                name="slideRange"
                value={option.value}
                onChange={() => {
                  if (option.value === "0-0") {
                    // setPageSize(10);
                    // setFilterChecked(false);
                    handleRangeFilterChange(option.value);
                  } else {
                    handleRangeFilterChange(option.value);
                    handleFilterClick(organ);
                  }
                }}
                checked={selectedRange === option.value}
                style={inputStyle}
              />
              <span style={{ ...customRadioStyle }}>
                {selectedRange === option.value && <CheckIcon color="green" />}{" "}
                {/* Render the check icon */}
              </span>
              <span
                style={{
                  ...textLabelStyle,
                  fontWeight:
                    selectedRange === option.value ? "bold" : "normal",
                }}
              >
                {option.label}
              </span>
            </label>
          </li>
        ))}
      </ul>
      <button
        onClick={(e) => {
          hideMenu();
          // setPageSize(10);
          // setFilterChecked(false);
          setHideScrollBar(false);
          handleRangeFilterChange(null);
          handleClearFilter("Slides");
        }}
        type="button"
        style={{
          marginLeft: "40px",
          padding: "3px 5px",
          backgroundColor: "#ccc",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "13px",
        }}
      >
        Clear
      </button>
    </div>
  );
};
export default RangeFilterMenu;
