import React, { useState } from "react";
import {
  Input,
  useDisclosure,
  Flex,
  Text,
  Image,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import ChatAdd from "../../Static/Images/chatAdd.svg";
import ChatSearch from "../../Static/Images/chatSearch.svg";
import ChatStar from "../../Static/Images/chatStar.svg";
import ChatModal from "./ChatModal";

const ChatHeader = ({
  setGroupData,
  setActiveGroup,
  setSearchName,
  setSearchCase,
  index,
  setIndex,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  const handleSearchTerm = (query) => {
    setSearchQuery(query);
    if (index === 0) {
      setSearchName(query);
    } else {
      setSearchCase(query);
    }
  };

  const closeModal = () => {
    setSelectedOption(null);
  };
  return (
    <Flex
      w="100%"
      h="61px"
      justify="space-between"
      fontSize="18px"
      fontWeight="500"
      align="center"
      px="23px"
      bg="white"
    >
      <Text color="light.1006" fontWeight="bold" fontSize="16px">
        Chats
      </Text>
      <Flex justifyContent="flex-end">
        {/* <Menu>
          <MenuButton
            as={IconButton}
            mr="5px"
            colorScheme="#F4F4F4"
            icon={<Image src={ChatSearch} h="15px" w="15px" />}
          />
          <MenuList
            minWidth="200px"
            fontSize="12px"
            fontWeight="bold"
            color="black.1000"
          >
            <Input
              minHeight="40px"
              value={searchQuery}
              onChange={(e) => handleSearchTerm(e.target.value)}
              placeholder="Search here..."
              border="0px"
            />
          </MenuList>
        </Menu>{" "} */}
        <InputGroup marginLeft="5px">
          <Input
            mt="6px"
            value={searchQuery}
            onChange={(e) => handleSearchTerm(e.target.value)}
            placeholder="Search here..."
            h="30px"
            fontSize="16px"
            border="1px solid #F6F6F6"
            _focus={{ border: "1px solid #F6F6F6" }}
          />
          <InputRightElement paddingTop="2px">
            <Image src={ChatSearch} h="16px" />
          </InputRightElement>
        </InputGroup>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<Image src={ChatAdd} h="24px" w="24px" />}
            colorScheme="#F4F4F4"
          />
          <MenuList
            minWidth="120px"
            fontSize="13px"
            fontWeight="bold"
            color="black.1000"
            border="0px"
          >
            <MenuItem
              onClick={() => {
                setIndex(0);
                handleOptionClick(1);
              }}
            >
              New Chat
            </MenuItem>
            <MenuItem
              onClick={() => {
                setIndex(1);
                handleOptionClick(2);
              }}
            >
              New Group
            </MenuItem>
          </MenuList>
        </Menu>
        {selectedOption && (
          <ChatModal
            isOpen
            onClose={closeModal}
            optionData={selectedOption}
            setActiveGroup={setActiveGroup}
            setGroupData={setGroupData}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ChatHeader;
