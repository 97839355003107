/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState } from "react";
import {
  Flex,
  VStack,
  Text,
  Textarea,
  HStack,
  Input,
  Select,
} from "@chakra-ui/react";
import { BsFillCircleFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";

const RoleBox = ({
  userType,
  setSelectedRole,
  roleName,
  selectedRole,
  roleCount,
  index,
}) => {
  const colorArr = ["#D06768", "#FED89B", "#CFE2F1"];
  const [colorRandomArr, setColorRandomArr] = useState(
    Array.from({ length: roleCount }, () => Math.floor(Math.random() * 3))
  );
  return (
    <Flex
      borderRadius="10px"
      border={
        selectedRole === roleName ? "2px solid #1B75BC" : "1px solid #e6e6e6"
      }
      h="100%"
      w="15%"
      justifyContent="center"
      alignItems="center"
      p={2}
      mr={2}
      _hover={{ transform: "scale(1.05)" }}
      transform={selectedRole === roleName ? "scale(1.05)" : null}
      minW="180px"
      onClick={() => setSelectedRole(roleName)}
    >
      <VStack>
        <FaUserAlt size={100} color={colorArr[colorRandomArr[index]]} />
        <Text>{userType}</Text>
      </VStack>
    </Flex>
  );
};

const PersonalDetails = ({
  setIndex,
  setInputData,
  inputData,
  rolesInfo,
  selectedRole,
  setSelectedRole,
}) => {
  const handleInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  return (
    <Flex mt="15px" direction="column">
      <HStack spacing="26px" w="100%" justifyContent="center">
        <BsFillCircleFill
          size={17}
          color="rgba(27, 117, 188, 0.43"
          style={{ cursor: "pointer" }}
        />
        <BsFillCircleFill
          size={17}
          color="#D9D9D9"
          style={{ cursor: "pointer" }}
          onClick={() => setIndex(2)}
        />
      </HStack>

      <VStack alignItems="left" marginTop="15px">
        <Text>Role*</Text>
        <Flex
          border="1px solid #E6E6E6"
          borderRadius="10px"
          minH="200px"
          w="100%"
          justifyContent="center"
          alignItems="center"
          p={2}
          // overflow="scroll"
        >
          {rolesInfo?.length === 0 ? (
            <Text>
              No roles present at the moment. Please create one before
              proceeding
            </Text>
          ) : (
            rolesInfo?.map((role, index) => {
              return (
                <RoleBox
                  userType={role}
                  key={role}
                  setSelectedRole={setSelectedRole}
                  roleName={role}
                  selectedRole={selectedRole}
                  roleCount={rolesInfo.length}
                  index={index}
                />
              );
            })
          )}
        </Flex>
      </VStack>
      <Text
        fontSize="11px"
        color="light.1006"
        _hover={{ textDecoration: "underline", cursor: "pointer" }}
        mt="3px"
        onClick={() => navigate("/dashboard/add-new-role")}
        w="295px"
      >
        Unable to find the required role? Create a new role first
      </Text>
      <Flex direction="column" mt="2.5vh">
        <Text fontSize="20px" mb="10px">
          Personal Details
        </Text>
        <HStack w="100%" justifyContent="space-between">
          <VStack alignItems="start" spacing={1} w="50%">
            <Text fontSize="14px">First Name*</Text>
            <Input
              w="90%"
              placeholder="Enter first name here"
              name="firstName"
              value={inputData.firstName}
              onChange={handleInput}
            />
          </VStack>
          <VStack alignItems="flex-end" spacing={1} w="50%">
            <Text fontSize="14px" w="90%">
              Last Name*
            </Text>
            <Input
              w="90%"
              placeholder="Enter last name here"
              name="lastName"
              value={inputData.lastName}
              onChange={handleInput}
            />
          </VStack>
        </HStack>
        <HStack w="100%" justifyContent="space-between" mt="7vh">
          <HStack w="50%" spacing={0}>
            <VStack alignItems="start" spacing={1} w="50%">
              <Text fontSize="14px" w="70%">
                Date of Birth
              </Text>
              <Input
                w="80%"
                type="date"
                id="age"
                max="9999-12-31"
                onWheel={(e) => e.target.blur()}
                maxLength="2"
                name="dob"
                value={inputData.dob}
                onChange={handleInput}
              />
            </VStack>
            <VStack alignItems="start" spacing={1} w="50%">
              <Text fontSize="14px" w="70%">
                gender*
              </Text>
              <Select
                id="gender"
                name="gender"
                w="80%"
                required
                value={inputData.gender}
                onChange={handleInput}
              >
                <option selected hidden fontSize={14}>
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="nonbinary">Non-binary</option>
              </Select>
            </VStack>
          </HStack>
          <VStack alignItems="flex-end" spacing={1} w="50%">
            <Text fontSize="14px" w="90%">
              Contact Number*
            </Text>

            <PhoneInput
              country="us"
              inputProps={{
                name: "patientContact",
                required: true,
                autoFocus: true,
                id: "contact_number",
              }}
              enableSearch="true"
              placeholder="Enter phone number"
              onChange={(country, formattedValue, value, e) => {
                setInputData({
                  ...inputData,
                  contact: e,
                });
              }}
              style={{
                width: "90%",
                backgroundColor: "pink",
              }}
              // buttonStyle={{ height: "3.6vh", minHeight: "33px" }}
              containerStyle={{
                border: "10px solid black",
              }}
              inputStyle={{ width: "100%" }}
            />
          </VStack>
        </HStack>

        <HStack
          w="100%"
          justifyContent="space-between"
          mt="7vh"
          alignItems="start"
        >
          <VStack alignItems="start" spacing={1} w="50%">
            <Text fontSize="14px">Address</Text>
            <Textarea
              w="90%"
              name="address"
              onChange={handleInput}
              value={inputData.address}
            />
          </VStack>
          <VStack alignItems="flex-end" spacing={1} w="50%">
            <Text fontSize="14px" w="90%">
              Email*
            </Text>
            <Input
              w="90%"
              placeholder="Enter text here"
              type="email"
              name="emailAddress"
              value={inputData.emailAddress}
              onChange={handleInput}
            />
          </VStack>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default PersonalDetails;
