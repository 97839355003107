import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Case from "../Case/Case";
import Login from "../Components/Authenticate/Login";
import UserDetails from "../Components/Authenticate/userDetails";
import DashboardRedirect from "../Components/DashboardRedirect/DashboardRedirect";
import ViewerRedirect from "../Components/Viewer/ViewerRedirect";
import OSDViewer from "../Components/Viewer/OSDViewer";
import CreateCase from "../NewCase/CreateCase";
import AppContainer from "../AppContainer";
import PublicRoute from "./PublicRoute";
import roles from "../configs/role.config";
import { PageNotFound } from "../Components/ErrorPages";
import AdminDashboard from "../DashboardLayout/AdminDashboard";
import AddMemberDashboard from "../DashboardLayout/AddMember/AddmemberDashboard";
import AddRoles from "../DashboardLayout/AddMember/AddRoles";
import RestrictedRoute from "./RestrictedRoute";
import { DashboardLayout } from "../Components/Dashboard";
import Chat from "../Features/Chats/Chat";
import CaseInfoScreen from "../Components/NewDashboard/CaseInfoScreen";
import { ChatProvider } from "../Components/ChatApplication";

const RouterConfig = () => {
  return (
    <Routes>
      <Route path="/:id/case/:caseId" element={<Case />} />

      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        path="/registration"
        element={
          <RestrictedRoute>
            <UserDetails />
          </RestrictedRoute>
        }
      />

      <Route element={<DashboardLayout />}>
        <Route
          path="/dashboard/admin"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/pathologist"
          element={
            <ProtectedRoute allowedRoles={[roles.Pathologist]}>
              <ChatProvider>
                <AppContainer />
              </ChatProvider>
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/add-new-user"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <AddMemberDashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/add-new-role"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <AddRoles />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/case"
          element={
            <ProtectedRoute allowedRoles={[roles.Pathologist]}>
              <CaseInfoScreen />
            </ProtectedRoute>
          }
        />

        <Route
          index
          element={
            <ProtectedRoute
              allowedRoles={[
                roles.Admin,
                roles.Pathologist,
                roles.Technologist,
              ]}
            >
              <DashboardRedirect />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route
        path="/chats"
        element={
          <ChatProvider>
            <Chat />
          </ChatProvider>
        }
      />

      <Route
        path="/dashboard/technologist"
        element={
          <ProtectedRoute allowedRoles={[roles.Technologist]}>
            <ChatProvider>
              <AppContainer />
            </ChatProvider>
          </ProtectedRoute>
        }
      />

      <Route path="/create-case" element={<CreateCase />} />

      <Route path="/viewer" element={<ViewerRedirect />} />

      <Route path="/viewer/:caseName" element={<OSDViewer />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouterConfig;
