import { Flex, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const Boxes = ({
  heading,
  number,
  headingColor,
  statColor,
  background,
  setStatSelect,
}) => {
  const [ifWidthLessthan1600] = useMediaQuery("(max-width:1600px)");
  return (
    <Flex
      w="100%"
      bgColor={background}
      p={ifWidthLessthan1600 ? "14px" : "18px"}
      borderRadius="15px"
      mt="3%"
      onClick={() =>
        setStatSelect(heading === "Total Users" ? "All users" : heading)
      }
      cursor="pointer"
    >
      <Flex direction="column" w="80%" h="100%">
        <Text
          fontSize={ifWidthLessthan1600 ? "16px" : "20px"}
          color={headingColor}
          h="25%"
        >
          {heading}
        </Text>
        <Flex>
          <Text
            h="75%"
            fontSize="8vh"
            fontWeight="600"
            color={statColor}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {number}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const DashboardStatistics = ({ orgDetails, setStatSelect, ...restProps }) => {
  const roles = orgDetails?.roleCounts
    ? Object.entries(orgDetails?.roleCounts)
    : null;
  const background = ["#F8F2F0", "#FEF4FE", "#F6F4FE"];
  const heading = ["rgba(208, 103, 104, 0.85)", "#B451B4", "#1B75BC"];
  const stat = ["#D06768", "#B451B4", "#1B75BC"];
  const [colorRandomArr, setColorRandomArr] = useState(
    Array.from({ length: roles?.length }, () => Math.floor(Math.random() * 3))
  );
  useEffect(
    () => [
      setColorRandomArr(
        Array.from({ length: roles?.length }, () =>
          Math.floor(Math.random() * 3)
        )
      ),
    ],
    [roles?.length]
  );
  return (
    <Flex
      padding="15px"
      h="100%"
      minW="250px"
      paddingTop="25px"
      // height="fit-content"
      direction="column"
      {...restProps}
      overflowY="scroll"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
          border: "1px solid #DEDEDE",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#DEDEDE",
        },
      }}
    >
      <Boxes
        heading="Total Users"
        number={orgDetails?.users?.length}
        background="#FEF4FE"
        headingColor="#B451B4"
        statColor="#B451B4"
        setStatSelect={setStatSelect}
      />
      {roles?.map((role, index) => {
        return (
          <Boxes
            key={role[0]}
            heading={role[0]}
            number={role[1]}
            background={background[colorRandomArr[index]]}
            headingColor={heading[colorRandomArr[index]]}
            statColor={stat[colorRandomArr[index]]}
            setStatSelect={setStatSelect}
          />
        );
      })}
    </Flex>
  );
};

export default DashboardStatistics;
