import {
  Button,
  Flex,
  HStack,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import CaseCreatedIcon from "../Static/Images/CaseCreatedIcon.svg";

const CreatedCaseConfirmation = ({
  setCaseCreatedPage,
  userInfo,
  createdCaseResponse,
  patientName,
  setIndex,
}) => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1600px)");
  const navigate = useNavigate();
  const buttonStyle = {
    borderRadius: "10px",
    color: "#1B75BC",
    border: "1px solid rgba(27, 117, 188, 0.25)",
    background: "inherit",
  };

  return (
    <Flex w="100%" h="100%" direction="column">
      <HStack w="100%" justifyContent="space-between">
        <Button
          style={buttonStyle}
          w="198px"
          h="37px"
          onClick={() => {
            // setCaseCreatedPage(false);
            window.location.reload();
          }}
        >
          Create another case
        </Button>
        <Button
          style={buttonStyle}
          w="198px"
          h="37px"
          onClick={() => {
            setCaseCreatedPage(false);
            navigate(`/dashboard/${userInfo?.userType}`);
          }}
        >
          Close
        </Button>
      </HStack>
      <Flex
        w="100%"
        h="90%"
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Image src={CaseCreatedIcon} w="120px" h="120px" />
        <Text fontSize={ifSmallScreen ? "14px" : "20px"} color="light.1006">
          Case created successfully!
        </Text>
        <Text mb="4vh">
          You will be notified when the pathologist will review it.
        </Text>
        <Flex
          direction="column"
          alignItems="center"
          mb="6vh"
          w="50%"
          fontSize="14px"
        >
          <Text fontSize={ifSmallScreen ? "14px" : "20px"} mb="1vh">
            Case Details
          </Text>
          <HStack w={ifSmallScreen ? "80%" : "50%"} mb="1vh">
            <Text minW="30%">Hospital</Text>
            <Text minW="70%">{`: ${createdCaseResponse?.data?.treatingHospitalDetails?.hospitalName}`}</Text>
          </HStack>
          <HStack w={ifSmallScreen ? "80%" : "50%"} mb="1vh">
            <Text minW="30%">Case Id</Text>
            <Text minW="70%">{`: ${createdCaseResponse?.data?.caseId}`}</Text>
          </HStack>
          <HStack w={ifSmallScreen ? "80%" : "50%"} mb="1vh">
            <Text minW="30%">Patient name</Text>
            <Text minW="70%">{`: ${patientName}`}</Text>
          </HStack>
          {/* <HStack w={ifSmallScreen ? "80%" : "50%"} mb="1vh">
            <Text minW="30%">Assign to</Text>
            <Text minW="70%">{`: ${createdCaseResponse?.data?.treatingDoctor}`}</Text>
          </HStack> */}
        </Flex>

        <Button
          borderRadius="10px"
          width="188px"
          height="37px"
          bgColor="rgba(27, 117, 188, 0.25)"
          color="light.1006"
          onClick={() => {
            sessionStorage.clear();
            navigate("/dashboard/case", {
              state: { caseId: createdCaseResponse?.data?._id },
            });
          }}
        >
          Open case
        </Button>
      </Flex>
    </Flex>
  );
};

export default CreatedCaseConfirmation;
