import React, { useEffect } from "react";
import {
  Flex,
  Text,
  useMediaQuery,
  Link,
  Box,
  VStack,
  Image,
  HStack,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link as RouteLink } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";
import { BiLinkExternal } from "react-icons/bi";
import axios from "axios";
import { getSlideUrl } from "../../../hooks/utility";
import {
  useGetRecentSlidesQuery,
  useLazyGetRecentSlidesQuery,
} from "../../../state/API/HospitalApi";
import Environment from "../../../environment";

const RecentSlides = ({
  caseInfo,
  caseId,
  setTabIndex,
  notifyOpenSlide,
  setNotifyOpenSlide,
}) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [ifWidthLargethanthan1800] = useMediaQuery("(min-width:1800px)");
  const [getRecentSlides] = useLazyGetRecentSlidesQuery();
  const { data: recentSlides } = useGetRecentSlidesQuery({
    caseId: caseInfo?._id,
  });
  useEffect(() => {
    if (notifyOpenSlide !== "") {
      getRecentSlides({ caseId: notifyOpenSlide }).unwrap();
      setNotifyOpenSlide("");
    }
  }, [notifyOpenSlide]);
  const { user } = useAuth0();
  const updateSlideOpened = async (slideId, caseid, subClaim) => {
    // console.log(slideId);
    const resp = await axios.post(`${Environment.USER_URL}/slide_opened`, {
      slideId: `${slideId}`,
      caseId: `${caseid}`,
      subClaim: `${subClaim}`,
    });
  };
  return (
    <Flex
      h="36vh"
      minH={ifWidthLargethanthan1800 ? "425px" : "350px"}
      w="100%"
      bgColor="#fff"
      mt="10px"
      borderRadius="10px"
      p="1vw"
      direction="column"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
          fontWeight="600"
        >
          Recent slides
        </Text>
        <BiLinkExternal
          size={16}
          style={{ color: "#1B75BC", cursor: "pointer" }}
          onClick={() => setTabIndex(1)}
        />
      </HStack>

      <Flex
        mr="-0rem !important"
        mb="1.1vh"
        flexWrap="wrap"
        mt="1.32vh"
        fontSize="12px"
        flexDirection="column"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
        w="100%"
        h="100%"
      >
        {recentSlides?.length === 0 ? (
          <Flex
            w="100%"
            h="100%"
            direction="column"
            align="center"
            justifyContent="center"
          >
            <FiAlertTriangle size="1.5vw" />
            <Text fontSize="14px">No slide is uploaded!</Text>
          </Flex>
        ) : (
          <Flex
            alignItems="flex-start"
            w="100%"
            // mr="-0rem !important"
            flexWrap="wrap"
            h={ifWidthLargethanthan1800 ? "350px" : "280px"}
            minH="200px"
            overflowY="scroll"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#C4C4C4",
              },
            }}
          >
            {recentSlides?.map((slide) => {
              return slide?.awsImageBucketUrl !== "" ? (
                <Link
                  as={RouteLink}
                  key={slide?.accessionId}
                  to="/viewer"
                  state={{ caseId, slide }}
                  style={{ textDecoration: "none" }}
                  _focus={{ outline: "none" }}
                  bgColor="light.100"
                  w="45%"
                  h="40%"
                  minH="220px"
                  mr="1.4vw"
                  borderRadius="10px"
                  onClick={() =>
                    updateSlideOpened(slide._id, caseId, user?.sub)
                  }
                >
                  <Box w="100%" h="100%">
                    <VStack px="auto">
                      <Image
                        src={getSlideUrl(slide?.awsImageBucketUrl)}
                        w="90%"
                        h="18vh"
                        minH="160px"
                        alt={slide?.accessionId}
                        pt="1vh"
                      />
                      <HStack
                        w="100%"
                        justifyContent="space-between"
                        px="1.1vw"
                      >
                        <Text w="100%">{`${
                          slide?.slideName
                            ? slide.slideName
                            : slide.originalName
                        }`}</Text>
                        {/* <Button
                      bgColor="#fff"
                      _focus={{ outline: "none" }}
                      w="2.5vw"
                      h="4vh"
                    > */}

                        {/* </Button> */}
                      </HStack>
                    </VStack>
                  </Box>
                </Link>
              ) : (
                ""
              );
            })}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default RecentSlides;
