import React, { useState } from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Link, Image as WImage, Flex } from "@chakra-ui/react";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    paddingRight: "20px",
    marginTop: "4vh",
    marginLeft: "3vw",
    flexDirection: "column",
    fontSize: "12px",
  },
  tableBox: {
    border: "1px solid #DEDEDE",
    minWidth: "550px",
    width: "40vw",
    display: "flex",
    flexDirection: "row",
  },
  tableColumn: {
    borderRight: "1px solid #DEDEDE",
    width: "50%",
    fontSize: "12px",
    minHeight: "25px",
  },
  tableData: {
    display: "flex",
    flexDirection: "row",
    padding: "2.5px",
    textOverflow: "ellipsis",
  },
  tableDataLabel: {
    paddingLeft: "0.5208vw",
    paddingRight: "10px",
    width: "50%",
  },
  tableDataLabelInfo: {
    paddingRight: "0.5208vw",
    textOverflow: "clip",
    wordWrap: "break-word",
    width: "100%",
  },
  reportBox: {
    marginTop: "1.7vh",
    display: "flex",
    flexDirection: "column",
  },
  reportHeadline: {
    fontWeight: 800,
    fontSize: "16px",
    // textDecoration: "underline",
  },
  title: {
    fontWeight: 600,
    fontSize: "20px",
    paddingBottom: "1vh",
    paddingTop: "2vh",
    textDecoration: "underline",
  },
  reportColumn: {
    paddingTop: "10px",
    display: "flex",
    // flexDirection: "row",
  },
  reportData: {
    marginLeft: "0.5vw",
    fontWeight: "100",
    fontSize: "13px",
  },
  media: {
    width: "100%",
    paddingTop: "2vh",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  image: {
    width: "50px",
    height: "50px",
  },
  reporterBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },
  reporterName: {
    color: "#3B5D7C",
    fontSize: "16px",
  },
  reporterDetails: {
    fontSize: "14px",
  },
});

const Report = ({ caseInfo, slideInfo, userInfo }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.tableBox}>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>Name: </Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.patient?.patientName?.firstName}`}
                {`${caseInfo?.patient?.patientName?.lastName}`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>UHID: </Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.patient?.uhid}`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableBox}>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>Gender/Age: </Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${
                  caseInfo?.patient?.gender ? caseInfo?.patient?.gender : null
                } / ${`${moment().diff(
                  `${caseInfo?.patient?.dateOfBirth}`,
                  "years"
                )} Y`}`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>Clinician: </Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.treatingDoctor}`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableBox}>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>Contact No: </Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.patient?.contactNumber}`
                  ? `${caseInfo?.patient?.contactNumber}`
                  : `-`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>Helpline No: </Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${
                  caseInfo?.patient?.contactNumber
                    ? caseInfo?.patient?.contactNumber
                    : "-"
                }`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableBox}>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>Address: </Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${
                  caseInfo?.patient?.patientAddress
                    ? caseInfo?.patient?.patientAddress
                    : "-"
                }`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>Hospital: </Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.treatingHospitalDetails?.hospitalName}`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.reportBox}>
          <Text style={styles.title}>
            HISTOPATHOLOGY FINAL DIAGNOSIS REPORT
          </Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              CASE ID:
              <Text style={styles.reportData}>{`${caseInfo?.caseId}`}</Text>
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              DEPARTMENT:
              <Text
                style={styles.reportData}
              >{`${caseInfo?.departmentFrom}`}</Text>
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              ORGAN:
              <Text style={styles.reportData}>
                {`${caseInfo?.organs[0]?.organName}`}
              </Text>
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              CLINICAL HISTORY:
              <Text style={styles.reportData}>{`${
                slideInfo?.clinicalStudy ? slideInfo?.clinicalStudy : ""
              }`}</Text>
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              GROSS DESCRIPTION:
              <Text style={styles.reportData}>{`${
                slideInfo?.grossDescription ? slideInfo?.grossDescription : ""
              }`}</Text>
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              MICROSCOPIC DESCRIPTION:
              <Text style={styles.reportData}>
                {`${
                  slideInfo?.microscopicDescription
                    ? slideInfo?.microscopicDescription
                    : ""
                }`}
              </Text>
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              IMPRESSION:
              <Text style={styles.reportData}>{`${
                slideInfo?.impression ? slideInfo?.impression : ""
              }`}</Text>
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              ADVICE:
              <Text style={styles.reportData}>{`${
                slideInfo?.advise ? slideInfo?.advise : ""
              }`}</Text>
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>ANNOTED SLIDES:</Text>
            <Text style={styles.reportData}>{`${
              slideInfo?.annotedSlides ? slideInfo?.annotedSlides : ""
            }`}</Text>
          </View>
          {/* <View style={styles.media}>
            {slideInfo?.mediaURLs?.map((url) => {
              return (
                <Image
                  key={url}
                  src={{
                    uri: url,
                    method: "GET",
                    // headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                />
                // <Image src={url} key={url} cache="false" />
                // <img src={url} key={url} alt="text" />
              );
            })}
          </View> */}
          <View style={styles.reporterBox}>
            {/* <Image
              src={{
                uri: userInfo?.signature,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
            /> */}
            <WImage
              w="100px"
              h="50px"
              fit="contain"
              src={userInfo?.signature}
            />
            <Text
              style={styles.reporterName}
            >{`Dr. ${userInfo?.firstName} ${userInfo?.lastName}`}</Text>
            <Text style={styles.reporterDetails}>
              {userInfo?.highestDegree?.split("")?.join(".")?.toUpperCase()}
            </Text>
            <Text
              style={styles.reporterDetails}
            >{`${userInfo?.organization?.name}, ${userInfo?.organization?.address}`}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const ReportDocument = ({
  caseInfo,
  slideInfo,
  userInfo,
  setReportLinkData,
}) => {
  return (
    <Flex
      direction="column"
      h="100%"
      alignItems="center"
      w="100%"
      justifyContent="center"
    >
      <Flex
        overflowY="scroll"
        h="90%"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
        p="20px"
        bgColor="#fff"
      >
        <Report caseInfo={caseInfo} slideInfo={slideInfo} userInfo={userInfo} />
      </Flex>
      <PDFDownloadLink
        document={
          <Report
            caseInfo={caseInfo}
            slideInfo={slideInfo}
            userInfo={userInfo}
          />
        }
        fileName="case_report.pdf"
      >
        {({ url }) => setReportLinkData(url)}
      </PDFDownloadLink>
    </Flex>
  );
};

export default ReportDocument;
