import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  HttpLink,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { MedViewer } from "med-viewer";
import {
  useSaveReportMutation,
  useMultiFileUploadMutation,
  useLazyGetSlideInfoQuery,
  useSaveSynopticReportMutation,
  useLazyGetSynopticReportQuery,
  useUpdateSynopticReportMutation,
  useAddMultipleUserToCaseMutation,
} from "../../state/API/HospitalApi";
import Loading from "../Loading/loading";
import Environment from "../../environment";
import Header from "../../DashboardLayout/Header";

const OSDViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo, caseInfo, slide, mentionUsers } = location.state;

  const [saveReport] = useSaveReportMutation();
  const [saveSynopticReport] = useSaveSynopticReportMutation();
  const [mediaUpload] = useMultiFileUploadMutation();
  const [slideInfo] = useLazyGetSlideInfoQuery();
  const [getSynopticReport] = useLazyGetSynopticReportQuery();
  const [updateSynopticReport] = useUpdateSynopticReportMutation();
  const [addUsersToCase] = useAddMultipleUserToCaseMutation();
  const [searchSelectedData, setSearchSelectedData] = useState("");
  const goToHomeHandler = () => {
    if (userInfo?.userType) navigate(`/dashboard/${userInfo.userType}`);
    else navigate("/dashboard");
  };

  // console.log("slide", slide);
  // console.log("slideInfo", caseInfo);

  const uploadPatch = async (file) => {
    try {
      const resp = await fetch(`${Environment.USER_URL}/upload_patch`, {
        method: "POST",
        body: file,
      });
      console.warn(resp);
    } catch (error) {
      console.error(error);
    }
  };

  const httpLink = new HttpLink({
    uri: Environment.VIEWER_URL,
    // uri: 'http://localhost:8080',
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: Environment.VIEWER_SOCKET_URL,
      // url: 'ws://localhost:8080',
    })
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });

  // clint 22

  const token = localStorage.getItem(Environment?.AUTH0_TOKEN);
  let accessToken;
  if (token) {
    const { body } = JSON.parse(token);
    if (body && typeof body === "object") {
      accessToken = body.access_token;
    }
  }
  const httpLink2 = new HttpLink({
    uri: "https://development-api.chat.prr.ai",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const wsLink2 = new GraphQLWsLink(
    createClient({
      url: "wss://development-api.chat.prr.ai",
    })
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value

  const splitLink2 = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink2,
    httpLink2
  );
  const apolloClient2 = new ApolloClient({
    link: splitLink2,
    cache: new InMemoryCache(),
  });

  return !caseInfo ? (
    <Loading />
  ) : (
    <Flex direction="column" h="100vh">
      <Header
        userInfo={userInfo}
        setSearchSelectedData={setSearchSelectedData}
        searchSelectedData={searchSelectedData}
      />
      <ApolloProvider client={apolloClient}>
        <MedViewer
          userInfo={userInfo}
          caseInfo={caseInfo}
          tile={slide?.awsImageBucketUrl}
          slides={caseInfo?.slides}
          viewerIds={[slide]}
          annotations
          slide={slide}
          report
          enableAI
          client2={apolloClient2}
          enableFilters
          application="hospital"
          uploadPatch={uploadPatch}
          goToHomeHandler={goToHomeHandler}
          saveReport={saveReport}
          saveSynopticReport={saveSynopticReport}
          mediaUpload={mediaUpload}
          slideInfo={slideInfo}
          getSynopticReport={getSynopticReport}
          mentionUsers={mentionUsers}
          Environment={Environment}
          updateSynopticReport={updateSynopticReport}
          addUsersToCase={addUsersToCase}
          searchSelectedData={searchSelectedData}
        />
      </ApolloProvider>
    </Flex>
  );
};

export default OSDViewer;
