import React, { useState } from "react";
import {
  Flex,
  HStack,
  VStack,
  Text,
  Input,
  Image,
  Select,
  InputGroup,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { BsFillCircleFill } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { MdOutlineVisibility } from "react-icons/md";
import { AiOutlineEyeInvisible } from "react-icons/ai";

const OrganisationDetails = ({ index, setIndex, setInputData, inputData }) => {
  const handleInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  const [image, setImage] = useState(
    inputData.profilePic ? URL.createObjectURL(inputData.profilePic[0]) : null
  );
  const handleChangeImage = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };
  const resetTarget = (e) => {
    e.target.value = null;
  };
  const [show, setShow] = useState(0);
  return (
    <Flex mt="15px" direction="column">
      <HStack spacing="26px" justifyContent="center">
        <BsFillCircleFill
          size={17}
          color="#D9D9D9"
          style={{ cursor: "pointer" }}
          onClick={() => setIndex(1)}
        />
        <BsFillCircleFill
          size={17}
          color="rgba(27, 117, 188, 0.43"
          style={{ cursor: "pointer" }}
        />
      </HStack>
      <Flex mt="20px" direction="column">
        <Text fontSize="20px" mb="3vh">
          Organisation Details
        </Text>
        <HStack w="100%" justifyContent="space-between">
          <VStack alignItems="start" spacing={1} w="50%">
            <Text fontSize="14px">Hospital*</Text>
            <Input
              w="90%"
              placeholder="Enter first name here"
              name="hospital"
              value={inputData.hospital}
              onChange={handleInput}
            />
          </VStack>
          <VStack alignItems="flex-end" spacing={1} w="50%">
            <Text fontSize="14px" w="90%">
              Designation
            </Text>
            <Input
              w="90%"
              placeholder="Enter last name here"
              name="designation"
              value={inputData.designation}
              onChange={handleInput}
            />
          </VStack>
        </HStack>
        <HStack w="100%" justifyContent="space-between" mt="6vh">
          <VStack alignItems="start" spacing={1} w="50%">
            <Text fontSize="14px">Experience</Text>
            <Select
              w="90%"
              name="experience"
              onChange={handleInput}
              value={inputData.experience}
            >
              <option selected hidden fontSize={14}>
                Select Experience
              </option>
              <option value="lessthan1">Less than 1 year</option>
              <option value="1 year">1 year</option>
              <option value="2 years">2 year</option>
              <option value="3 years">3 year</option>
              <option value="4 years">4 year</option>
              <option value="More than 5 years">above 5 year</option>
            </Select>
          </VStack>
          <VStack alignItems="flex-end" spacing={1} w="50%">
            <Text fontSize="14px" w="90%">
              Highest Qualification
            </Text>
            <Select
              w="90%"
              name="qualification"
              onChange={handleInput}
              value={inputData.qualification}
            >
              <option selected hidden fontSize={14}>
                Select qualification
              </option>

              <option value="MBBS">MBBS</option>
              <option value="MD">MD</option>
              <option value="DM">DM</option>
              <option value="Fellowship">Fellowship</option>
              <option value="Other">Other</option>
            </Select>
          </VStack>
        </HStack>
        <Text fontSize="20px" mt="3vh" mb="2vh">
          Other Details
        </Text>
        <HStack>
          <VStack w="50%">
            <VStack alignItems="start" spacing={1} w="100%" mb="2vh">
              <Text fontSize="14px" w="90%">
                Signature*
              </Text>
              <Flex
                bg="#F7F9FB"
                position="relative"
                w="140px"
                h="44px"
                border="1px solid #1B75BC"
                justifyContent="center"
                alignItems="center"
              >
                <Text color="light.1006" fontSize="14px">
                  + Select Image
                </Text>
                <Input
                  id="signature"
                  name="signature"
                  position="absolute"
                  opacity="0"
                  left="0"
                  // resize="none"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    setInputData({
                      ...inputData,
                      [e.target.name]: e.target.files,
                    })
                  }
                  onClick={resetTarget}
                />
              </Flex>
              {inputData.signature.length === 0 ? null : (
                <HStack spacing={1} pt="5px" alignItems="flex-start">
                  <Text>Signature uploaded!</Text>
                  <Text>
                    {inputData.signature.length === 0
                      ? null
                      : inputData.signature[0].name}
                  </Text>
                </HStack>
              )}
            </VStack>
            <VStack alignItems="start" spacing={1} w="100%">
              <Text fontSize="14px" w="90%">
                Password*
              </Text>
              <InputGroup w="90%">
                <Input
                  fontSize={14}
                  type={show ? "text" : "password"}
                  name="password"
                  value={inputData.password}
                  onChange={handleInput}
                  maxLength={16}
                />
                <InputRightElement mr="1%">
                  <Button
                    size="sm"
                    _focus={{ outline: "none" }}
                    onClick={() => setShow(!show)}
                  >
                    {show ? <MdOutlineVisibility /> : <AiOutlineEyeInvisible />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </VStack>
          </VStack>
          <VStack w="50%" alignItems="flex-end" h="100%" spacing={1}>
            <Text w="90%">Profile picture*</Text>
            <Flex w="90%" h="100%">
              <Flex
                w="9vw"
                minW="160px"
                h="100%"
                maxH="250px"
                border="1px solid #1B75BC"
                borderRadius="5px"
                bg="rgba(176,200,214,0.1)"
                alignItems="center"
                justifyContent="center"
              >
                {image ? (
                  <Image
                    src={image}
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <CiUser style={{ width: "65%", height: "65%" }} />
                )}

                <Input
                  id="signature"
                  name="profilePic"
                  position="absolute"
                  opacity="0"
                  left="0"
                  // resize="none"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      [e.target.name]: e.target.files,
                    });
                    handleChangeImage(e);
                  }}
                  onClick={resetTarget}
                />
              </Flex>
            </Flex>
          </VStack>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default OrganisationDetails;
