import React, { useState, useEffect, useRef } from "react";
import { Input } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

const FilterMenu = ({
  uniqueNames,
  filterOptions,
  handleFilterChange,
  selectedValue,
  searchTerm,
  handleSearchChange,
  hideMenu,
  setPageSize,
  setFilterChecked,
  data,
  hideScrollbar,
  setHideScrollBar,
  organ,
  handleFilterClick,
  handleClearFilter,
}) => {
  const [focusedOption, setFocusedOption] = useState(null);
  const [search, setSearch] = useState("");
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [search]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".filter-menu")) {
        return;
      }
      hideMenu();
      setHideScrollBar(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideMenu]);

  const filteredChecklist = uniqueNames.filter((item) =>
    item?.toLowerCase().includes(search?.toLowerCase())
  );

  return (
    <div
      className="filter-menu"
      style={{
        padding: "1rem",
        width: "170px",
        height: "200px",
        position: "relative",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
        // overflowY: "scroll",
        // "&::-webkit-scrollbar": {
        //   width: "2px",
        // },
        // "&::-webkit-scrollbar-track": {
        //   width: "2px",
        // },
        // "&::-webkit-scrollbar-thumb": {
        //   background: "#C4C4C4",
        // },
      }}
    >
      <Input
        type="text"
        ref={searchInputRef}
        placeholder={`Search for ${
          selectedValue ? selectedValue?.toLowerCase() : "value"
        }...`}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        style={{
          width: "100%",
          height: "30px",
          fontSize: "14px",
          padding: "0.5rem",
          marginBottom: "1rem",
          borderRadius: "4px",
          border: "1px solid #ccc",
        }}
      />
      <div
        style={{
          width: "140px",
          height: "100px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "2px",
          },
          "&::-webkit-scrollbar-track": {
            width: "2px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
      >
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li>
            <label
              htmlFor="All"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                transition: "background-color 0.3s, box-shadow 0.3s",
                backgroundColor:
                  focusedOption === "All"
                    ? "rgba(49, 130, 206, 0.2)"
                    : "transparent",
                padding: "0.5rem",
                borderRadius: "4px",
                border: "1px solid transparent",
                marginBottom: "0.25rem",
              }}
              onMouseEnter={() => setFocusedOption("All")}
              onMouseLeave={() => setFocusedOption(null)}
            >
              <input
                type="checkbox"
                id="All"
                name="All"
                onChange={(e) => {
                  handleFilterChange(organ, e);
                  handleClearFilter(organ);
                }}
                checked={filterOptions.includes("All")}
                style={{
                  position: "absolute",
                  opacity: 0,
                }}
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    width: "14px",
                    height: "14px",
                    border: "1px solid #ccc",
                    borderRadius: "2px",
                    marginRight: "20px",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: filterOptions.includes("All") ? "white" : "",
                  }}
                >
                  {filterOptions.includes("All") && <CheckIcon color="green" />}
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    fontWeight: filterOptions.includes("All")
                      ? "bold"
                      : "normal",
                  }}
                >
                  All
                </span>
              </span>
            </label>
          </li>
          {filteredChecklist.map((item) => (
            <li key={item}>
              <label
                htmlFor={item}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  transition: "background-color 0.3s, box-shadow 0.3s",
                  backgroundColor:
                    focusedOption === item
                      ? "rgba(49, 130, 206, 0.2)"
                      : "transparent",
                  padding: "0.5rem",
                  borderRadius: "4px",
                  border: "1px solid transparent",
                  marginBottom: "0.25rem",
                }}
                onMouseEnter={() => setFocusedOption(item)}
                onMouseLeave={() => setFocusedOption(null)}
              >
                <input
                  type="checkbox"
                  id={item}
                  name={item}
                  onChange={(e) => {
                    handleFilterChange(organ, e);
                    handleFilterClick(organ);
                  }}
                  checked={filterOptions.includes(item)}
                  style={{
                    position: "absolute",
                    opacity: 0,
                  }}
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      width: "14px",
                      height: "14px",
                      border: "1px solid #ccc",
                      borderRadius: "2px",
                      marginRight: "20px", // Add some space here
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: filterOptions.includes(item) ? "white" : "",
                    }}
                  >
                    {filterOptions.includes(item) && (
                      <CheckIcon color="green" />
                    )}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      fontWeight: filterOptions.includes(item)
                        ? "bold"
                        : "normal",
                    }}
                  >
                    {item}
                  </span>
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default FilterMenu;
